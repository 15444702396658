<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="date-range-holder" style="width: 100%!important;">
          <VueCtkDateTimePicker
            v-if="!errored"
            v-model="dateRange"
            color="#483D8B"
            buttonColor="#e34696"
            range
            overlay
            format=YYYY-MM-DD
            formatted=ll
            hint="Selected:"
            :disabled="loading"
            label="Filter by date"
            :customShortcuts="datePickerCustomShortcuts"
            :maxDate="maxDate"
            :minDate="minDate"
            @input="dateInput"
          >
          </VueCtkDateTimePicker>
        </div>
      </div>
      <div class="col-md-4 col-sm-12"v-if="allCompanies.length > 0">
          <div class="col-md-12" >
            <Multiselect
                v-model="selectedCompanies"
                :multiple="true"
                :options="allCompanies"
                :disabled="loading"
                placeholder="Filter by company"
                @input="companiesSelectChanged"
            >
            </Multiselect>
          </div>
      </div>
      <div class="col-md-4 col-sm-12" v-if="!errored">
        <div class="row">
            <div class="col-md-5">
              <button
                  type="button"
                  class="btn ml-4 search"
                  :disabled="loading || (!dateUpdated && !companiesFilterUpdated)"
                  @click.prevent="applyFilters"
              >
                Apply filters
              </button>
            </div>
            <div class="col-md-5">
              <button
                  type="button"
                  class="btn ml-4 reset"
                  :disabled="loading || !filtersApplied"
                  @click.prevent="resetFilters"
              >
                Reset filters
              </button>
            </div>
        </div>
      </div>
    </div>
    <!-- Transactions table -->
    <div v-if="!errored" id="bills" class="bills-table mt-4">

      <div v-if="!loading && (dateUpdated || companiesFilterUpdated)">
        <p class="updated-filters-info">
          The displayed data does not correspond to the filters selection. Please apply the filters in order to get the proper data.
        </p>
      </div>
      <table class="table table-hover">
        <thead>
          <tr class="text-body">
            <th>Company Name</th>
            <th>Date</th>
            <th>Transaction ID</th>
            <th>Auth Code</th>
            <th>Scheme</th>
            <th>Status</th>
            <th>Tip</th>
            <th>Total<br/>Amount</th>
          </tr>
        </thead>
        <tbody v-if="loading" class="text-center">
          <img style="margin-left: 270%" src="../../assets/spinner.svg" alt="" class="table-logo">
        </tbody>
        <tbody v-else-if="!loading && results.length > 0">
          <tr v-for="result in results" :key="result.id" class="text-dark">
            <td>{{ result.company }}</td>
            <td>{{ result.date }}</td>
            <td>{{ result.id }}</td>
            <td>{{ result.card_erase_reference }}</td>
            <td>{{ result.scheme | capitalize}}</td>
            <td
              :style="result.status === 'Approved' ? 'color: #89C58E' : 'color: #FF5252'"
            >{{ result.status }}</td>
            <td><strong>&#163; {{ result.additional_service_charge | currency }}</strong></td>
            <td><strong>&#163; {{result.amount | currency }}</strong></td>
          </tr>
        </tbody>
        <tbody v-else >
          <tr>
            <td colspan="12" class="text-center alert-info empty-data">
              Nothing to show...
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Info if error -->
    <div v-else class="text-center mt-5 pt-5 pb-4 error-holder">
      <Error></Error>
    </div>



    <!-- Download Transactions Report button -->
    <button v-if="!loading && !errored && results.length > 0"
        type="button"
        class="btn download"
        @click="onCSVExport"><span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Download Report
    </button>

    <Pagination
        v-if="!errored && !loading && results.length > 0"
        @handlePageChange="handlePageChange"
        :maxPage="maxPage"
        :totalItems="totalItems"
    >
      <li slot="body"></li>
    </Pagination>
  </div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";
import Error from "../Error/ErrorComponent";
import Multiselect from "vue-multiselect";
import moment from 'moment';

export default {
  name: "BillsTable",
  components: {
    VueCtkDateTimePicker,
    Pagination,
    Multiselect,
    Error
  },
  data(){
    return {
      results: [],
      dateRange: null,
      date_from: '',
      date_to: '',
      csvLoad: false,
      listLoad: true,
      errored: false,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      maxPage: 10,
      totalItems: 100,
      selectedCompanies: [],
      preSelectedCompanies: [],
      allCompanies: [],
      loading: true,
      filtersApplied: false,
      datePickerCustomShortcuts: [
        { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
        { key: 'last7Days', label: 'Last 7 days', value: 7 },
        { key: 'last30Days', label: 'Last 30 days', value: 30 },
        { key: 'thisMonth', label: 'This month', value: 'month' },
        { key: 'lastMonth', label: 'Last month', value: '-month' },
      ],
      maxDate: this.moment().format('YYYY-MM-DD'),
      minDate: '2018-01-01',
      dateUpdated: false,
      companiesFilterUpdated: false
    }
  },
  created () {
    let today = this.moment();

    this.dateRange = {
        start: today.format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD')
    };

    this.loadData();
  },
  methods: {
    moment() {
      return moment();
    },
    created () {
      this.loadData();
    },
    loadData () {
      this.filtersApplied = false;
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.filtersApplied = true;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            this.filtersApplied = true;
            params.append('date_to', this.dateRange.end);
          }
        }
      }
      // Append page param
      if (this.page !== null) {
        params.append('page', this.page);
      }
      // Append perPage param
      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      // Append selected companies
      if (this.selectedCompanies.length) {
        this.filtersApplied = true;
        params.append('companies', JSON.stringify(this.selectedCompanies));
      }

      // Api request for transactions list
      axios.get(`backoffice/transactions/list`, {params: params})
        .then(response => {
          let data = response.data.data;
          if (!response.data.success) {
            this.errored = true;
            this.loading = false;
            return;
          }

          this.maxPage = data.max_page;
          this.totalItems = data.total_items;
          this.preSelectedCompanies = data.filtered_companies;
          this.allCompanies = data.all_companies;
          this.loading = false;
          this.dateUpdated = false;
          this.companiesFilterUpdated = false;

          this.results = data.items;
      }).catch(error => {
          console.log(error)
          this.errored = true;
          this.loading = false;
      }).finally(() => {
          setTimeout(() => {
            this.listLoad = false
            this.loading = false;
          }, 300)
      })
    },
    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },
    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    // Api request for CSV data
    onCSVExport () {
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.date_from = this.dateRange.start;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            this.date_to = this.dateRange.end;
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      // Append selected companies
      if (this.selectedCompanies.length) {
        params.append('companies', JSON.stringify(this.selectedCompanies));
      }

      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/transactions/csv', {params: params}, config)
        .then(response => {
          const csvData = response.data;
          if (response.status === 200 && response.data !== null) {
            this.csvLoad = true;
            let blob = new Blob([csvData], {type: 'text/csv'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if ((this.date_from !== null && this.date_from !== '' && this.date_from !== undefined)
               || (this.date_to !== null && this.date_to !== '' && this.date_to !== undefined)) {
              link.download = `transactions(${this.date_from}-${this.date_to}).csv`;
            } else {
              link.download = `transactions-all.csv`;
            }
            link.click();
            setTimeout(() => {
              this.csvLoad = false;
            }, 1000);
          }
        })
        .catch(error => console.log(error));
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    },
    applyFilters() {
      this.page = 1;
      this.loading = true;

      this.loadData();
    },
    resetFilters() {
      this.page = 1;
      this.selectedCompanies = [];
      this.dateRange = null;
      this.loading = true;

      this.loadData();
    },
    filterByDate() {
      this.page = 1;
      this.loading = true;

      this.loadData();
    },
    dateInput() {
      this.dateUpdated = true;
      let now = new moment();
      let max = new moment(this.dateRange.start);
      let min = new moment(this.dateRange.start);

      let maxDateValue = max.add(31, 'd');
      if (maxDateValue > now) {
        maxDateValue = now;
      }

      this.maxDate = maxDateValue.format('YYYY-MM-DD');
      this.minDate = min.subtract('31', 'd').format('YYYY-MM-DD');
    },
    companiesSelectChanged() {
      this.companiesFilterUpdated = true;
    }
  }
}
</script>

<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  float: right;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  /*margin-left: ;*/
}

button.download:hover, button.search:hover {
  color: white;
}

button.search {
  background: #E85F9A;
  color: white;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  margin-top: 2%;
}

button.search:disabled {
  background: #f69bc1;
  cursor: default;
}

button.reset {
  background: white;
  color: #E85F9A;
  -webkit-box-shadow: 0px 0px 13px -2px rgb(246, 155, 193);
  -moz-box-shadow: 0px 0px 13px -2px rgb(173, 117, 138);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  margin-top: 2%;
}

button.reset:disabled {
  background: #b7b7b7;
  cursor: default;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select {
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.updated-filters-info {
  font-size: 12px;
  color: #3c038c;
  background-color: #d49eb5;
  width: 52%;
  border-radius: 5px;
  padding: 5px;
}
</style>
