<template lang="html">
  <div>
    <button
        type="button"
        class="btn download"
        @click="showAddStaffModal"
    >
      Add Staff Member
    </button>
    <h2>Staff table</h2>
    <div v-if="message" :class="error ? 'alert alert-warning' : 'alert alert-info'">
      {{message}}
    </div>
    <div v-if="!errored" id="bills" class="bills-table mt-4">
      <table  class="table table-hover">
        <thead>
        <tr class="text-body">
          <th>Name</th>
          <th>Token</th>
          <th>Pin</th>
          <th>Payroll</th>
          <th>Active</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody v-if="listLoad">
        <tr>Loading...</tr>
        </tbody>
        <tbody v-else-if="!listLoad && results.length > 0">
        <tr v-for="result in results" :key="result.id" class="text-dark">
          <td>
            {{ result.name }}
          </td>
          <td>
            {{ result.token }}
          </td>
          <td>
            {{ result.pin }}
          </td>
          <td>
            {{ result.email }}
          </td>
          <td>
            {{ result.is_active ? "1" : "0"}}
          </td>
          <td>
            <button
                type="button"
                class="btn ml-4 download"
                @click="editStaff(result)">
              Edit
            </button>
            <button
                type="button"
                class="btn ml-4 delete"
                @click="deleteStaffMember(result.token)">
              Delete
            </button>
          </td>
        </tr>
        </tbody>
        <tbody v-else >
        <tr>
          <td colspan="12" class="text-center alert-info empty-data">
            Nothing to show
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Info if error -->
    <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </div>

    <Pagination
        @handlePageChange="handlePageChange"
        :maxPage="maxPage"
        :totalItems="totalItems"
    >
      <li slot="body"></li>
    </Pagination>

    <Modal v-if="isModalVisible"
           @close="closeModal"
           :loadModel="loadModel"
           :staffId="staffId"
           :staffMemberName="staffMemberName"
           :staffMemberToken="staffMemberToken"
           :staffMemberPin="staffMemberPin"
           :staffMemberPayrollNumber="staffMemberPayrollNumber"
           :staffMemberIsActive="staffMemberIsActive"
    >
      <ul>
        <li slot="body"></li>
      </ul>
    </Modal>
  </div>
</template>

<script>

import axios from 'axios'
import Modal from './Modals/AddStaffMemberModalComponent';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "TeamMembersComponent",
  components: {
    Modal,
    Pagination
  },
  computed: {
  },
  data(){
    return {
      results: [],
      maxPage: 1,
      totalItems: 1,
      itemsCount: 0,
      busy: false,
      listLoad: true,
      errored: false,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      loadModel: '',
      isModalVisible: false,
      staffId: null,
      staffMemberName: null,
      staffMemberToken: null,
      staffMemberPin: null,
      staffMemberPayrollNumber: null,
      staffMemberIsActive: null,
      message: null,
      error: false
    }
  },
  created () {
    this.loadData();
  },

  methods: {
    loadData() {
      this.error = false;
      let params = new URLSearchParams();
      // Append page param
      if (this.page !== null) {
        params.append('page', this.page);
      }
      // Append perPage param
      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }
      this.busy = true;
      axios.get(`backoffice/staff/list`, {params: params})
          .then(response => {
            this.busy = false;
            this.listLoad = false;

            let data = response.data.data;
            this.maxPage = data.max_page;
            this.totalItems = data.total_items;
            this.results = data.items;
          })
          .catch( (err) => {
            this.busy = false;
            this.error = true;
            this.message = err.response.data.message;
            setTimeout(() => {
              this.message = null;
              this.error = false;
            }, 3000);
          })
    },
    nextPage () {
      if (this.maxPage !== this.page) {
        this.page++;
        this.loadData();
      }
    },
    previousPage () {
      if (this.page !== 1) {
        this.page--;
        this.loadData();
      }
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    showAddStaffModal () {
      this.loadModel = 'add'
      this.isModalVisible = true;

    },
    closeModal () {
      this.isModalVisible = false;
      this.staffId = null;
      this.staffId = null;
      this.staffMemberName = null;
      this.staffMemberToken = null;
      this.staffMemberPin = null;
      this.staffMemberPayrollNumber = null;
      this.staffMemberIsActive = null;

      this.loadData();
    },
    editStaff(staffData) {
      this.staffId = staffData.id;
      this.staffMemberName = staffData.name;
      this.staffMemberToken = staffData.token;
      this.staffMemberPin = staffData.pin;
      this.staffMemberPayrollNumber = staffData.email;
      this.staffMemberIsActive = staffData.is_active;

      this.loadModel = 'edit';
      this.isModalVisible = true;
    },
    deleteStaffMember(token) {
      this.error = false;
      if(confirm("Are you sure you want to delete the staff member?")){

        axios.post('backoffice/staff/' + token + '/delete')
            .then(res => {
              if (res) {
                this.message = res.data.message
                setTimeout(() => {
                  this.message = null;
                }, 3000);

                if (this.itemsCount % this.perPage === 1) {
                  this.page--;
                }
                this.loadData();
              }
            })
            .catch(err => {
              this.error = true;
              this.message = err.response.data.message
              setTimeout(() => {
                this.message = null;
                this.error = false;
              }, 3000);
            })
      }
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  }
}
</script>

<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.delete {
  padding: 8px 20px;
  background: #ee6d04;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover {
  color: white;
  background: #e33480;
}

button.delete:hover {
  background: #e33131;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select {
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


h2{
  font-weight: 700;
  color: #433C8C;
  margin-top: 20px;
}

.a-disabled{
  cursor: no-drop!important;
}
</style>