<template lang="html">
  <div class="" v-if="isAdmin">
    <AccountSubheader />
    <p>Account Manager</p>
    <AccountManagerComponent />
  </div>
</template>

<script>
import AccountSubheader from '../../components/AccountComponents/AccountSubheader';
import AccountManagerComponent from '../../components/AccountComponents/AccountManagerComponent';
export default {
  name: "AccountManagerPage",
  components: {
    AccountSubheader,
    AccountManagerComponent
  },
  created() {
    this.isAdmin = localStorage.getItem('userEmail') == 'developer@tableyeti.com';
  },
  data() {
    return {
      isAdmin: false
    }
  },
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
