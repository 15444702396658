<template lang="html">
  <div class="">
    <SalesSubheader />
    <p>Z Reports</p>
    <ZreportsTable />
  </div>
</template>

<script>
import SalesSubheader from '../../components/SalesComponents/SalesSubheader';
import ZreportsTable from '../../components/SalesComponents/ZreportsTable';
export default {
  name: "ZreportsPage",
  components: {
    SalesSubheader,
    ZreportsTable
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
