<template>
  <div class="sales">
    <SalesSubheader />
    <p>Sales</p>
</div>
</template>


<script>
import SalesSubheader from '../components/SalesComponents/SalesSubheader';


export default {
  name: 'Sales',
  components: {
    SalesSubheader
  }
}
</script>

<style scoped>


div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}

</style>
