<template lang="html">
  <div>
    <div class="date-range-holder">
      <VueCtkDateTimePicker
          v-model="dateRange"
          color="#483D8B"
          range
          overlay
          hint="Selected:"
          label="Select Start and End Dates"
          id="left"
          @input="onDateChanged"
          :format="'YYYYMMDD'"
          :formatted="'DD/MM/YYYY'"
          max-date="maxDate"
      >
      </VueCtkDateTimePicker>
    </div>
    <button
        type="button"
        class="btn ml-4 download"
        @click="createReport"
        :disabled="datesPicked == 0"
    >
      <span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Create Report
    </button>
    <button
        type="button"
        class="btn ml-4 download"
        @click="showAutomatedReportsModal"
    >
      <span v-if="csvLoad" role="status" aria-hidden="true"></span>
      Automated Reports
    </button>


    <h2>Reports table</h2>
    <div v-if="!errored" id="bills" class="bills-table mt-4">
      <table  class="table table-hover">
        <thead>
          <tr class="text-body">
            <th>Date From</th>
            <th>Date To</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="listLoad">
          <tr>Loading...</tr>
        </tbody>
        <tbody v-else-if="!listLoad && results.length > 0">
          <tr v-for="result in results" :key="result.id" class="text-dark">
            <td>
              {{ result.date_from }}
            </td>
            <td>
              {{ result.date_to }}
            </td>
            <td>
              <button
                  type="button"
                  class="btn ml-4 download"
                  @click="onCSVExport(result.id)">
                <span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Download Report
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else >
          <tr>
            <td colspan="12" class="text-center alert-info empty-data">
              Nothing to show
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Info if error -->
    <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </div>

    <Pagination
        @handlePageChange="handlePageChange"
        :maxPage="maxPage"
        :totalItems="totalItems"
    >
      <li slot="body"></li>
    </Pagination>


    <Modal v-if="isAutomatedReportsModalVisible"
           @close="closeModal"
           :data="automatedReportsData"
           :automatedReportsId="automatedReportsId"
           :automatedReportsType="automatedReportsType"
           :automatedReportsTime="automatedReportsTime"
           :automatedReportsDay="automatedReportsDay"
           :automatedReportsEmails="automatedReportsEmails"
    >
      <ul>
        <li slot="body"></li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";
import Modal from './Modals/AutomatedReportsModalComponent';

export default {
  name: "DateRangeReportsComponent",
  components: {
    VueCtkDateTimePicker,
    Pagination,
    Modal
  },
  computed: {
  },
  data(){
    return {
      results: [],
      slicedTableData: [],
      allTableData: [],
      busy: false,
      limit: 30,
      keyword: '',
      showSlicedData: false,
      filterDate: "This year",
      dateRange: null,
      csvLoad: false,
      listLoad: true,
      errored: false,
      datesPicked: 0,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      maxPage: 100,
      totalItems: 100,
      isAutomatedReportsModalVisible: false,
      automatedReportsData: [],
      automatedReportsId: null,
      automatedReportsType: null,
      automatedReportsTime: null,
      automatedReportsDay: null,
      automatedReportsEmails: null,
    }
  },
  created () {
    this.loadData();
  },

  methods: {
    loadData() {
      console.log("LOAD DATA");
      let params = new URLSearchParams();
      // Append page param
      if (this.page !== null) {
        params.append('page', this.page);
      }
      // Append perPage param
      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }
      this.busy = true;
      axios.get(`backoffice/daily-reports/staff-report-list`, {params: params})
          .then(response => {
            this.busy = false;
            this.listLoad = false;
            let data = response.data.data;
            this.maxPage = data.max_page;
            this.totalItems = data.total_items;
            this.results = data.items;
          })
          .catch( (err) => {
            this.busy = false;
          })
    },
    createReport() {
      if (!this.datesPicked) {
        return;
      }

      let params = new URLSearchParams();
      if (this.dateRange !== null) {
        params.append('date_from', this.dateRange.start);
        params.append('date_to', this.dateRange.end);
      }

      // Api request for transactions list
      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get(`backoffice/daily-reports/create-staff-report`, {params: params})
          .then(response => {
            const csvData = response.data;
            if (response.status === 200 && response.data !== null) {
              this.csvLoad = true;
              let blob = new Blob([csvData], {type: 'text/csv'});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'report.csv'
              link.click();
              setTimeout(() => {
                this.csvLoad = false;
              }, 1000);
            }
          })
          .catch(error => {
            console.log(error)
            this.errored = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.listLoad = false
            }, 300)
          })
    },
    onDateChanged () {
      this.datesPicked = 0;

      let fromSet = false;
      let toSet = false;
      console.log(this.dateRange);
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          fromSet = true;
        }
        if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
          toSet = true;
        }
      }

      this.datesPicked = fromSet && toSet ? 1 : 0;
    },
    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },
    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    // Api request for CSV data
    onCSVExport (staffReportId) {
      let params = new URLSearchParams();

      params.append('staff_report_id', staffReportId)

      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/daily-reports/staff-report-download', {params: params}, config)
          .then(response => {
            const csvData = response.data;
            if (response.status === 200 && response.data !== null) {
              this.csvLoad = true;
              let blob = new Blob([csvData], {type: 'text/csv'});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'staff-member-tip.csv'
              link.click();
              setTimeout(() => {
                this.csvLoad = false;
              }, 1000);
            }
          })
          .catch(error => console.log(error));
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    },
    showAutomatedReportsModal() {
      axios.get('backoffice/auto-reports-config')
          .then(response => {
            let data = response.data.data;
            this.automatedReportsData = response.data.data;

            if (data.id !== undefined) {
              this.automatedReportsId = data.id;
              this.automatedReportsType = data.type;
              this.automatedReportsTime = data.time;
              this.automatedReportsDay = data.day;

              let emailsCount = data.emails.length;
              if (emailsCount > 0) {
                this.automatedReportsEmails = '';
                for(let i = 0; i < emailsCount; i++) {
                  this.automatedReportsEmails += data.emails[i].email;

                  if (emailsCount-1 !== i) {
                    this.automatedReportsEmails += ',';
                  }
                }
              }
            }
            this.isAutomatedReportsModalVisible = true;
          })
          .catch(error => {
            console.log("ERROR: ");
            console.log(error);
          });
    },
    closeModal () {
      this.isAutomatedReportsModalVisible = false;
    }
  }
}
</script>

<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover {
  color: white;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select {
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


h2{
  font-weight: 700;
  color: #433C8C;
  margin-top: 20px;
}
</style>