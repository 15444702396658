<template>
  <div>
    <AccountSubheader />
    <p>Account</p>
</div>
</template>

<script>
import AccountSubheader from '../components/AccountComponents/AccountSubheader';

export default {
  name: 'Account',
  components: {
    AccountSubheader
  }

}
</script>

<style scoped>
  div p{
    color: rgb(60, 3, 140);
    font-size: 36px;
    font-weight: bold;
  }
</style>
