<template>
  <transition name="modal">
    <div class="modal-backdrop">
      <div class="modal-wrapper">
        <div class="modal-container">
          <!-- Modal body section start -->
          <section class="modal-body">
            <slot name="body">
            </slot>
            <!-- Add Emails modal section start -->
            <div v-if="loadModel === 'add-emails'">
              <p class="px-3 add-email-text">Add email addresses below</p>
              <hr>
              <!-- Add another email section start -->
              <button :disabled="disabled" class="add-another-email mt-1" @click="addNewEmail">Add another email</button>
              <div v-if="showAddEmailSection">
                <ul class="add-email ml-1">
                  <li v-for="(emailInput, index) in emailInputs" :key="index">
                    <input
                      v-model="emailInput.email"
                      :name="`emailInputs[${index}][email]`"
                      :id="index"
                      @click="clearValidationMessages"
                      type="email"
                      placeholder="Enter Email" />
                    <select v-model="emailInput.repetition" :name="`emailInputs[${index}][repetition]`" class="select-type">
                      <option value="daily" :selected="true">Daily</option>
                      <option value="weekly">Weekly</option>
                    </select>
                    <button class="remove-email ml-2 py-1" @click="removeEmail(index)">X</button>
                  </li>
                  <!-- Validation errors start -->
                  <div class="validation-errors-wrapper">
                    <p v-if="emailValidationErrors.length"  class="alert-failed ml-2">
                      <span v-for="error in emailValidationErrors">{{ error }}</span>
                    </p>
                  </div>
                  <!-- Validation errors end -->
                </ul>
                <div class="save-emails">
                  <button type="submit" @click.prevent="saveEmails($event)">Save All</button>
                </div>
              </div>
              <!-- Add another email section end -->

              <!-- NPS email records list section start -->
              <div class="list">
                <!-- API requests response messages start-->
                <div class="messages-wrapper">
                  <p v-if="errors" class="alert-failed text-center p-2" v-for="(error, index) in errors">
                    <span :key="index">Failed! {{error}}</span>
                  </p>
                  <p v-if="success" class="alert-success text-center p-2 mt-2">
                    <span>{{success}}</span>
                  </p>
                </div>
                <!-- API requests response messages end -->
                <h3>Email report recipients</h3>
                <div v-if="onLoadErrors" class="text-center alert-info">
                  <p>{{ this.onLoadErrors }}</p>
                </div>
                <!-- Emails list start -->
                <ul class="list-emails">
                  <div v-for="(e, index) in loadEmails" :key="index">
                    <li v-if="!e.edit" class="float-left">{{ e.email }} | {{e.repetition ? e.repetition : "Daily"}}</li>
                    <!-- Edit email input start-->
                    <input
                      v-if="e.edit"
                      v-model="e.email"
                      type="email"
                      class="update-input m-1"
                    />
                    <select v-if="e.edit" v-model="e.repetition" class="update-input select-type edit-select">
                      <option value="daily" :selected="e.repetition === 'daily' ? 'selected' : ''">Daily</option>
                      <option value="weekly" :selected="e.repetition === 'daily' ? 'selected' : ''">Weekly</option>
                    </select>
                    <!-- Edit email input end-->

                    <!-- Buttons for Edit, Update, Delete, Cancel start -->
                    <div class="p-2">
                      <button
                        v-if="!updateMode"
                        class="edit-email"
                        @click="editEmail(e)">Edit
                      </button>
                      <button
                        v-if="updateMode && e.edit"
                        class="update-email"
                        @click.prevent="updateEmail(e)">Update
                      </button>
                      <button
                        v-if="updateMode && e.edit"
                        class="cancel-email"
                        @click="cancelEmail(e)">Cancel
                      </button>
                      <button
                        v-if="!updateMode"
                        class="delete-email"
                        @click="deleteEmail(e)">Delete
                      </button>
                    </div>
                    <!-- Buttons for Edit, Update, Delete, Cancel end -->
                  </div>
                </ul>
                <!-- Emails list end -->
              </div>
              <!-- NPS email records list section end -->
            </div>
            <!-- Add Emails modal section end -->

            <!-- Emails modal section start -->
            <h3 v-if="loadModel === 'emails'">Emails</h3>
            <ul v-if="loadModel === 'emails'" v-for="email in emails">
              <p>{{eventDate}}</p>
              <li>{{email}}</li>
            </ul>
            <!-- Emails modal section end -->

            <!-- Comments modal section start -->
            <h3 v-if="loadModel === 'comments'">Comments</h3>
            <ul v-if="loadModel === 'comments'" v-for="comment in comments" >
              <p>{{eventDate}}</p>
              <li>{{comment.replace(/\\u2028/g, " ")}}</li>
            </ul>
            <!-- Comments modal section end -->

            <!-- Voice Notes modal section start -->
            <h3 v-if="loadModel === 'voiceNotes'">Voice Notes</h3>
            <ul v-if="loadModel === 'voiceNotes'" v-for="voiceNote in voiceNotes" class="audio">
              <p>{{eventDate}}</p>
              <li>
                <audio id="audio" controls preload="metadata" style=" width:480px;">
                  <source :src="voiceNote" type="audio/wav">
                </audio>
              </li>
            </ul>
            <!-- Voice Notes modal section end -->
          </section>
          <!-- Modal body section end -->

          <!-- Modal footer section start -->
          <footer class="modal-footer mt-3">
            <slot name="footer">
              <button
                type="button"
                class="modal-close-button"
                @click="onClose"
                aria-label="Close modal">
                Close
              </button>
            </slot>
          </footer>
          <!-- Modal body section end -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { mapMutations, mapActions } from 'vuex'
  import npsEmails from '../../store/modules/npsEmails'

  export default {
    name: 'ModalComponent',
    props: {
      loadModel: '',
      emails: '',
      comments: '',
      voiceNotes: '',
      eventDate: ''
    },
    data() {
      return {
        emailInputs: [
          {
            email: '',
            repetition: '',
          }
        ],
        showAddEmailSection: false,
        updateMode: false,
        disabled: false
      }
    },
    computed: {
      loadEmails() {
        return npsEmails.state.loadEmails
      },
      onLoadErrors() {
        return npsEmails.state.onLoadErrors
      },
      emailValidationErrors() {
        return npsEmails.state.emailValidationErrors
      },
      errors() {
        return npsEmails.state.errors
      },
      success() {
        return npsEmails.state.successEmailMessage;
      },
    },
    created () {
      this.emailInputs = [];
      return this.$store.commit('LOAD_ALL_NPS_EMAILS')
    },
    methods: {
      ...mapActions([
        'addNpsEmails',
      ]),
      ...mapMutations([
        'LOAD_ALL_NPS_EMAILS',
        'CLEAR_LOAD_ERROR_MESSAGE',
        'CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE',
        'CLEAR_EMAIL_ERROR_MESSAGE',
      ]),
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      addNewEmail() {
        this.showAddEmailSection = true;
        const newEmail = {
          email: '',
          repetition: ''
        }
        this.emailInputs.push(newEmail)
      },
      removeEmail(index) {
        this.emailInputs.splice(index, 1);
        if (this.emailInputs.length === 0) {
          this.showAddEmailSection = false;
        }
      },
      saveEmails(event) {
        this.emailInputs.map(e => {
          if (!e.email) {
            npsEmails.state.emailValidationErrors = 'Email required.';
            setTimeout(() => {
              this.$store.commit('CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE')
            }, 2000)
          } else if (!this.validEmail(e.email)) {
            npsEmails.state.emailValidationErrors = 'Please enter valid email.';
            setTimeout(() => {
              this.$store.commit('CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE')
            }, 2000)
          } else if (e.repetition === "" || e.repetition === undefined) {
            npsEmails.state.emailValidationErrors = 'Please select repetition period.';
            setTimeout(() => {
              this.$store.commit('CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE')
            }, 2000)
          }
        });

        if (!npsEmails.state.emailValidationErrors.length) {
          this.$store.dispatch('addNpsEmails', JSON.stringify(this.emailInputs));
          this.$store.commit('CLEAR_LOAD_ERROR_MESSAGE');

          this.emailInputs = [];
          this.showAddEmailSection = false;
        }
      },
      deleteEmail(email) {
        this.$store.dispatch('deleteEmail', email);

        this.emailInputs = [];
        this.showAddEmailSection = false;
      },
      editEmail(e) {
        this.updateMode = true;
        e.edit = true;

        this.emailInputs = [];
        this.showAddEmailSection = false;
        this.disabled = true;
      },
      updateEmail(email) {
        this.$store.dispatch('updateEmail', email);

        this.updateMode = false;
        email.edit = false;
        this.disabled = false;
      },
      cancelEmail(e) {
        this.updateMode = false;
        e.edit = false;

        this.emailInputs = [];
        this.showAddEmailSection = false;
        this.disabled = false;

        this.$store.commit('LOAD_ALL_NPS_EMAILS');
      },
      clearValidationMessages() {
        this.$store.commit('CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE');
      },
      onClose() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="css" scoped>
.modal-backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  opacity: 1;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  max-height: 600px;
  margin: 0px auto;
  padding: 10px 30px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 2px 2px 20px 1px;
  overflow: auto;
}

.modal-body {
  padding: 20px 10px;
}

.modal-footer {
  padding: 10px;
  margin: 50px 0;
}

button {
  padding: 10px;
  background: #E85F9A;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
}

.add-another-email {
  padding: 7px 15px;
  margin-left: 10px;
  margin-bottom: 3px;
}

div.save-emails {
  width: 50%;
  padding: 0px;
  margin-left: 10px;
}

div.save-emails > button {
  padding: 7px 17px;
}

.remove-email {
  background-color: #3C038C;
}

.edit-email,
.update-email {
  padding: 5px 15px;
  margin-right: 5px;
  background-color: #3C038C;
  color: #FFFFFF;
}

.delete-email,
.cancel-email {
  color: #E85F9A;
  background: #FFFFFF;
  padding: 10px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.modal-close-button {
  float: right;
  padding: 7px 20px;
}

input{
  border: 1px solid #BCBBBC;
  width: 65%;
  height: 45px;
  padding-left: 12px;
  outline: none;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  color: #202020;
  cursor: text;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.update-input {
  cursor: text;
  background-color:#e7e7e7;
  width: 75%;
  border-radius: 0px;
}

label.pl-3{
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0.3px;
}

input:focus,
input.visited{
  color: #202020;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h3 {
  background: #3C038C;
  color: #FFFFFF;
  text-align: center;
  padding: 20px;
  margin-bottom: 60px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #3C038C;
  border-radius: 2px;
  letter-spacing: 1px;
}

ul {
  min-width: 600px;
  min-height: 100px;
  margin: 20px auto;
  padding: 20px 30px 40px 30px;
  list-style-type: none;
  background-color: rgba(60,3,140,0.1);
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 10px rgba(146, 70, 70, 0.5);
  -moz-box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
}

li {
  padding: 20px;
  padding-bottom: 30px;
  overflow: auto;
  border: 1px solid rgba(60,3,140, 0.2);
  border-radius: 2px;
  background-color: rgb(250, 250, 250);
  -webkit-box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  letter-spacing: .1px;
  }

p {
  color: #3C038C;
  font-size: 14px;
}

ul.audio li {
  padding: 20px 10px 10px 40px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.add-email-text {
  font-size: 18px;
  font-weight: bold;
  color: #3C038C;
  letter-spacing: .2px;
}

ul.add-email {
  min-height: 50px;
  padding: 0px;
  margin: 0px;
  background-color: #FFFFFF;
  box-shadow: none;
}

ul.add-email li {
  padding: 5px;
  border: none;
  background-color: #FFFFFF;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

div.list h3 {
  margin-bottom: 0px;
}

ul.list-emails {
  min-height: 50px;
  padding: 10px 5px 0px;
  background-color: #FFFFFF;
  box-shadow: none;
}

ul.list-emails li {
  margin: 5px;
  padding: 10px;
  width: 75%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
}

div.alert-info {
  height: 110px;
  padding-top: 45px;
  background-color: #FFFFFF;
}

.validation-errors-wrapper {
  min-height: 25px;
}

.messages-wrapper {
  min-height: 70px;
  margin-top: 20px;
  padding-top: 20px;
}

.alert-failed {
  background-color: white;
  color: #ff0000;
}

.alert-success {
  background-color: white;
  color:#7ac142;
}

.select-type {
  padding: 1%;
  border-radius: 5px;
  border: 1px solid #bcbbbc;
  background: white;
  height: 45px;
  margin-left: 5px;
}

.edit-select {
  width: 16%;
  margin-top: 0.4em;
}
</style>
