import { render, staticRenderFns } from "./VideoComponent.vue?vue&type=template&id=30ebf93d&scoped=true&v-if=fileType%20%3D%3D%3D%20'Video'&"
import script from "./VideoComponent.vue?vue&type=script&lang=js&"
export * from "./VideoComponent.vue?vue&type=script&lang=js&"
import style0 from "./VideoComponent.vue?vue&type=style&index=0&id=30ebf93d&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ebf93d",
  null
  
)

export default component.exports