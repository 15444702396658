<template lang="html">
  <div>
     Z-Reports table
    <div v-if="!errored" id="bills" class="bills-table mt-4">
      <table class="table table-hover">
        <thead>
        <tr class="text-body">
          <th>Date</th>
          <th>Number of tables</th>
          <th>Card revenue</th>
          <th>Additional gratuity</th>
          <th>Number of team members</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody v-if="listLoad">
        <tr>Loading...</tr>
        </tbody>
        <tbody v-else-if="!listLoad && results.length > 0">
        <tr v-for="result in results" :key="result.id" class="text-dark">
          <td>
            {{ result.date }}
          </td>
          <td>
            {{ result.number_of_tables }}
          </td>
          <td>
            {{ result.card_revenue }}
          </td>
          <td>
            {{ result.additional_gratuity }}
          </td>
          <td>
            {{ result.number_of_team_members }}
          </td>
          <td>
            <button
              type="button"
              class="btn ml-4 download"
              @click="onCSVExport(result.id)">
              <span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Download CSV
            </button>
          </td>
        </tr>
        </tbody>
        <tbody v-else >
        <tr>
          <td colspan="12" class="text-center alert-info empty-data">
            Nothing to show...
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Info if error -->
    <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </div>

    <Pagination
        @handlePageChange="handlePageChange"
        :maxPage="maxPage"
        :totalItems="totalItems"
    >
      <li slot="body"></li>
    </Pagination>
  </div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "TipManagementReportsComponent",
  components: {
    VueCtkDateTimePicker,
    Pagination
  },
  computed: {
  },
  data(){
    return {
      results: [],
      dateRange: null,
      date_from: '',
      date_to: '',
      csvLoad: false,
      listLoad: true,
      errored: false,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
    }
  },
  created () {
    this.loadData();
  },

  methods: {
    created () {
      this.loadData();
    },
    loadData () {
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            params.append('date_to', this.dateRange.end);
          }
        }
      }
      // Append page param
      if (this.page !== null) {
        params.append('page', this.page);
      }
      // Append perPage param
      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }
      // Api request for transactions list
      axios.get(`backoffice/daily-reports/tip-management-reports-list`, {params: params})
          .then(response => {
            let data = response.data.data;
            this.maxPage = data.max_page;
            this.totalItems = data.total_items;
            this.results = data.items;
          })
          .catch(error => {
            console.log(error)
            this.errored = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.listLoad = false
            }, 300)
          })
    },
    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },
    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    // Api request for CSV data
    onCSVExport (reportId) {
      let params = new URLSearchParams();

      params.append('report_id', reportId)

      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/daily-reports/tip-management-report-csv', {params: params}, config)
          .then(response => {
            const csvData = response.data;
            if (response.status === 200 && response.data !== null) {
              this.csvLoad = true;
              let blob = new Blob([csvData], {type: 'text/csv'});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'staff-member-tip.csv'
              link.click();
              setTimeout(() => {
                this.csvLoad = false;
              }, 1000);
            }
          })
          .catch(error => console.log(error));
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  }
}
</script>

<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover {
  color: white;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select {
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
</style>
