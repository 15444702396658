<template lang="html">
  <div class="">
    <ServiceSubheader />
    <p>Settings</p>
    <ServiceSettingsComponent />
  </div>
</template>

<script>
import ServiceSubheader from '../../components/ServiceComponents/ServiceSubheader';
import ServiceSettingsComponent from '../../components/ServiceComponents/ServiceSettingsComponents/ServiceSettingsComponent';
export default {
  name: "SettingsPage",
  components: {
    ServiceSubheader,
    ServiceSettingsComponent,
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
