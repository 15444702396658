<template lang="html">
  <div class="">
    <MarketingSubheader />
    <p>Receipt Email</p>
    <ReceiptEmailComponent />
  </div>
</template>

<script>
import MarketingSubheader from '../../components/MarketingComponents/MarketingSubheader';
import ReceiptEmailComponent from '../../components/MarketingComponents/ReceiptEmailComponents/ReceiptEmailComponent';
export default {
  name: "ReceiptEmailPage",
  components: {
    MarketingSubheader,
    ReceiptEmailComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
