import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "./tracing.js";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import * as LDClient from "launchdarkly-js-client-sdk";

import posthogPlugin from "./plugins/posthog";
Vue.use(posthogPlugin);

import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

require("./store/subscriber");

let url = "localhost:8081";

const context = {
  kind: "user",
  anonymous: true
};
const client = LDClient.initialize(
  process.env.VUE_APP_LAUNCHDARKLY_CLIENTSIDE_ID,
  context
);

Vue.prototype.ldClient = client;

function set_backend_url() {
  const app_mode = process.env.VUE_APP_MODE;
  const aws_backend = client.variation("hq-toggle-aws-backend", false);

  switch (app_mode) {
    case "production":
      url = "www.app.tableyeti.com";
      if (aws_backend) {
        console.log("backend: production aws");
        axios.defaults.baseURL = process.env.VUE_APP_PROD_AWS_URL;
      } else {
        console.log("backend: production legacy");
        axios.defaults.baseURL = process.env.VUE_APP_PROD_URL;
        url = "www.app.tableyeti.com";
      }
      break
    case "local":
      console.log("backend: local");
      axios.defaults.baseURL = process.env.VUE_APP_LOCAL_URL;
      break
    default:
      url = "www.dev.tableyeti.com";
      if (aws_backend) {
        console.log("backend: dev aws");
        axios.defaults.baseURL = process.env.VUE_APP_DEV_AWS_URL;
      } else {
        console.log("backend: dev legacy");
        axios.defaults.baseURL = process.env.VUE_APP_DEV_URL;
      }
  }

}

set_backend_url();

client.on("ready", () => {
  // initialization succeeded, flag values are now available
  set_backend_url();
});

client.on("change", settings => {
  // flags have changed
  set_backend_url();
});

Vue.config.productionTip = process.env.VUE_APP_MODE === "production";

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("to-fixed", function(value) {
  var number = +value.replace(/[^\d.]/g, "");
  return isNaN(number) ? "0" : parseFloat(number).toFixed(2);
});

Vue.filter("to-fixed-one", function(value) {
  var number = +value.replace(/[^\d.]/g, "");
  return isNaN(number) ? "0" : parseFloat(number).toFixed(1);
});

Vue.filter("currency", function(value) {
  if (value === "/") {
    return value;
  } else {
    return parseFloat(value).toFixed(2);
  }
});

Vue.filter("service", function(value) {
  if (value === 0) {
    return value;
  } else if (value === "/") {
    return value;
  } else {
    return parseFloat(value).toFixed(2);
  }
});

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", url, /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
