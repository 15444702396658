<template>
<div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="date-range-holder" style="width: 100%!important;">
        <VueCtkDateTimePicker
            v-if="!errored"
            v-model="dateRange"
            color="#483D8B"
            buttonColor="#e34696"
            range
            overlay
            format=YYYY-MM-DD
            formatted=ll
            hint="Selected:"
            :disabled="loading"
            label="Filter by date"
            :customShortcuts="datePickerCustomShortcuts"
            :maxDate="maxDate"
            :minDate="minDate"
            @input="dateInput"
        >
        </VueCtkDateTimePicker>
      </div>
    </div>
    <div class="col-md-4 col-sm-12"v-if="!errored && allCompanies.length > 0">
      <div class="col-md-12" >
        <Multiselect
            v-model="selectedCompanies"
            :multiple="true"
            :options="allCompanies"
            :disabled="loading"
            placeholder="Filter by company"
            @input="companiesSelectChanged"
        >
        </Multiselect>
      </div>
    </div>
    <div class="col-md-4 col-sm-12" v-if="!errored">
      <div class="row">
        <div class="col-md-5">
          <button
              type="button"
              class="btn ml-4 search"
              :disabled="loading || (!dateUpdated && !companiesFilterUpdated)"
              @click.prevent="applyFilters"
          >
            Apply filters
          </button>
        </div>
        <div class="col-md-5">
          <button
              type="button"
              class="btn ml-4 reset"
              :disabled="loading || !filtersApplied"
              @click.prevent="resetFilters"
          >
            Reset filters
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Download Transactions Report button -->
  <button v-if="!loading && !errored && results.length > 0"
          type="button"
          class="btn download download-report"
          @click="onCSVExport"><span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Download Report
  </button>

  <div v-if="!errored" id="zreports" class="zreports-table mt-4">
    <div v-if="!loading && (dateUpdated || companiesFilterUpdated)">
      <p class="updated-filters-info">
        The displayed data does not correspond to the filters selection. Please apply the filters in order to get the proper data.
      </p>
    </div>
    <table class="table table-hover">
      <thead>
        <tr class="text-body">
          <th>
            Site Name
          </th>
          <th>
            Settlement <br/> Date
          </th>
          <th>
            Transactions <br/> Date
          </th>
          <th>
            Settlement Total
          </th>
          <th>
            Fees Deducted
          </th>
          <th>
            Tips
            <p v-if="isTipjarCompany" class="tipjar-annotation">
              TiPJAR
            </p>
          </th>
          <th>
            Amount Paid To Bank
          </th>
          <th>
            Date Paid to Bank
          </th>
          <th>
            Invoice ID
          </th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <img style="margin-left: 270%" src="../../assets/spinner.svg" alt="" class="table-logo">
      </tbody>
      <tbody v-else-if="listLoad && this.results.length > 0">
      <tr v-for="result in results" :key="result.id" class="text-dark">
        <td>{{ result.company_name }}</td>
        <td>{{ result.date_created}}</td>
        <td>{{ result.billing_date_from }} - <br/> {{result.billing_date_to }}</td>
        <td><strong>&#163; {{ result.total_net_turnover | service }}</strong></td>
        <td><strong>&#163; {{ result.total_fees | service }}</strong></td>
        <td>
          <strong>&#163; {{result.total_tip | currency }}</strong>
          <p v-if="result.is_tipjar_company && allCompanies.length > 0" class="tipjar-annotation">
            TiPJAR
          </p>
        </td>
        <td>
          <strong>
            &#163; {{result.is_tipjar_company ? result.tipjar_amount_paid : result.payout_amount | currency }}
          </strong>
        </td>
        <td>{{ result.paid_at }}</td>
        <td>{{ result.invoice_id}}</td>

      </tr>
      </tbody>
      <tbody v-else >
      <tr>
        <td colspan="12" class="text-center alert-info empty-data">
          Nothing to show...
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- Info if error -->
  <div v-else class="text-center pt-5 pb-4">
    <Error></Error>
  </div>

  <Pagination
      v-if="!errored && !loading && results.length > 0"
      @handlePageChange="handlePageChange"
      :maxPage="maxPage"
      :totalItems="totalItems"
      :predefinedPerPages="perPages"
      :predefinedPerPage="perPage"
  >
    <li slot="body"></li>
  </Pagination>
</div>
</template>

<script>
import axios from 'axios';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";
import Error from "../Error/ErrorComponent";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  name: "SettlementsComponent",
  components: {
    VueCtkDateTimePicker,
    Pagination,
    Multiselect,
    Error
  },
  data(){
    return {
      results: [],
      dateRange: null,
      date_from: '',
      date_to: '',
      listLoad: false,
      errored: false,
      page: 1,
      perPage: 100,
      perPages: [100],
      maxPage: null,
      totalItems: null,
      merchantId: null,
      selectedCompanies: [],
      preSelectedCompanies: [],
      allCompanies: [],
      loading: true,
      maxDate: this.moment().format('YYYY-MM-DD'),
      minDate: '2018-01-01',
      dateUpdated: false,
      companiesFilterUpdated: false,
      filtersApplied: false,
      datePickerCustomShortcuts: [
        { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
        { key: 'last7Days', label: 'Last 7 days', value: 7 },
        { key: 'last30Days', label: 'Last 30 days', value: 30 },
        { key: 'thisMonth', label: 'This month', value: 'month' },
        { key: 'lastMonth', label: 'Last month', value: '-month' },
      ],
      csvCompanyName: '',
      isTipjarCompany: false,
    }
  },
  created() {
    console.log('aaa');
    console.log(this.page);
    this.loadData();
  },
  methods: {
    moment() {
      return moment();
    },
    loadData() {
      this.filtersApplied = false;
      this.loading = true;
      this.listLoad = false;

      let params = new URLSearchParams();

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.filtersApplied = true;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      if (this.selectedCompanies.length) {
        this.filtersApplied = true;
        params.append('companies', JSON.stringify(this.selectedCompanies));
      }

      axios.get("backoffice/settlements",  {params: params})
          .then(res => {
            let data = res.data.data;

            if (!res.data.success) {
              this.errored = true;
              this.loading = false;
              return;
            }

            this.maxPage = data.max_page;
            this.totalItems = data.total_items;
            this.preSelectedCompanies = data.filtered_companies;
            this.allCompanies = data.all_companies;
            this.loading = false;
            this.dateUpdated = false;
            this.companiesFilterUpdated = false;
            this.csvCompanyName = data.csv_company_name;
            this.isTipjarCompany = data.is_tipjar_company;

            this.results = data.items;
          }).
          catch( (err) => {
            this.message = err.response.data.message;
            this.error = true;
            this.errored = true;
            this.loading = false;
            this.clearMessage();
          }).finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 300)
          });
      this.busy = false;
      this.listLoad = true;

    },
    clearMessage(errorValue = false) {
      setTimeout(() => {
        this.message = null;
        this.error = errorValue;
      }, 3000);
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    },
    searchCompanyByName() {
      this.page = 1;

      this.loadData();
    },
    resetCompaniesFilter() {
      this.selectedCompanies = [];
      this.loadData();
    },
    dateInput() {
      this.dateUpdated = true;
      let now = new moment();
      let max = new moment(this.dateRange.start);
      let min = new moment(this.dateRange.start);

      let maxDateValue = max.add(31, 'd');
      if (maxDateValue > now) {
        maxDateValue = now;
      }

      this.maxDate = maxDateValue.format('YYYY-MM-DD');
      this.minDate = min.subtract('31', 'd').format('YYYY-MM-DD');
    },
    companiesSelectChanged() {
      this.companiesFilterUpdated = true;
    },
    applyFilters() {
      this.page = 1;
      this.loading = true;

      this.loadData();
    },
    resetFilters() {
      this.page = 1;
      this.selectedCompanies = [];
      this.dateRange = null;
      this.loading = true;

      this.loadData();
    },
    onCSVExport () {
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.date_from = this.dateRange.start;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            this.date_to = this.dateRange.end;
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      // Append selected companies
      if (this.selectedCompanies.length) {
        params.append('companies', JSON.stringify(this.selectedCompanies));
      }

      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/settlements/csv', {params: params}, config)
          .then(response => {
            let csvName = '';
            if (this.csvCompanyName !== '') {
              csvName = `Settlement_Report_${this.csvCompanyName}_${new Date().toLocaleDateString('en-UK')}.csv`
            } else {
              csvName = `Settlement_Report_${new Date().toLocaleDateString('en-UK')}.csv`;
            }
            const csvData = response.data;
            if (response.status === 200 && response.data !== null) {
              this.csvLoad = true;
              let blob = new Blob([csvData], {type: 'text/csv'});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              if ((this.date_from !== null && this.date_from !== '' && this.date_from !== undefined)
                  || (this.date_to !== null && this.date_to !== '' && this.date_to !== undefined)) {
                link.download = csvName;
              } else {
                link.download = csvName;
              }
              link.click();
              setTimeout(() => {
                this.csvLoad = false;
              }, 1000);
            }
          })
          .catch(error => console.log(error));
    },
  }
}
</script>


<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover, button.search:hover{
  color: white;
}

button.search {
  background: #E85F9A;
  color: white;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  margin-top: 2%;
}

button.search:disabled {
  background: #f69bc1;
  cursor: default;
}

button.reset {
  background: white;
  color: #E85F9A;
  -webkit-box-shadow: 0px 0px 13px -2px rgb(246, 155, 193);
  -moz-box-shadow: 0px 0px 13px -2px rgb(173, 117, 138);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  margin-top: 2%;
}

button.reset:disabled {
  background: #b7b7b7;
  cursor: default;
}

.tooltipa{
  height: 19px;
  width: 19px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #D25793;
  color: #D25793;
  position: absolute;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 15px;
  z-index: 2;
}

.yeti{
  right: -2px;
}

.tooltiptext{
  visibility: hidden;
  width: 400px;
  padding: 10px;
  padding-top: 15px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D25793;
  border-radius: 2px;
  bottom: 100%;
  right: 100%;
  font-size: 14px;
}

.cards,
.exp{
  left: 100%;
}

.tooltipa:hover .tooltiptext {
  visibility: visible;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  position: relative;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select{
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.updated-filters-info {
  font-size: 12px;
  color: #3c038c;
  background-color: #d49eb5;
  width: 52%;
  border-radius: 5px;
  padding: 5px;
}

.download-report{
  margin-top: 2%;
  float: right;
}

.tipjar-annotation{
  font-size: 0.675em;
  font-weight: 800;
  color: #6b0202;
  margin-top: 5px;
  margin-bottom: 0;
}
</style>