<template>
  <div>
    <div class="row">
      <div class="col-2 mt-3 mr-3">
        <img src="../../assets/mailchimp-logo.png" alt="" class="chimp-icon">
      </div>
      <div class="col-3">
        <button class="btn setup" data-toggle="modal" data-target="#myModal">Setup</button>
      </div>
    </div>
    <div class="row">
      <div class="col-2 mt-5 mr-3">
        <img src="../../assets/zapier-logo.png" alt="" class="zapier-icon">
      </div>
      <div class="col-3">
        <button class="btn setup" data-toggle="modal" data-target="#myModal">Setup</button>
      </div>
    </div>
    <!-- Modal start -->
    <div class="modal fade" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="modal-title">Coming soon</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
  </div>
</template>

<script>
export default {
  name: "IntegrationsComponent",
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="css" scoped>
img {
  display: block;
}

.chimp-icon {
  width: 14em;
}

.chimp-name {
  width: 18em;
}

.zapier-icon {
  width: 16em;
}

button.setup {
  padding: 10px 75px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-top: 90px;
}

button.setup:hover {
  color: white;
}

.modal-content {
  height: 200px;
}

.modal-title {
  text-align: center;
  padding: 70px 0;
}

@media only screen and (max-width: 767px) {
  button.setup {
    float: left;
    margin-left: 80px;
  }
}
</style>
