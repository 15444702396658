<template lang="html">
  <div class="">
    <FeedbackSubheader />
    <p>Feedback Manager</p>
    <FeedbackManagerComponent />
  </div>
</template>

<script>
import FeedbackSubheader from '../../components/FeedbackComponents/FeedbackSubheader';
import FeedbackManagerComponent from '../../components/FeedbackComponents/FeedbackManagerComponent';
export default {
  name: "ExperimentResultsPage",
  components: {
    FeedbackSubheader,
    FeedbackManagerComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
