
<template lang="html">
  <div class="inner-colour-container">
    <form class="" action="" method="">
      <select v-on:change="onChangeOption" ref="option">
        <option disabled selected value="3">Select Experiment</option>
        <option value="0">Yes or No</option>
        <option value="1">10x worse - 10x better</option>
        <option value="2">Not disappointed - Very disappointed</option>
      </select>
      <div class="result-screen">
        <input v-if="this.options.yesNoScreen" type="text" name="" value="" v-model="options.yesNoQuestion" class="input-modal" placeholder="Question">
        <input v-if="this.options.worseBetterScreen" type="text" name="" value="" v-model="options.worseQuestion" class="input-modal" placeholder="Question">
        <input v-if="this.options.notVeryDissapointedScreen" type="text" name="" value="" v-model="options.disappointedQuestion" class="input-modal" placeholder="Question">
        <template v-if="options.ButtonChangeToUpdate">
          <button type="submit" class="save btn ml-3" v-on:click.prevent="onUpdate(options)">Update</button>
        </template>
        <template v-else>
          <button v-if="this.options.worseBetterScreen || this.options.notVeryDissapointedScreen || this.options.yesNoScreen" type="submit" name="button" class="btn save" v-on:click.prevent=" save()">Save</button>
        </template>
      </div>
    </form>

    <div class="iphone-holder float-right">
      <div class="inner">
        <div class="content text-center">
          <h6 class="mb-0 pt-3">Your Bill</h6>
          <p class=" mb-0 pb-1 secondary table-text">Table 7</p>
          <img alt="ham logo" src="../../assets/hamburger_menu_icon.png" class="mr-2 ham-img">
        </div>
        <div class="container-fluid ">
          <div class="row">
            <div class="col-md-12 parent-holder p-0">
              <ul class="">
                <li class="first">Tap <img src="../../assets/like-icon.svg" alt="" class="first"> below to leave feedback  <img alt="info logo" src="../../assets/info-icon.png" class="mr-2 info-img"> <span class="float-right secondary amount">Amount</span> </li>
                <li v-for="item in items" class="my-1 py-1 px-2">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   width="84.000000pt" height="84.000000pt" viewBox="0 0 84.000000 84.000000"
                   preserveAspectRatio="xMidYMid meet" class="mr-2">
                  <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
                  fill="#E85F9A" stroke="none" class="primary-svg">
                  <path d="M494 816 c-9 -39 3 -78 24 -74 13 3 17 14 17 48 0 34 -4 45 -17 48
                  -12 2 -19 -5 -24 -22z"/>
                  <path d="M340 752 c0 -25 45 -66 64 -59 23 9 20 30 -7 55 -28 27 -57 29 -57 4z"/>
                  <path d="M641 746 c-23 -24 -23 -56 -1 -56 17 0 62 49 57 63 -7 21 -32 18 -56
                  -7z"/>
                  <path d="M431 623 c-22 -74 -66 -141 -104 -162 -22 -12 -28 -11 -54 8 -26 19
                  -40 21 -123 19 l-95 -3 0 -240 0 -240 95 -3 c81 -2 98 0 121 17 26 20 27 20
                  89 0 48 -15 91 -19 196 -19 130 0 136 1 159 25 14 13 25 35 25 49 0 14 11 36
                  25 50 26 26 33 64 15 82 -8 8 -7 19 1 37 9 20 9 33 1 51 -8 18 -8 33 1 55 10
                  26 8 34 -9 58 -20 26 -27 28 -110 33 -73 4 -88 8 -85 20 2 8 6 50 9 92 4 75 4
                  78 -26 108 -24 23 -39 30 -70 30 l-40 0 -21 -67z m97 5 c15 -15 16 -100 1
                  -150 -9 -35 -13 -38 -45 -38 -31 0 -35 -3 -32 -22 3 -22 7 -23 138 -26 74 -1
                  139 -7 144 -12 16 -16 -13 -39 -52 -42 -25 -2 -37 -8 -37 -18 0 -10 12 -16 37
                  -18 65 -6 78 -52 14 -52 -35 0 -62 -21 -51 -40 3 -5 21 -10 38 -10 41 0 62
                  -14 54 -34 -4 -10 -18 -16 -40 -16 -55 0 -76 -33 -31 -50 16 -7 24 -16 22 -28
                  -3 -15 -19 -17 -132 -20 -110 -2 -139 1 -193 19 l-63 21 0 154 c0 120 3 154
                  13 154 40 0 136 109 157 179 7 20 13 42 15 49 5 15 27 16 43 0z m-283 -383 l0
                  -190 -72 -3 -73 -3 0 196 0 196 73 -3 72 -3 0 -190z"/>
                  <path d="M154 135 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36 0z"/>
                  </g>
                  </svg>
                 {{item.name}} <span class="float-right">&#163; {{item.price}}</span> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bottom">
          <p class="px-3 pt-1">You can add service charge d...<span class="float-right total">Total &#163; 33.25</span> </p>
          <button type="button" name="button" class="mb-2 primary" disabled>Pay whole bill <img alt="people logo" src="../../assets/icon-button-single.png" class="ml-2"> </button>
          <button type="button" name="button" disabled class="primary">Split bill <img alt="people logo" src="../../assets/icon-button-multiple.png"></button>
        </div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="256.000000pt" height="212.000000pt" viewBox="0 0 256.000000 212.000000"
         preserveAspectRatio="xMidYMid meet" class="arrow-img">
        <g transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
        fill="#E85F9A" stroke="none" class="primary-svg">
        <path d="M231 2089 c-88 -29 -181 -119 -205 -199 -21 -70 -20 -129 2 -187 10
        -27 126 -214 259 -418 132 -203 314 -485 405 -625 392 -607 380 -590 488 -631
        43 -17 71 -20 130 -17 127 8 201 59 293 203 24 39 194 306 377 595 183 289
        380 599 438 689 118 186 132 219 132 306 0 116 -74 221 -196 277 -39 18 -67
        23 -139 23 -81 0 -95 -3 -143 -29 -29 -17 -66 -44 -81 -61 -15 -17 -178 -268
        -362 -558 -183 -290 -337 -527 -340 -527 -3 0 -162 240 -352 534 -192 295
        -363 548 -382 565 -89 77 -205 99 -324 60z m213 -190 c18 -19 170 -245 336
        -504 167 -258 351 -541 407 -628 l104 -159 88 139 c48 76 233 367 411 646 236
        372 331 514 354 528 65 40 176 12 216 -54 43 -70 36 -86 -158 -392 -98 -154
        -314 -494 -480 -755 -166 -262 -315 -489 -332 -505 -24 -23 -41 -29 -87 -33
        -94 -6 -98 -2 -361 403 -128 198 -290 448 -360 555 -401 619 -402 620 -402
        661 0 29 8 47 32 75 44 49 81 65 146 61 45 -3 58 -9 86 -38z"/>
        </g>
        </svg>
      </div>
      <!-- <form class="" action="index.html" method="post"> -->
        <div v-if="this.options.yesNoScreen" class="inner">
          <div class="battery-row">
            <img src="../../assets/icon-signal.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-loading.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-wireless.png" alt="" class="left-battery-row">
            <p class="centar"><span>11:34</span></p>
            <p class="centar"><span>77%</span></p>
            <img src="../../assets/icon-battery.png" alt="" class="right-battery-row">
          </div>

          <p class="text-center my-3 px-4 font-weight-bold">{{this.options.yesNoQuestion}}</p>
          <div class="form-check" v-for="(options,index) in this.optionsYesNoQuestion" :key="index">
            <label class="form-check-label" >
              <input type="radio" v-bind:class="options.class" v-bind:name="options.name" v-bind:value="options.value" v-model="selectedValueRadio">{{options.answer}}
            </label>
          </div>
        </div>

        <div v-if="this.options.worseBetterScreen" class="inner">
          <div class="battery-row">
            <img src="../../assets/icon-signal.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-loading.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-wireless.png" alt="" class="left-battery-row">
            <p class="centar"><span>11:34</span></p>
            <p class="centar"><span>77%</span></p>
            <img src="../../assets/icon-battery.png" alt="" class="right-battery-row">
          </div>

          <p class="text-center my-3 px-4 font-weight-bold">{{this.options.worseQuestion}}</p>
          <div class="form-check" v-for="(options,index) in this.optionsWorseBetterQuestion" :key="index">
            <label class="form-check-label " >
              <input type="radio" v-bind:class="options.class" v-bind:name="options.name" v-bind:value="options.value" v-model="selectedValueRadio">{{options.answer}}
            </label>
          </div>
        </div>

        <div v-if="this.options.notVeryDissapointedScreen" class="inner">
          <div class="battery-row">
            <img src="../../assets/icon-signal.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-loading.png" alt="" class="left-battery-row">
            <img src="../../assets/icon-wireless.png" alt="" class="left-battery-row">
            <p class="centar"><span>11:34</span></p>
            <p class="centar"><span>77%</span></p>
            <img src="../../assets/icon-battery.png" alt="" class="right-battery-row">
          </div>

          <p class="text-center my-3 px-4 font-weight-bold">{{this.options.disappointedQuestion}}</p>
          <div class="form-check" v-for="(options,index) in this.optionsDisappointedQuestion" :key="index">
            <label class="form-check-label" >
              <input type="radio" v-bind:class="options.class" v-bind:name="options.name" v-bind:value="options.value" v-model="selectedValueRadio">{{options.answer}}
            </label>
          </div>
        </div>
      </div>
      <!-- </form> -->

      <div v-if="this.options.leftSideCardInfo ">
        <draggable :list="this.options.optionsArr" :forceFallback="true">
          <div class="result align-items-center py-2" v-for="(card,index) in options.optionsArr" :key="card.x" >
            <div class="float-left ml-3 ">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p class="ml-3 mt-3 d-inline-block">{{card.question}}</p>
            <div class="float-right ios-correct">
              <button type="button" name="button" class="btn view" data-toggle="modal" data-target="#myModal">View Results</button>
              <button type="button" name="button" class="mx-2 btn edit" v-on:click="openLeftSideCardBackToStepOne(card)">Edit</button>
            </div>
            <div class="acti-deacti">
              <input type="radio" :id="index" :name="'option-selected'" :value="card.activate" @change="handleChange($event,card.x)" style="display:none;">
              <label :for="index" style="border: none; cursor:pointer;" v-bind:class='card.activate ? "text-danger" : "text-success"'>{{card.activateText}}</label>
            </div>
          </div>
        </draggable>
      </div>
      <!-- {{this.options.optionsArr}} -->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "ExperimentComponent",
  components: {
    draggable
  },
  data(){
    return{
      items: [
        {name: "Mango", price: "3.95"},
        {name: "Mango", price: "3.95"},
        {name: "Mango", price: "3.95"},
        {name: "Plum", price: "4.95"},
        {name: "Green Appple", price: "6.95"},
        {name: "Honey Fresh Tea", price: "5.95"},
        {name: "Honeydew", price: "3.95"}
      ],
      optionsYesNoQuestion: [
        {id: 1, answer: "Yes", value: "yes", name: "yesNo", class:"form-check-input", type:"yesNoScreen"},
        {id: 2, answer: "No", value: "no", name: "yesNo", class:"form-check-input", type:"yesNoScreen"},
      ],
      optionsWorseBetterQuestion: [
        {id: 1, answer: "10x better", value: "10Better", name: "worse", class:"form-check-input", type:"worseBetterScreen"},
        {id: 2, answer: "Somewhat better", value: "someBetter", name: "worse", class:"form-check-input", type:"worseBetterScreen"},
        {id: 3, answer: "Neither better or worse", value: "neither", name: "worse", class:"form-check-input", type:"worseBetterScreen"},
        {id: 4, answer: "Worse", value: "worse", name: "worse", class:"form-check-input", type:"worseBetterScreen"},
        {id: 5, answer: "10x worse", value: "10Worse", name: "worse", class:"form-check-input", type:"worseBetterScreen"},
      ],
      optionsDisappointedQuestion: [
        {id: 1, answer: "Very disappointed", value: "veryDsp", name: "disappointed", class:"form-check-input", type:"notVeryDissapointedScreen"},
        {id: 2, answer: "Somewhat disappointed", value: "someDsp", name: "disappointed", class:"form-check-input", type:"notVeryDissapointedScreen"},
        {id: 3, answer: "Not disappointed", value: "notDsp", name: "disappointed", class:"form-check-input", type:"notVeryDissapointedScreen"},
      ],
      options: {
        yesNoScreen: false,
        worseBetterScreen: false,
        notVeryDissapointedScreen: false,
        questionsInCards: "",
        yesNoQuestion: "",
        worseQuestion: "",
        disappointedQuestion: "",
        optionsArr: [],
        optionsObj: {},
        leftSideCardInfo: false,
        activate: true,
        activateText: "Deactivate",
        x: 0,
        ButtonChangeToUpdate: false,
        type: ""
      },
      selectedValueRadio: "",
    }
  },
    methods: {
      onChangeOption(){
        if (this.$refs.option.value ==  0) {
          this.options.yesNoScreen = true;
          this.options.worseBetterScreen = false;
          this.options.notVeryDissapointedScreen = false;
          this.options.leftSideCardInfo = false;
          this.options.yesNoQuestion = "";
          this.options.worseQuestion = "";
          this.options.disappointedQuestion = "";
          this.selectedValueRadio = "";
          this.options.type = 'yesNoScreen';

        }
        else if (this.$refs.option.value == 1) {
          this.options.yesNoScreen = false;
          this.options.worseBetterScreen = true;
          this.options.notVeryDissapointedScreen = false;
          this.options.leftSideCardInfo = false;
          this.options.yesNoQuestion = "";
          this.options.worseQuestion = "";
          this.options.disappointedQuestion = "";
          this.selectedValueRadio = "";
          this.options.type = 'worseBetterScreen';
        }
        else if (this.$refs.option.value == 2) {
          this.options.yesNoScreen = false;
          this.options.worseBetterScreen = false;
          this.options.notVeryDissapointedScreen = true;
          this.options.leftSideCardInfo = false;
          this.options.yesNoQuestion = "";
          this.options.worseQuestion = "";
          this.options.disappointedQuestion = "";
          this.selectedValueRadio = "";
          this.options.type = 'notVeryDissapointedScreen';
        }
      },
      save(){
        this.options.yesNoScreen = false;
        this.options.worseBetterScreen = false;
        this.options.notVeryDissapointedScreen = false;
        this.$refs.option.value = 3;
        this.options.leftSideCardInfo = true;
        // this.options.optionValue = this.$refs.sase.value;
        // this.yesNoQuestion = "";

        let optionsArrCopy = [...this.options.optionsArr];
        this.options.optionsObj = {
          question: this.options.worseQuestion || this.options.yesNoQuestion || this.options.disappointedQuestion,
          activate: this.options.activate,
          activateText: this.options.activateText,
          x: Date.now(),
          value: this.selectedValueRadio,
          type: this.options.type
        }
        console.log(this.options.optionsObj)

        optionsArrCopy.unshift(this.options.optionsObj);
        this.options.optionsArr = optionsArrCopy;

        // console.log(this.options.optionsArr)

      },
      // handleChange(e,x){
      //   this.options.optionsArr.filter(o=>o.x===x).map(o=>o.activate=false);
      //   this.options.optionsArr.filter(o=>o.x===x).map(o=>o.activateText="Activate");
      //   this.options.optionsArr.filter(o=>o.x!==x).map(o=>o.activate=true);
      //   this.options.optionsArr.filter(o=>o.x!==x).map(o=>o.activateText="Deactivate");
      //   },
      openLeftSideCardBackToStepOne(index){
        // console.log(index)
        this.options.leftSideCardInfo = false;
        this.options.ButtonChangeToUpdate = true;

        if(index.type === "yesNoScreen") {
          this.options.yesNoScreen = true;
          this.options.yesNoQuestion = index.question;
          this.selectedValueRadio = index.value;
          this.options.value = index.value;
          this.options.x = index.x;
          this.options.activate = index.activate;
          this.options.activateText = index.activateText;
          this.options.type = index.type;
        } else if(index.type === "worseBetterScreen") {
          this.options.worseBetterScreen = true;
          this.options.worseQuestion = index.question;
          this.options.x = index.x;
          this.options.activate = index.activate;
          this.options.activateText = index.activateText;
          this.selectedValueRadio = index.value;
          this.options.value = index.value;
          this.options.type = index.type;
        } else {
          this.options.notVeryDissapointedScreen = true;
          this.options.disappointedQuestion = index.question;
          this.options.x = index.x;
          this.options.activate = index.activate;
          this.options.activateText = index.activateText;
          this.selectedValueRadio = index.value;
          this.options.value = index.value;
          this.options.type = index.type;
        }
        // console.log(this.options)

      },
      onUpdate(obj) {
        // console.log(obj)
        this.options.leftSideCardInfo = true;
        this.options.yesNoScreen = false;
        this.options.worseBetterScreen = false;
        this.options.notVeryDissapointedScreen = false;
        let question = "";
        let optionsArrCopy = [...this.options.optionsArr];

        let updatedItem = this.options.optionsArr.find((element) => { return element.x === obj.x })
        let foundIndex = this.options.optionsArr.findIndex(element => element.x === obj.x)

        if(obj.type === 'yesNoScreen') {
          question = obj.yesNoQuestion
        } else if(obj.type === 'worseBetterScreen') {
          question = obj.worseQuestion
        } else {
          question = obj.disappointedQuestion
        }

        updatedItem = {
          question: question,
          activate: obj.activate,
          activateText: obj.activateText,
          value: this.selectedValueRadio,
          x: obj.x,
          type: obj.type
        }

        optionsArrCopy[foundIndex] = updatedItem;
        this.options.optionsArr = optionsArrCopy;

        this.options.ButtonChangeToUpdate = false;
      }
    }
}
</script>

<style lang="css" scoped>
.iphone-holder{
  background-image: url("../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -111px;
}

.inner{
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.parent-holder{
  background: #E8E8E8;
  padding: 0 6px!important;
}

.content p.table-text{
  color: #463C8B;
  font-weight: bold;
  font-size: 14px;
}

ul li.first{
  background: #F0F0F0;
  padding: 8px!important;
  margin-top: 6px;
  font-size: 11px;
  color: #B3B3B3;
}

.content{
  position: relative;
}

.content h6,
.total{
  color: #1F1F1F;
  font-weight: bold;
}

.content img.ham-img{
  height: 13px;
  position: absolute;
  top: 19px;
  right: 3px;
}

svg.arrow-img{
  height: 5em;
  width: 100px;
  position: absolute;
  bottom: 33%;
  left: 33%;
}

ul{
  list-style: none;
  padding: 0;
  background: #E8E8E8;
}

ul li{
  display: block;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
}

ul li span.amount{
  color: #463C8B;
  font-weight: bold;
  font-size: 15px;
}

ul li svg{
  height: 2em;
  width: 31px;
}

ul li img.first{
  vertical-align: sub;
  height: 2em;
}

ul li img{
  height: 1.3em;
}

.bottom p{
  font-size: 10px;
  color: #C9C9C9;
  font-weight: bold;
}

.bottom p span{
  font-size: 14px;
}

.bottom button{
  background: #D15895;
  border-radius: 20px;
  border: none;
  width: 90%;
  margin-left: 5%;
  padding: 7px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
}

.bottom button img{
  height: 1.8em;
}

button.save-color{
  padding: 15px 60px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}


div.battery-row {
  background-color: #F4F5F7;
  height: 25px;
  width: 100%;
  padding:  0 5px;
}

img.left-battery-row {
  height: .9em;
  margin-right: 5px;
  margin-top: 5px;
}

img.right-battery-row {
  height: 1.3em;
  margin-left: 4px;
}

p.centar {
  display: inline-block;
  width: 35%;
  text-align: end;
  font-size: 12px;
}

div.result-screen{
  position: absolute;
}


div.result{
  height: auto;
  width: 60%;
  padding-right: 15px;
  color: white;
  border-radius: 6px;
  margin-top: 18px;
  cursor: move;
  position: relative;
  background: #433C8C;
}


div.result span{
  background: white;
  padding: 4px;
  border-radius: 5px;
  display: block;
  margin-top: 6px;
}

div.result p{
  width: 59%;
  font-weight: bold;
}

button.next,
button.save,
button.back{
  padding: 13px 60px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  outline: none;
  margin-left: 25px;
}

button.back,
button.view,
button.edit{
  background: white;
  border: 1px solid #E85F9A;
  color: #E85F9A;
  margin: 5px 0px;
  font-weight: bold;
}

button.edit{
  padding: 6px 40px;
  margin: 10px 0;
}

div.acti-deacti{
  position: absolute;
  right: -82px;
  bottom: 15px;
}

div.form-check{
  margin-left: 12px;
}

select{
  height: 55px;
  width: 310px;
  border-radius: 7px;
  background: transparent;
  padding-left: 12px;
}

input.input-modal{
  border: 1px solid #BCBBBC;
  width: 410px;
  height: 55px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  padding-left: 12px;
}

input{
  display: block;
  color: #202020;
  cursor: pointer;
}


input::placeholder{
  color: #BCBBBC;
  /* padding-left: 12px; */
}


input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: 0px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #433C8C;
}

input[type='radio']:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: #433C8C;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

label{
  cursor: pointer;
}
</style>
