<template lang="html">
  <div class="inner-colour-container">
    <p class="my-0">Below you can upload a image or video to greet your guests on device.</p>
    <p class="my-0">Click here to view spec.</p>
    <br />
    <SelectInputComponent
      @clickChange="onChange"
      :fileType="fileType" />
    <template v-if="fileType === 'Select Type (Video/Image)'">
      <label for="filePicker" class="pl-3" id="file" @click="onChangeFile">
        Select File
        <input type="file" id="filePicker" style="display:none" disabled />
      </label>
      <button type="submit" name="button" class="btn ml-4 save-color" @click="onSaveFile">Save</button>
    </template>

    <ImageComponent
      v-if="fileType === 'Image'"
      :fileType="fileType"
      :loadFiles="loadFiles" />
    <VideoComponent
      v-if="fileType === 'Video'"
      :fileType="fileType"
      :loadFiles="loadFiles" />

    <!-- Iphone Holder -->
    <div v-if="fileType === 'Select Type (Video/Image)'" class="iphone-holder float-right">
    <template v-if="loadFiles.length === 0">
      <template>
        <div class="inner"></div>
      </template>
    </template>
    <template v-for="loadFile in loadFiles">
      <template v-if="loadFile.image !== null">
        <div v-if="loadFile.image !== '' || loadFile.image !== undefiend"
          class="inner-upload" :style="{backgroundImage:'url(' + loadFile.image + ')'}"></div>
      </template>
      <template v-if="loadFile.video !== null">
        <div v-if="loadFile.video !== '' || loadFile.video !== undefiend || loadFile.video !== null">
          <div class="inner-upload-video"></div>
          <div class="video">
            <video controls :src="loadFile.video" autoplay></video>
          </div>
        </div>
      </template>
    </template>
      <div class="bottom">
        <button type="button" name="button" class="mb-2 primary" disabled>Get Started - Pay Bill </button>
        <p>Powered by Table Yeti <img src="../../../assets/tableYeti-logo.png" alt=""> </p>
      </div>
    </div>

    <p v-if="error" class="alert-danger ">{{ message }}</p>
    <div v-if="loadFilesErrors" class="alert-danger text-center load-error">
      <p>{{ loadFilesErrors }}</p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import auth from '../../../store/auth'
import welcome from '../../../store/modules/welcome'
import axios from 'axios'

import SelectInputComponent from '../WelcomeHomeComponents/SelectInputComponent';
import ImageComponent from '../WelcomeHomeComponents/ImageComponent';
import VideoComponent from '../WelcomeHomeComponents/VideoComponent';

export default {
  name: "WelcomeScreenComponent",
  components: {
    SelectInputComponent,
    ImageComponent,
    VideoComponent,
  },
  data () {
    return {
      optionColorChanged: false,
      error: false,
      message: '',
      loadFile: '',
    }
  },
  created () {
    return this.$store.commit('LOAD_WELCOME_FILES')
  },
  destroyed () {
    welcome.state.fileType = 'Select Type (Video/Image)'
  },
  computed: {
    fileType() {
      return welcome.state.fileType
    },
    loadFiles() {
      return welcome.state.loadFiles
    },
    loadFilesErrors() {
      return welcome.state.loadFilesErrors
    }
  },
  methods: {
    ...mapMutations([
      'SET_FILE_TYPE',
      'LOAD_WELCOME_FILES',
      'CLEAR_LOAD_ERROR_MESSAGE',
      'CLEAR_IMAGE_ERROR_MESSAGE',
      'CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE',
      'CLEAR_VIDEO_ERROR_MESSAGE',
      'CLEAR_PREVIEW_VIDEO_ERROR_MESSAGE'
    ]),
    onChange (value) {
      this.$store.commit('CLEAR_LOAD_ERROR_MESSAGE')
      this.$store.commit('CLEAR_IMAGE_ERROR_MESSAGE')
      this.$store.commit('CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE')
      this.$store.commit('CLEAR_VIDEO_ERROR_MESSAGE')
      this.$store.commit('CLEAR_PREVIEW_VIDEO_ERROR_MESSAGE')
      this.$store.commit('SET_FILE_TYPE', value)
      this.error = false;
      this.message = '';
    },
    onChangeFile () {
      this.$store.commit('CLEAR_LOAD_ERROR_MESSAGE')
      this.error = true
      this.message = 'Please first select file type'
    },
    onSaveFile () {
      this.$store.commit('CLEAR_LOAD_ERROR_MESSAGE')
      this.error = true
      this.message = 'Please first select type and file'
    }
  }
}
</script>

<style lang="css" scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -210px;
}

.inner,
.inner-upload {
  background: white;
  background-image: url("../../../assets/tableYeti-logo.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 62px;
  background-size: 27em;
  z-index: 2;
  position: absolute;
  width: 81%;
  left: 35px;
  top: 92px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inner-upload {
  background-size: cover;
  background-position: center;
}

.video,
.static-video {
  z-index: 3;
  position: absolute;
  left: 35px;
  top: 92px;
  width: 81.4%;
}

.static-video {
  display: none;
}

video {
  width: 100%;
  object-fit: cover;
  height: 33.5em;
  outline: none;
}

.bottom {
  height: 100px;
  background: rgba(80, 55, 55, 0.5);
  position: absolute;
  bottom: 87px;
  left: 34px;
  width: 81.6%;
  z-index: 3;
}

.bottom p {
  color: #BFC0C0;
  font-size: 13px;
  float: right;
  margin-right: 2%;
  font-weight: bold;
}

.bottom img {
  height: 1.5em;
}

.bottom button {
  background: #D15895;
  border-radius: 20px;
  border: none;
  width: 90%;
  margin-left: 5%;
  margin-top: 8%;
  padding: 9px;
  color: #FFFFFF;
  font-weight: bold;
}

button.save-color {
  padding: 15px 60px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

label,
input {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 60px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

label.pl-3 {
  margin-bottom: 0px;
  font-size: 15px;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
}

input:focus,
input.visited,
select:focus {
  color: #202020;
}

.alert-danger {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 5px;
}

.load-error {
  padding-top: 80px;
}
</style>
