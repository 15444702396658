// tracing.js
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import {
  WebTracerProvider,
  BatchSpanProcessor
} from "@opentelemetry/sdk-trace-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

import store from "./store/store";
import { propagation } from "@opentelemetry/api";

console.log(process.env.VUE_APP_HONEYCOMB_API_KEY);

class AuthSpanProcessor extends BatchSpanProcessor {
  // augment all spans with auth attributes, if we know them
  onStart(span, parentContext) {
    let user = store.getters["auth/user"];
    if (user) {
      let userAttrs = Object.entries(user.data.user).reduce(
        (acc, [key, value]) => {
          acc[`app.user.${key}`] = value;
          return acc;
        },
        {}
      );
      span.setAttributes(userAttrs);
    }
  }
}

// The exporter is responsible for sending traces from the browser to your collector
const exporter = new OTLPTraceExporter({
  url: "https://api.honeycomb.io/v1/traces",
  headers: {
    "x-honeycomb-team": process.env.VUE_APP_HONEYCOMB_API_KEY
  }
});
// The TracerProvider is the core library for creating traces
const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: "browser"
  })
});
// The processor sorts through data as it comes in, before it is sent to the exporter

provider.addSpanProcessor(new AuthSpanProcessor(exporter));

// A context manager allows OTel to keep the context of function calls across async functions
// ensuring you don't have disconnected traces
provider.register({
  contextManager: new ZoneContextManager()
});

//
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      // load custom configuration for xml-http-request instrumentation
      "@opentelemetry/instrumentation-xml-http-request": {
        propagateTraceHeaderCorsUrls: [
          /.+\.yeti\.host/g, //Regex to match backend urls,
          // /localhost/g. // for dev
        ]
      }
    })
  ]
});
