<template>
  <div>
    <select name="" id=""
      class="mr-1 mb-3"
      v-model="fileType"
      @change="onChangeAmount"
      @click="optionColorChanged = false"
      :class="{optColor: optionColorChanged}"
      id="select">
      <option
        v-for="typeOption in fileTypeOptions"
        :value="typeOption">
        {{ typeOption }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectInputComponent",
  props: ['fileType'],
  data() {
    return {
      fileTypeOptions: ['Select Type (Video/Image)', 'Image', 'Video'],
      optionColorChanged: false,
    }
  },
  methods: {
    onChangeAmount (event) {
      this.fileType = event.target.value;
      this.$emit('clickChange', this.fileType);
    }
  }
}
</script>

<style lang="css" scoped>
select{
  padding: 18px 70px 18px 15px;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #BCBBBC;
  outline: none;
  border-radius: 7px;
  color:#BCBBBC;
}

select:focus{
  color: #202020;
}

.optColor {
  color:#202020
}
</style>
