<template>
  <div>
    <div v-if="message" class="input-group mt-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="select">
          {{message}}
        </label>
      </div>
    </div>
    <div class="input-group mt-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="select">Items per Page:</label>
      </div>
      <select v-model="perPage" @change="handlePageSizeChange($event)" class="ml-1" id="select">
        <option v-for="size in perPages" :key="size" :value="size">
          {{ size }}
        </option>
      </select>
    </div>
    <div class="input-group mt-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="select">
          Total items: {{totalItems}} |
          Page
          <input class="page-number-info" type="number" step="1" :max="maxPage" min="1" :value="page" @change="handlePageNumberChange($event)">
          of {{maxPage}}
        </label>
      </div>
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <a :class="page === 1 ? 'page-link a-disabled' : 'page-link'" href="#" tabindex="-1" @click.prevent="previousPage">
            Previous
          </a>
        </li>
        <li class="page-item">
          <a class="page-link current" href="#">
            {{ this.page }}
          </a>
        </li>
        <li class="page-item">
          <a :class="page === maxPage ? 'page-link a-disabled' : 'page-link'" href="#" @click.prevent="nextPage">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    totalItems: null,
    maxPage: null,
    predefinedPerPages: null,
    predefinedPerPage: null,
    message: null
  },
  data() {
    let perPagesValues = [10, 15, 20];
    if (this.predefinedPerPages) {
      perPagesValues = this.predefinedPerPages;
    }
    let perPageValue = 10;
    if (this.predefinedPerPage) {
      perPageValue = this.predefinedPerPage;
    }

    return {
      page: 1,
      perPage: perPageValue,
      perPages: perPagesValues,
    };
  },
  methods: {
    nextPage() {
      if (this.page === this.maxPage) {
        return;
      }
      this.page += 1;
      this.$emit('handlePageChange', this.page, this.perPage);
    },
    previousPage() {
      if (this.page === 1) {
        return;
      }

      this.page -= 1;
      this.$emit('handlePageChange', this.page, this.perPage);
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;

      this.$emit('handlePageChange', this.page, this.perPage)
    },
    handlePageNumberChange(event) {
      let newPageValue = event.target.value;

      if (newPageValue < 1 || newPageValue > this.maxPage) {
        return;
      }

      this.page = event.target.value;

      this.$emit('handlePageChange', this.page, this.perPage);
    }
  }
}
</script>

<style scoped>
.a-disabled{
  pointer-events: none;
  cursor: default;
  background: #e9ecef;
  color: #4a5371;
}

.page-number-info {
  margin: 0 5px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #dee2e6;
  width: 20%;
}

.page-number-info::-webkit-outer-spin-button,
.page-number-info::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>