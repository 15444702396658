<template v-if="widgetType === 'Instagram'">
  <div class="inner-colour-container">
    <form>
      <!-- step 1 -->
        <div v-if="step === 1">
          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="primaryColorPicker" class="pl-3" v-on:input="onInputColor">
                Colour
              <input type="color" name="primary" id="primaryColorPicker" value="widget.colour" v-on:change="onChangeColorPicker($event)" v-model="this.color" v-bind:class="{pickedColor: attachedColor}" ref="color" />
              </label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-9">
              <input type="text" name="" placeholder="Headline" v-model="widget.headline">
              <p v-if="!headlineIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-9">
              <input type="text" name="" placeholder="Sub Headline" v-model="widget.sub_headline">
              <p v-if="!sub_headlineIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-4">
              <input type="text" name="" placeholder="Button Text" v-model="widget.button_text">
              <p v-if="!button_textIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="imagePicker" class="pl-3">
              Logo
              <input type="file" value="" name="logo" id="imagePicker" style="display:none" v-on:change="onHandleImage" ref="image" />
              </label>
            </div>

            <div class="form-group col-md-10">
              <button class="btn next" v-on:click.prevent="next()">Next</button>
            </div>
          </div>
        </div>
        <!-- step 2 -->
        <div v-if="step === 2">
          <div class="form-row">
            <div class="form-group col-4">
              <input type="text" name="" placeholder="Title" v-model="widget.title" class="title">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-9">
              <input type="text" name="" placeholder="Instagram page URL (example https://www.instagram.com/tableYeti_com/)" v-on:change="qrCodeValueShow($event)" v-model="widget.details[0].details.instagram_url" ref="preview">
            </div>
            <button class="preview btn ml-3" @click.prevent="loadIframe($event)">Preview</button>
          </div>
          <div class="form-row">
            <div class="form-group col-9">
              <input type="text" name="" placeholder="QR Code URL (example https://www.instagram.com/tableYeti_com/)" v-on:change="qrCodeValueShow($event)" v-model="widget.details[0].details.instagram_qr" ref="qrUrl">
            </div>
          </div>
            <button type="submit" v-on:click.prevent="onUpdateWidget($event)" class="save btn ml-3">Update</button>
            <button v-on:click.prevent="prev()" class="back btn">Back</button>
        </div>
        <!-- On success message -->
        <p v-if="onWidgetsSuccess" class="alert-success text-left">{{ onWidgetsSuccess }}</p>
    </form>

    <!-- Iphone Holder Live Preview -->
    <div class="iphone-holder float-right">
      <div v-if="step === 1">
        <div class="inner">
          <div class="header text-center pt-2">
            <img src="../../../assets/icon-back-arrow-iphone.png" alt="" class="back float-left arrow">
            <h5 class="mb-0"> Thanks for paying with</h5>
            <img src="../../../assets/icon-logo.png" alt="" class="table-logo">
          </div>
          <hr class="my-2">
          <p class="pl-2">Here are some exclusive offers as a thank you for dining with us.</p>

          <div v-show="cardInIphoneRealPreview">
            <div class="widgets-instagram p-2 mb-3" :style="{backgroundColor: widget.colour}">
              <p class="d-inline-block">{{ widget.headline }}</p>
              <img :src="widget.logo" alt="" class="insta-image float-right">
              <p>{{ widget.sub_headline }}</p>
              <button disabled type="button" name="button" class="p-1 button-widget" :style="{color: widget.colour}">{{ widget.button_text }}</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 2">
        <div class="inner">
          <div class="" v-show="cardInIphoneRealPreview">
            <div class="prev-screen-2">
              <h6> <img src="../../../assets/icon-back-arrow-iphone.png" alt="" class="back float-left arrow">{{ widget.title }}</h6>
            </div>
            <qrcode-vue :value="widget.details[0].details.instagram_qr" :size="size" :foreground="foreground" :background="widget.colour" level="H" class="qr-parent"></qrcode-vue>
            <div id="info-message" class="ml-1 mb-0"></div>
            <div v-if="fallback" class="fallback-message">{{ this.fallbackMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import widgets from '../../../store/modules/widgets'
import { mapMutations } from 'vuex';

export default {
  name: "EditInstagramComponent",
  components: {
    QrcodeVue
  },
  props: {
    editWidgetMode: {
      type: Boolean
    },
  },
  data () {
    return {
      step: 1,
      color: '',
      cardInIphoneRealPreview: true,
      fallback: false,
      fallbackMessage: '',
      iframeLoaded: false,
      size: 150,
      foreground: 'white',
      attachedColor: false,
      isActive: true,
    }
  },
  computed: {
    widgetType() {
      return widgets.state.widgetType
    },
    onWidgetsSuccess() {
      return widgets.state.onWidgetsSuccess
    },
    widget() {
      return widgets.state.selectedWidget
    },
    headlineIsValid() {
      return !!this.widget.headline
    },
    sub_headlineIsValid() {
      return !!this.widget.sub_headline
    },
    button_textIsValid() {
      return !!this.widget.button_text
    },
    firstStepFormIsValid() {
      return this.headlineIsValid && this.sub_headlineIsValid && this.button_textIsValid;
    },
  },
  methods: {
    ...mapMutations([
      'EMPTY_SELECTED_WIDGET',
    ]),
    // For Safari issue with visibility of input type color
    onInputColor() {
      this.attachedColor = false;
    },
    onChangeColorPicker(e){
      this.color = this.$refs.color.value;
      this.widget.colour = this.color
      this.attachedColor = false;
    },
    onHandleImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0]
      this.createImage(files[0]);
      this.file = file;
      this.fileName = e.target.files[0].name;
    },
    createImage(file) {
      this.widget.logo = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        this.widget.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    loadIframe(e) {
      this.fallback = false;
      this.iframeLoaded = false;
      let iframeParent = document.querySelector('.inner');
      let infoMessage = document.getElementById('info-message');
      let html = '';

      if(!this.iframeLoaded) {
        let iframe = document.getElementById('iframe');
        if(iframe !== null && iframe !== undefined) {
          let oldIframe = document.getElementById('iframe');
          let oldInfoMessage = document.getElementById('info-message');
          oldIframe.parentNode.removeChild(oldIframe);
          oldInfoMessage.innerText = '';

          let iframe = document.createElement('iframe');
          iframe.id = 'iframe';
          iframe.src = this.widget.details[0].details.instagram_url;
          iframe.frameBorder="0";
          iframe.height ='250px'
          iframe.style.position ='absolute'
          iframe.style.bottom = '-2px'
          iframe.style.marginBottom = '-5px'

          let iframeOnloadEvent =  () => {
            this.iframeLoaded = true;

            if (iframe.contentWindow.length > 0) {
              this.fallback = false;
              this.fallbackMessage = ''
            } else {
              infoMessage.innerHTML = 'This website could not be loaded, but the preview will be visible in the Table Yeti iOS application.';
              this.fallbackMessage = infoMessage.innerText
            }
          }

          if (iframe.attachEvent){
            iframe.attachEvent('onclick', iframeOnloadEvent);
          } else {
            iframe.onload = iframeOnloadEvent;
          }
          iframeParent.appendChild(iframe);
          this.iframeLoaded = false;
        } else {
          let iframe = document.createElement('iframe');
          iframe.id = 'iframe';
          iframe.src = this.widget.details[0].details.instagram_url;
          iframe.frameBorder="0";
          iframe.height ='250px'
          iframe.style.position ='absolute'
          iframe.style.bottom = '-2px'
          iframe.style.marginBottom = '-5px'

          let iframeOnloadEvent =  () => {
            this.iframeLoaded = true;

            if (iframe.contentWindow.length > 0) {
              this.fallback = false
            } else {
              infoMessage.innerHTML = 'This website could not be loaded, but the preview will be visible in the Table Yeti iOS application.';
              this.fallbackMessage = infoMessage.innerText
            }
          }

          if (iframe.attachEvent){
            iframe.attachEvent('onclick', iframeOnloadEvent);
          } else {
            iframe.onload = iframeOnloadEvent;
          }
          iframeParent.appendChild(iframe);
          this.iframeLoaded = false;
        }
      }
        // iframe.onload event doesn't trigger in firefox if loading mixed content (http iframe in https parent) and it is blocked.
        setTimeout(() => {
          if (this.iframeLoaded === false) {
            infoMessage.innerHTML = '✘ iframe failed to load within 10s: ' + iframe.src;
          }
        }, 10000);
    },
    qrCodeValueShow(e){
      this.widget.details[0].details.instagram_url = this.$refs.preview.value;
      this.widget.details[0].details.instagram_qr = this.$refs.qrUrl.value;
    },
    prev() {
      if(this.iframeLoaded) {
        let frame = document.getElementById("iframe");
        frame.style.display = 'none'
      }
      setTimeout(() => {
        this.step--;
        this.attachedColor = false;
      }, 700);
    },
    next() {
      if (!this.firstStepFormIsValid) {
        this.isActive = false;
      } else {
        this.step++;
        if(this.iframeLoaded) {
          let frame = document.getElementById("iframe");
          frame.style.display = 'inline-block'
          setTimeout(() => {
            this.fallback = true;
          }, 500);
        }
      }
    },
    onUpdateWidget(e) {
      let formData = {
        type_id: 3,
        id: this.widget.id,
        colour: this.widget.colour,
        logo: this.widget.logo,
        button_text: this.widget.button_text,
        headline: this.widget.headline,
        sub_headline: this.widget.sub_headline,
        title: this.widget.title,
        instagram_url: this.widget.details[0].details.instagram_url,
        instagram_qr: this.widget.details[0].details.instagram_qr
      }
      if(formData !== null && formData !== undefined) {
        if(!this.widget.logo.includes(';base64,')) {
          formData.logo = '';
        }
        this.$emit('clickedUpdate', {formData: formData, editWidgetMode: false})
        this.fallback = false;
      }
    }
  }
}
</script>

<style scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -140px;
}

.inner {
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overflow-y: scroll;
}

.inner::-webkit-scrollbar {
    display: none;
}

img.table-logo {
  height: 1.9em;
}

img.back {
  height: 1.3em;
  margin-left: 10px;
}

img.insta-image {
  height: 2em;
  width: 2em;
  margin-left: 20px;
}

img.insta-image {
  border-radius: 50%;
}

h5 {
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #202020;
  margin: 5px 20px 0 0;
}

label,
input {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 60px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
}

input[type="color"] {
  width: 35%;
  position: relative;
  left: 50%;
  padding: 0px;
}

input[type="text"]:disabled,
input[type="number"]:disabled {
    background-color: #FEFFFF;
}

label.pl-3 {
  margin-bottom: 0px;
  font-size: 15px;
}

p.pl-2 {
  color: #292929;
  line-height: 20px;
  font-size: 15px;
}

.pickedColor {
  visibility: hidden;
}

form {
  display: inline-block;
  width: 69%;
  margin-top: 10px;
  margin-bottom: 15px;
}

button.next,
button.save,
button.back,
button.preview {
  padding: 13px 50px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-left: 25px;
}

button.back {
  background: white;
  border: 1px solid #E85F9A;
  color: #E85F9A;
  margin: 5px 0px;
  font-weight: bold;
}

button.next {
  margin-right: 50px;
  margin-top: 5px;
}

button.save {
  margin-right: 35px;
  margin-top: 5px;
  border: 2px solid #E85F9A;
}

button.preview {
  padding: 10px;
  height: 45px;
  margin-top: 10px;
}

.button-widget {
  font-weight: bold;
}

div.widgets-instagram {
  color: white;
  border-radius: 10px;
  width: 96%;
  margin: auto;
}

div.widgets-instagram button {
  color: white;
  border-radius: 20px;
  width: 100%;
  border: none;
  background: white;
  margin-bottom: 5px;
}

div.widgets-instagram p {
  width: 80%;
  line-height: 19px;
  word-break: break-word;
  display: inline-block;
}

div.widgets-instagram p:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

input:focus,
input.visited {
  color: #202020;
}

div.header img {
  margin-right: 0px;
}

div.prev-screen-2 {
  background: #FEFFFF;
  height: 50px;
  padding: 15px 40px 5px 0px;
  border-bottom: 1px solid #DFE0E0;
}

.prev-screen-2 h6 {
  font-size: 18px;
  font-weight: bold;
  color: #202020;
  text-align: center;
}

#info-message,
.fallback-message {
  position: absolute;
  top: 40%;
  left: 0%;
  height: 80px;
  background: #FFFFFF;
  color: #D25793;
  font-size: 14px;
  padding: 10px;
}

.alert-danger,
.alert-success {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px;
}

div.qr-parent {
  text-align: center;
  width: 65%;
  margin: auto;
  padding: 10px 0 5px;
  border-radius: 10px;
}
</style>


