<template lang="html">
  <div class="">
    <MarketingSubheader />
    <p>Collected Emails</p>
    <CollectedEmailsComponent />
  </div>
</template>

<script>
import MarketingSubheader from '../../components/MarketingComponents/MarketingSubheader';
import CollectedEmailsComponent from '../../components/MarketingComponents/CollectedEmailsComponent';
export default {
  name: "CollectedPage",
  components: {
    MarketingSubheader,
    CollectedEmailsComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
