<template>
  <div class="card-info">
      <p class="float-lef title text-danger">{{task.title}}</p>
      <p class="float-righ date text-info">{{task.date}}</p>
      <p class="clearfix text-success">{{task.email}}</p>
      <badge v-if="task.type" class="text-primary">{{task.type}}</badge>
  </div>
</template>
<script>
import Badge from "./Badge";
export default {
  components: {
    Badge
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: "purple",
        "Feature Request": "teal",
        Backend: "blue",
        QA: "green",
        default: "teal"
      };
      return mappings[this.task.type] || mappings.default;
    }
  }
};
</script>


<style scoped>
  /* .title{
    width: 65%;
  }

  .date{
    width: 35%;
    text-align: right;
  } */

  .card-info p{
    margin-bottom: 5px;
  }
</style>
