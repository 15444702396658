<template lang="html">
  <div class="">
    <FeedbackSubheader />
    <p>Menu</p>
    <MenuComponent />
  </div>
</template>

<script>
import FeedbackSubheader from '../../components/FeedbackComponents/FeedbackSubheader';
import MenuComponent from '../../components/FeedbackComponents/MenuComponent';
export default {
  name: "MenuPage",
  components: {
    FeedbackSubheader,
    MenuComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
