<template lang="html">
  <div class="inner-colour-container">
    <p class="float-left">You can use the option below to setup banners during your guests payment pro...</p>
    <br />
    <br />
    <form class="" action="" method="">
        <div v-if="step === 1">
          <input v-if="widgetBanner.writeModel" type="text" name="primary" value="" v-model="widgetBanner.bannerText"  placeholder="Banner Text"/>
          <input v-if="widgetBanner.regularModel" type="text" name="primary" value="" placeholder="Banner Text" v-on:click="switchInput"/>
          <label for="secondaryColorPicker" class="pl-1 label">
            Colour
            <input type="color" value="#ffffff" v-on:input="secondaryInputColorPicker" id="secondaryColorPicker" ref="bannerColor" />
          </label>
          <button type="submit" name="button" class="btn ml-4 save" v-on:click.prevent="next()">Next</button>
        </div>

        <div v-else-if="step === 2">
          <input type="text" name="" value="" placeholder="Heading" v-model="widgetBanner.bannerHeading">
          <input type="text" name="" value="" placeholder="Sub Heading" v-model="widgetBanner.bannerSubHeading">
          <label for="selectImagePicker" class="pl-1 label">
          Image
          <input type="file" value="" id="selectImagePicker" style="display:none" v-on:change="onFileChange"/>
          </label>
          <input type="text" name="" value="" placeholder="Main Text Area" v-model="widgetBanner.bannerMainText">
          <input type="text" name="" value="" placeholder="Amount" v-model="widgetBanner.bannerAmount">
          <input type="text" name="" value="" placeholder="Button Text" v-model="widgetBanner.bannerButton">
          <button type="submit" name="button" class="btn back" v-on:click.prevent="prev()">Back</button>
          <button v-if="widgetBanner.ButtonChangeToUpdate" type="submit" class="save btn ml-3" v-on:click.prevent="onUpdate(widgetBanner)">Update</button>
          <button v-else type="submit" name="button" class="btn save ml-4" v-on:click.prevent="submit()">Save</button>
        </div>
  </form>
    <div class="iphone-holder float-right">
      <template v-if="step === 1">
        <div class="inner">
          <div class="content text-center">
            <h6 class="mb-0 pt-3">Your Bill</h6>
            <p class=" mb-0 pb-1 secondary table-text">Table 51</p>
            <img alt="ham logo" src="../../assets/hamburger_menu_icon.png" class="mr-2 ham-img">
          </div>
          <div class="container-fluid ">
            <div class="row">
              <div class="col-md-12 parent-holder p-0">
                <div v-show="widgetBanner.bannerCardInIphone" class="banner-holder">
                  <p class="my-2 py-2 px-3 banner-text"  v-for="(card,index) in widgetBanner.bannerCardsArr" :key="card.x" :style="{backgroundColor: card.color}">{{card.textInfo}}</p>
                </div>
                <div class="" v-show="widgetBanner.bannerCardInIphoneRealPreview" class="banner-holde">
                  <p class="my-2 py-2 px-3 banner-text"   :style="{backgroundColor: this.widgetBanner.bannerColor}">{{widgetBanner.bannerText}}</p>
                </div>
                <ul class="">
                  <li class="first">Tap <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   width="84.000000pt" height="84.000000pt" viewBox="0 0 84.000000 84.000000"
                   preserveAspectRatio="xMidYMid meet" class="mr-2">
                  <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
                  fill="#E79D49" stroke="none" class="primary-svg">
                  <path d="M494 816 c-9 -39 3 -78 24 -74 13 3 17 14 17 48 0 34 -4 45 -17 48
                  -12 2 -19 -5 -24 -22z"/>
                  <path d="M340 752 c0 -25 45 -66 64 -59 23 9 20 30 -7 55 -28 27 -57 29 -57 4z"/>
                  <path d="M641 746 c-23 -24 -23 -56 -1 -56 17 0 62 49 57 63 -7 21 -32 18 -56
                  -7z"/>
                  <path d="M431 623 c-22 -74 -66 -141 -104 -162 -22 -12 -28 -11 -54 8 -26 19
                  -40 21 -123 19 l-95 -3 0 -240 0 -240 95 -3 c81 -2 98 0 121 17 26 20 27 20
                  89 0 48 -15 91 -19 196 -19 130 0 136 1 159 25 14 13 25 35 25 49 0 14 11 36
                  25 50 26 26 33 64 15 82 -8 8 -7 19 1 37 9 20 9 33 1 51 -8 18 -8 33 1 55 10
                  26 8 34 -9 58 -20 26 -27 28 -110 33 -73 4 -88 8 -85 20 2 8 6 50 9 92 4 75 4
                  78 -26 108 -24 23 -39 30 -70 30 l-40 0 -21 -67z m97 5 c15 -15 16 -100 1
                  -150 -9 -35 -13 -38 -45 -38 -31 0 -35 -3 -32 -22 3 -22 7 -23 138 -26 74 -1
                  139 -7 144 -12 16 -16 -13 -39 -52 -42 -25 -2 -37 -8 -37 -18 0 -10 12 -16 37
                  -18 65 -6 78 -52 14 -52 -35 0 -62 -21 -51 -40 3 -5 21 -10 38 -10 41 0 62
                  -14 54 -34 -4 -10 -18 -16 -40 -16 -55 0 -76 -33 -31 -50 16 -7 24 -16 22 -28
                  -3 -15 -19 -17 -132 -20 -110 -2 -139 1 -193 19 l-63 21 0 154 c0 120 3 154
                  13 154 40 0 136 109 157 179 7 20 13 42 15 49 5 15 27 16 43 0z m-283 -383 l0
                  -190 -72 -3 -73 -3 0 196 0 196 73 -3 72 -3 0 -190z"/>
                  <path d="M154 135 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36 0z"/>
                  </g>
                </svg> below to leave feedback  <img alt="info logo" src="../../assets/info-icon.png" class="mr-1 ml-1 info-img"> <span class="float-right secondary amount pt-1">Amount</span> </li>
                  <li v-for="item in items" class="my-1 py-1 px-2">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="84.000000pt" height="84.000000pt" viewBox="0 0 84.000000 84.000000"
                     preserveAspectRatio="xMidYMid meet" class="mr-2">
                    <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
                    fill="#E79D49" stroke="none" class="primary-svg">
                    <path d="M494 816 c-9 -39 3 -78 24 -74 13 3 17 14 17 48 0 34 -4 45 -17 48
                    -12 2 -19 -5 -24 -22z"/>
                    <path d="M340 752 c0 -25 45 -66 64 -59 23 9 20 30 -7 55 -28 27 -57 29 -57 4z"/>
                    <path d="M641 746 c-23 -24 -23 -56 -1 -56 17 0 62 49 57 63 -7 21 -32 18 -56
                    -7z"/>
                    <path d="M431 623 c-22 -74 -66 -141 -104 -162 -22 -12 -28 -11 -54 8 -26 19
                    -40 21 -123 19 l-95 -3 0 -240 0 -240 95 -3 c81 -2 98 0 121 17 26 20 27 20
                    89 0 48 -15 91 -19 196 -19 130 0 136 1 159 25 14 13 25 35 25 49 0 14 11 36
                    25 50 26 26 33 64 15 82 -8 8 -7 19 1 37 9 20 9 33 1 51 -8 18 -8 33 1 55 10
                    26 8 34 -9 58 -20 26 -27 28 -110 33 -73 4 -88 8 -85 20 2 8 6 50 9 92 4 75 4
                    78 -26 108 -24 23 -39 30 -70 30 l-40 0 -21 -67z m97 5 c15 -15 16 -100 1
                    -150 -9 -35 -13 -38 -45 -38 -31 0 -35 -3 -32 -22 3 -22 7 -23 138 -26 74 -1
                    139 -7 144 -12 16 -16 -13 -39 -52 -42 -25 -2 -37 -8 -37 -18 0 -10 12 -16 37
                    -18 65 -6 78 -52 14 -52 -35 0 -62 -21 -51 -40 3 -5 21 -10 38 -10 41 0 62
                    -14 54 -34 -4 -10 -18 -16 -40 -16 -55 0 -76 -33 -31 -50 16 -7 24 -16 22 -28
                    -3 -15 -19 -17 -132 -20 -110 -2 -139 1 -193 19 l-63 21 0 154 c0 120 3 154
                    13 154 40 0 136 109 157 179 7 20 13 42 15 49 5 15 27 16 43 0z m-283 -383 l0
                    -190 -72 -3 -73 -3 0 196 0 196 73 -3 72 -3 0 -190z"/>
                    <path d="M154 135 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36 0z"/>
                    </g>
                    </svg>
                   {{item.name}} <span class="float-right pt-2">&#163; {{item.price}}</span> </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="bottom">
            <p class="px-2 pt-1">Service Charge &#163; 48.75<span class="float-right total">Total &#163; 438.75</span> </p>
            <button type="button" name="button" class="mb-2 primary" disabled>Pay whole bill <img alt="people logo" src="../../assets/icon-button-single.png" class="ml-2"> </button>
            <button type="button" name="button" disabled class="primary">Split bill <img alt="people logo" src="../../assets/icon-button-multiple.png"></button>
          </div>
        </div>
      </template>

      <template v-else-if="step === 2">
        <div class="inner">
          <div class="content text-center">
            <h6 class="mb-0 pt-3">{{widgetBanner.bannerHeading}}</h6>
            <p class=" mb-0 pb-1 secondary mt-2">{{widgetBanner.bannerSubHeading}}</p>
          </div>
          <div class="container-fluid ">
            <div class="row">
              <div class="col-md-12 p-0 my-3">
                <img :src="image" alt="" class="insta-image img-fluid mr-2 mt-1">
              </div>
              <div class="col-md-12 text-center">
                <p>{{widgetBanner.bannerMainText}}</p>
              </div>
            </div>
          </div>
          <div class="bottom wht">
            <button type="button" name="button" class="mb-2 primary" disabled>{{widgetBanner.bannerButton}}&nbsp;({{ widgetBanner.bannerAmount }}) </button>
            <button type="button" name="button" disabled class="primary wht">No Thanks</button>
          </div>
        </div>
      </template>

    </div>

    <div v-if="widgetBanner.leftSideCardInfo">
      <draggable :list="this.widgetBanner.bannerCardsArr" :forceFallback="true">
        <div class="result align-items-center py-2" v-for="(card,index) in widgetBanner.bannerCardsArr" :key="card.x" :style="{backgroundColor: card.color}" v-if="widgetBanner.leftSideCardInfo">
          <div class="float-left ml-3 ">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <p class="ml-3 mt-3 d-inline-block">{{card.textInfo}}</p>
          <div class="float-right ios-correct">
            <button type="button" name="button" class="btn view" data-toggle="modal" data-target="#myModal">View Results</button>
            <button type="button" name="button" class="mx-2 btn edit" v-on:click="openLeftSideCardBackToStepOne(card)">Edit</button>
          </div>
          <div class="acti-deacti">
            <input type="radio" :id="index" :name="'option-selected'" :value="card.activate" @change="handleChange($event,card.x)" style="display:none;">
            <label :for="index" style="border: none; cursor:pointer;" v-bind:class='card.activate ? "text-danger" : "text-success"'>{{card.activateText}}</label>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "BannersComponent",
  components: {
    draggable
  },
  data(){
    return{
      step:1,
      image: "",
      widgetBanner: {
      bannerText: "",
      bannerColor: "",
      bannerHeading: "",
      bannerSubHeading: "",
      bannerMainText: "",
      bannerAmount: "",
      bannerButton: "",
      newBannerCardsObj: {},
      bannerCardsArr: [],
      bannerInputs:false,
      bannerCardInIphone: false,
      bannerCardInIphoneRealPreview: true,
      leftSideCardInfo: false,
      ButtonChangeToUpdate: false,
      activate: false,
      activateText: "Activate",
      x: 0,
      regularModel: false,
      writeModel: true
    },
      items: [
        {name: "*2 SET MENU", price: "134.00"},
        {name: "*2 SET MENU", price: "134.00"},
        {name: "*BOOZLESS SET", price: "60.00"},
        {name: "*BOOZLESS SET", price: "60.00"},
        {name: "*2 St Mary's garden Dona.. ", price: "1.00"},
        {name: "*2 St Mary's garden Dona..  ", price: "1.00"},
      ],
    }
  },
    methods: {
      secondaryInputColorPicker(){
        this.widgetBanner.bannerColor = this.$refs.bannerColor.value;
      },
      prev() {
        this.step--;
        this.widgetBanner.leftSideCardInfo = true;

      },
      next(){
          this.step++;
          this.widgetBanner.leftSideCardInfo = false;
      },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.createImage(files[0]);
      },
      createImage(file) {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      submit() {
        this.widgetBanner.leftSideCardInfo = true;
        this.widgetBanner.bannerCardInIphone = true;
        this.widgetBanner.bannerCardInIphoneRealPreview = false;
        this.widgetBanner.bannerInputs = false;

        let bannerCardsArrCopy = [...this.widgetBanner.bannerCardsArr];
        this.widgetBanner.newBannerCardsObj = {
          title: this.widgetBanner.bannerHeading,
          subtitle: this.widgetBanner.bannerSubHeading,
          image: this.image,
          textInfo: this.widgetBanner.bannerText,
          amount: this.widgetBanner.bannerAmount,
          button: this.widgetBanner.bannerButton,
          color: this.widgetBanner.bannerColor,
          mainText: this.widgetBanner.bannerMainText,
          x: this.widgetBanner.x++,
          activate: this.widgetBanner.activate,
          activateText: this.widgetBanner.activateText,
        }

        bannerCardsArrCopy.unshift(this.widgetBanner.newBannerCardsObj);
        this.widgetBanner.bannerCardsArr = bannerCardsArrCopy;
        this.step = 1;

        this.widgetBanner.regularModel = true;
        this.widgetBanner.writeModel = false;
        this.widgetBanner.bannerHeading = "";
        this.widgetBanner.bannerSubHeading = "";
        this.image = "";
        this.widgetBanner.bannerAmount = "";
        this.widgetBanner.bannerButton = "";
        this.widgetBanner.bannerColor = "";
        this.widgetBanner.bannerMainText = "";

      },
      openLeftSideCardBackToStepOne(index){
        this.widgetBanner.bannerInputs = false;
        this.widgetBanner.bannerCardInIphoneRealPreview = true;
        this.widgetBanner.bannerCardInIphone = false;
        this.widgetBanner.leftSideCardInfo = false;
        this.widgetBanner.ButtonChangeToUpdate = true;
        this.widgetBanner.regularModel = false;
        this.widgetBanner.writeModel = true;
        this.$refs.bannerColor.value = index.color

        this.widgetBanner.bannerHeading = index.title;
        this.widgetBanner.bannerSubHeading = index.subtitle;
        this.image = index.image;
        this.widgetBanner.bannerText = index.textInfo;
        this.widgetBanner.bannerAmount = index.amount;
        this.widgetBanner.bannerButton = index.button;
        this.widgetBanner.bannerColor = index.color;
        this.widgetBanner.bannerMainText = index.mainText;
        this.widgetBanner.x = index.x;
        this.widgetBanner.activate = index.activate;
        this.widgetBanner.activateText = index.activateText;
      },
      onUpdate(obj) {
        this.widgetBanner.leftSideCardInfo = true;
        this.widgetBanner.bannerCardInIphone = true;
        this.widgetBanner.bannerCardInIphoneRealPreview = false;
        this.widgetBanner.bannerInputs = false;
        this.widgetBanner.regularModel = true;
        this.widgetBanner.writeModel = false;

        let updatedItem = this.widgetBanner.bannerCardsArr.find((element) => { return element.x === obj.x })
        let foundIndex = this.widgetBanner.bannerCardsArr.findIndex(element => element.x === obj.x)
        updatedItem = {
          title: obj.bannerHeading,
          subtitle: obj.bannerSubHeading,
          image: obj.image,
          textInfo: obj.bannerText,
          amount: obj.bannerAmount,
          button: obj.bannerButton,
          color: obj.bannerColor,
          mainText: obj.bannerMainText,
          activate: obj.activate,
          activateText: obj.activateText,

        }
        this.widgetBanner.bannerCardsArr[foundIndex] = updatedItem;
        this.widgetBanner.ButtonChangeToUpdate = false;
        this.step = 1;
        this.widgetBanner.bannerHeading = "";
        this.widgetBanner.bannerSubHeading = "";
        this.image = "";
        this.widgetBanner.bannerAmount = "";
        this.widgetBanner.bannerButton = "";
        this.widgetBanner.bannerColor = "";
        this.widgetBanner.bannerMainText = "";
      },
      handleChange(e,x){
      this.widgetBanner.bannerCardsArr.filter(o=>o.x===x).map(o=>o.activate=true);
      this.widgetBanner.bannerCardsArr.filter(o=>o.x===x).map(o=>o.activateText="Deactivate");
      this.widgetBanner.bannerCardsArr.filter(o=>o.x!==x).map(o=>o.activate=false);
      this.widgetBanner.bannerCardsArr.filter(o=>o.x!==x).map(o=>o.activateText="Activate");
      },
      switchInput(){
        this.widgetBanner.regularModel = false;
        this.widgetBanner.writeModel = true;
        this.widgetBanner.bannerCardInIphoneRealPreview = true;
        this.widgetBanner.bannerCardInIphone = false;
        this.widgetBanner.bannerText = "";
      }
    },
}
</script>

<style lang="css" scoped>
.iphone-holder{
  background-image: url("../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -110px;
}

.inner{
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.parent-holder{
  background: #E8E8E8;
  padding: 0 6px!important;
}

.content p.table-text{
  color: #463C8B;
  font-weight: bold;
  font-size: 14px;
}

div.result{
  height: auto;
  width: 60%;
  padding-right: 15px;
  color: white;
  border-radius: 6px;
  margin-top: 18px;
  cursor: move;
  position: relative;
}

div.acti-deacti{
  position: absolute;
  right: -82px;
  bottom: 15px;
}

div.result p{
  width: 59%;
  font-weight: bold;
}

div.result span{
  background: white;
  padding: 4px;
  border-radius: 5px;
  display: block;
  margin-top: 6px;
}

ul li.first{
  background: #F0F0F0;
  padding: 8px!important;
  margin-top: 6px;
  font-size: 11px;
  color: #B3B3B3;
}

p.banner-text{
  background: #E85F9A;
  color: white;
  text-align: center;
  width: 103.7%;
  margin-left: -5.5px;
}

.content{
  position: relative;
}

.content h6,
.total{
  color: #1F1F1F;
  font-weight: bold;
}

.content img.ham-img{
  height: 13px;
  position: absolute;
  top: 19px;
  right: 3px;
}

button.next,
button.save,
button.back{
  padding: 13px 60px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  outline: none;
  margin-left: 25px;
}

button.back,
button.view,
button.edit{
  background: white;
  border: 1px solid #E85F9A;
  color: #E85F9A;
  margin: 5px 0px;
  font-weight: bold;
}

button.edit{
  padding: 6px 40px;
  margin: 10px 0;
}
.pickedColor {
  visibility: hidden;
}

button.next{
  margin-right: 50px;
  margin-top: 5px;
}

button.save{
  margin-right: 35px;
  margin-top: 5px;
  border: 2px solid #E85F9A;
}

ul{
  list-style: none;
  padding: 0;
  background: #E8E8E8;
}

ul li{
  display: block;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
}

ul li span.amount{
  color: #463C8B;
  font-weight: bold;
  font-size: 15px;
}

ul li svg{
  height: 2em;
  width: 31px;
  vertical-align: bottom;
}

ul li img.first{
  vertical-align: sub;
  height: 2em;
}

ul li img{
  height: 1.3em;
}

.bottom p{
  font-size: 12px;
  color: #C9C9C9;
  font-weight: bold;
  margin-bottom: 8px;
}

.bottom p span{
  font-size: 14px;
}

.bottom button{
  background: #E79D49;
  border-radius: 20px;
  border: none;
  width: 90%;
  margin-left: 5%;
  padding: 7px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
}

.bottom button img{
  height: 1.8em;
}

.bottom button.wht{
  background: transparent;
  border: 1px solid orange;
  color: black;
}

.banner-holder{
    overflow: hidden;
    overflow-y: scroll;
    height: 55px;
}

.banner-holder::-webkit-scrollbar{
    display: none;
}

div.wht{
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
}

.insta-image{
  max-height: 230px;
  width: 100%;
}

label.label,
input{
  border: 1px solid #BCBBBC;
  width: 210px;
  height: 70px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
  margin-bottom: 10px;
}

input{
  display: block;
  color: #202020;
}


input::placeholder{
  color: #BCBBBC;
}


input[type="color"]{
    width: 35%;
    position: relative;
    left: 43%;
    top: 7%;
    padding: 0px;
}

form{
  display: inline-block;
}

</style>
