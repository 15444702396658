<template>
  <transition name="modal">
    <div class="modal-backdrop">
      <div class="modal-wrapper">
        <div class="modal-container">
          <!-- Modal body section start -->
          <section class="modal-body">
            <slot name="body">
            </slot>
            <div v-if="successMessage" class="alert alert-success">
              {{successMessage}}
            </div>
            <div v-if="errorMessage" class="alert alert-danger">
              {{errorMessage}}
            </div>
            <div v-if="loadModel === 'add'">
              <p class="px-3 add-email-text">Add Team Member</p>
              <hr>
              <form
                  @submit="submitForm"
                  method="post"
                  novalidate="true"
                  class="form-style"
              >
                <div class="col-md-12">
                  <input :disabled="validating" type="text" v-model="name" name="name" placeholder="Name" id="name" class="form-control mb-1">
                  <input :disabled="validating" type="text" v-model="pin" name="pin" placeholder="Pin" id="pin" class="form-control mb-1">
                  <input :disabled="validating" type="text" v-model="payrollNumber" name="payroll_number" placeholder="Payroll Number" id="payroll-number" class="form-control mb-1">
                  <div class="form-control" style="border: 0px">
                  <input :disabled="validating" type="checkbox" v-model="isActive" name="is_active" checked id="is-active" class="checkbox-input">
                  <label for="is-active">Is Active?</label>
                  </div>
                </div>
                <input
                    class="submit-btn btn btn-sm ml-3"
                    type="submit"
                    value="Submit"
                >
              </form>
            </div>
            <div v-else-if="loadModel === 'edit'">
              <p class="px-3 add-email-text">Add Team Member</p>
              <hr>
              <form
                  @submit="submitForm"
                  method="post"
                  novalidate="true"
                  class="form-style"
              >
                <div class="col-md-12">
                  <input :disabled="validating" type="text" v-model="name" name="name" id="name" class="form-control mb-1">
                  <input :disabled="validating" type="text" v-model="pin" name="pin" id="pin" class="form-control mb-1">
                  <input :disabled="validating" type="text" v-model="payrollNumber" name="payroll_number" id="payroll-number" class="form-control mb-1">
                  <div class="form-control" style="border: 0px">
                    <input :disabled="validating" type="checkbox" v-model="isActive" name="is_active" :checked="isActive" id="is-active" class="checkbox-input">
                    <label for="is-active">Is Active?</label>
                  </div>
                </div>
                <input
                    class="submit-btn btn btn-sm ml-3"
                    type="submit"
                    value="Submit"
                >
              </form>
            </div>
          </section>
          <!-- Modal body section end -->

          <!-- Modal footer section start -->
          <footer class="modal-footer mt-3">
            <slot name="footer">
              <button
                type="button"
                class="modal-close-button"
                @click="onClose"
                aria-label="Close modal">
                Close
              </button>
            </slot>
          </footer>
          <!-- Modal body section end -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import axios from "axios";

  export default {
    name: 'AddStaffMemberModalComponent',
    props: {
      loadModel: '',
      staffId: null,
      staffMemberName: null,
      staffMemberToken: null,
      staffMemberPin: null,
      staffMemberPayrollNumber: null,
      staffMemberIsActive: false,
    },
    data() {
      return {
        name: '',
        token: '',
        pin: '',
        payrollNumber: '',
        isActive: true,
        teamMemberId: null,
        successMessage: null,
        errorMessage: null,
        validating: false,
      }
    },
    mounted() {
      if (this.staffId) {
        this.name = this.staffMemberName;
        this.token = this.staffMemberToken;
        this.pin = this.staffMemberPin;
        this.payrollNumber = this.staffMemberPayrollNumber;
        this.isActive = this.staffMemberIsActive;
      }
    },
    created () {
      this.emailInputs = [];
      return this.$store.commit('LOAD_ALL_NPS_EMAILS')
    },
    methods: {
      submitForm: function (e) {
        this.validating = true;
        this.errorMessage = '';
        e.preventDefault();
        if (!this.checkForm()) {
          this.validating = false;
          this.errorMessage = 'All fields are required. The pin must contain exactly 4 digits.'

          setTimeout(() => {
            this.errorMessage = null;
          }, 3500);
          return;
        }

        const config = {
          headers: {'Content-Type': 'aplication/json'}
        }

        let url = 'backoffice/staff/';
        url += this.token
            ? this.token + '/update'
            : 'store';

        axios.post(url, {
          name: this.name,
          pin: this.pin,
          payroll_number: this.payrollNumber,
          is_active: this.isActive
        }, config)
            .then(res => {
              if (res) {
                this.successMessage = res.data.message
                setTimeout(() => {
                  this.validating = false;
                  this.$emit('close');
                }, 2500);
              }
            })
            .catch(error => {
              if (error.response) {
                this.validating = false;
                this.errorMessage = error.response.data.message
                setTimeout(() => {
                  this.errorMessage = null;
                }, 3500);
              }
            })
      },
      checkForm() {

        if (this.pin !== undefined && this.pin.length === 3) {
          this.pin = '0' + this.pin;
        }

        let validations = [];

        if (this.name === undefined || this.name.length < 2) {
          validations.push('Name must have a value');
        }

        if (this.payrollNumber === undefined || this.payrollNumber.length < 1) {
          validations.push('Payroll number must have a value');
        }

        if (this.pin === undefined || this.pin.length !== 4) {
          validations.push('Pin must have a 4 digit value');
        }

        return !(validations.length > 0);
      },
      onClose() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="css" scoped>
.modal-backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  opacity: 1;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  max-height: 600px;
  margin: 0px auto;
  padding: 10px 30px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 2px 2px 20px 1px;
  overflow: auto;
}

.modal-body {
  padding: 20px 10px;
}

.modal-footer {
  padding: 10px;
  margin: 50px 0;
}

button {
  padding: 10px;
  background: #E85F9A;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
}

.add-another-email {
  padding: 7px 15px;
  margin-left: 10px;
  margin-bottom: 3px;
}

div.save-emails {
  width: 50%;
  padding: 0px;
  float: right;
}

div.save-emails > button {
  padding: 7px 17px;
}

.remove-email {
  background-color: #3C038C;
}

.edit-email,
.update-email {
  padding: 5px 15px;
  margin-right: 5px;
  background-color: #3C038C;
  color: #FFFFFF;
}

.delete-email,
.cancel-email {
  color: #E85F9A;
  background: #FFFFFF;
  padding: 10px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.modal-close-button {
  float: right;
  padding: 7px 20px;
}

input{
  border: 1px solid #BCBBBC;
  width: 65%;
  height: 45px;
  padding-left: 12px;
  outline: none;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  color: #202020;
  cursor: text;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.update-input {
  cursor: text;
  background-color:#e7e7e7;
  width: 75%;
  border-radius: 0px;
}

label.pl-3{
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0.3px;
}

input:focus,
input.visited{
  color: #202020;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h3 {
  background: #3C038C;
  color: #FFFFFF;
  text-align: center;
  padding: 20px;
  margin-bottom: 60px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #3C038C;
  border-radius: 2px;
  letter-spacing: 1px;
}

ul {
  min-width: 600px;
  min-height: 100px;
  margin: 20px auto;
  padding: 20px 30px 40px 30px;
  list-style-type: none;
  background-color: rgba(60,3,140,0.1);
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 10px rgba(146, 70, 70, 0.5);
  -moz-box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
}

li {
  padding: 20px;
  padding-bottom: 30px;
  overflow: auto;
  border: 1px solid rgba(60,3,140, 0.2);
  border-radius: 2px;
  background-color: rgb(250, 250, 250);
  -webkit-box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  letter-spacing: .1px;
  }

p {
  color: #3C038C;
  font-size: 14px;
}

ul.audio li {
  padding: 20px 10px 10px 40px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.add-email-text {
  font-size: 18px;
  font-weight: bold;
  color: #3C038C;
  letter-spacing: .2px;
}

ul.add-email {
  min-height: 50px;
  padding: 0px;
  margin: 0px;
  background-color: #FFFFFF;
  box-shadow: none;
}

ul.add-email li {
  padding: 5px;
  border: none;
  background-color: #FFFFFF;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

div.list h3 {
  margin-bottom: 0px;
}

ul.list-emails {
  min-height: 50px;
  padding: 10px 5px 0px;
  background-color: #FFFFFF;
  box-shadow: none;
}

ul.list-emails li {
  margin: 5px;
  padding: 10px;
  width: 75%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 7px rgba(0,0,0,0.2);
}

div.alert-info {
  height: 110px;
  padding-top: 45px;
  background-color: #FFFFFF;
}

.validation-errors-wrapper {
  min-height: 25px;
}

.messages-wrapper {
  min-height: 70px;
  margin-top: 20px;
  padding-top: 20px;
}

.alert-failed {
  background-color: white;
  color: #ff0000;
}

.alert-success {
  background-color: white;
  color:#7ac142;
}

.checkbox-input{
  width: 10%;
  height: auto;
  margin-left: -5.5%;
  cursor: pointer;
}

.submit-btn{
  background: #E85F9A;
  color: white;
  width: 12%;
  padding-left: 2%;
  font-size: 1.2em;
}
.form-style{
  width: 100%;
}
</style>
