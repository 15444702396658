<template lang="html">
<div>
  <div class="date-range-holder">
    <VueCtkDateTimePicker
      v-model="dateRange"
      color="#483D8B"
      range
      overlay
      hint="Selected:"
      label="Select date"
      >
    </VueCtkDateTimePicker>
  </div>
  <div v-if="!errored" id="bills" class="bills-table mt-4">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Transaction ID</th>
          <th>Email</th>
          <th>Marketing Permission</th>
          <th>Amount</th>
          <th>Service Charge</th>
          <th>Additional Service Charge</th>
        </tr>
      </thead>
      <tbody v-if="listLoad">
        <tr>Loading...</tr>
      </tbody>
      <tbody v-else-if="!listLoad && results.length > 0">
        <tr v-for="result in results" class="text-dark">
          <td>
            {{ result.id }}
          </td>
          <td>
            {{ result.customer_email }}
          </td>
          <td>
            {{ result.marketing_permission }}
          </td>
          <td>
            {{ result.amount }}
          </td>
          <td>
            {{ result.service_charge }}
          </td>
          <td>
            {{ result.additional_service_charge }}
          </td>
        </tr>
      </tbody>
      <tbody v-else >
      <tr>
        <td colspan="12" class="text-center alert-info empty-data">
          Nothing to show
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- Info if error -->
  <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
  </div>

  <Pagination
      @handlePageChange="handlePageChange"
      :maxPage="maxPage"
      :totalItems="totalItems"
  >
    <li slot="body"></li>
  </Pagination>
</div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "CollectedEmailsComponent",
  components: {
    VueCtkDateTimePicker,
    Pagination
  },
  data(){
    return {
      results: [],
      dateRange: null,
      listLoad: true,
      slicedTableData: [],
      allTableData: [],
      busy: false,
      limit: 30,
      keyword: '',
      showSlicedData: false,
      filterDate: "This year",
      yourValue: null,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      errored: false,
    }
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      let params = new URLSearchParams();

      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      axios.get(`backoffice/transactions/customer-emails`, {params: params})
          .then(response => {
            let data = response.data.data;
            this.maxPage = data.max_page;
            this.totalItems = data.total_items;
            this.results = data.items;
          })
          .catch(error => {
            this.errored = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.listLoad = false
            }, 300)
          });

      this.listLoad = false;
    },

    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },

    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  },

  computed: {
    filteredIds: function(){
      return this.allTableData.filter((item) => {
        return item.email.match(this.keyword);
      })
    }
  },

}
</script>

<style lang="css" scoped>

input{
  height: 44px;
  border-radius: 7px;
  font-size: 14px;
}

table thead tr,
.fa-search
 {
  color: #B4B4B4;
}

table thead tr th{
  padding: 12px 35px;
  border: none;
  text-align: center!important;
}


table tbody tr td{
  padding: 25px 15px!important;
}

table tbody tr:hover{
  cursor: pointer;
}

.filter{
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.filter::-webkit-scrollbar {
  display: none;
}


.date-range-holder{
  width: 40%;
  display: inline-block;
  vertical-align: middle;
}


button.download{
  background: #E55D98;
  font-weight: bold;
}

.text-dark{
  text-align: center;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

</style>
