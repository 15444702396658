<template lang="html">
  <div>
    <div class="date-range-holder">
      <VueCtkDateTimePicker
        v-model="inputValueLeft"
        color="#483D8B"
        range
        overlay
        hint="Selected:"
        label="Select Date"
        id="left"
      />
    </div>
    <div class="btnWrapper">
      <button class="btn report">Download Report</button>
    </div>
    <div id="devices" class="devices-table">
      <!-- <table v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit" class="table table-hover"> -->
      <table class="table">
        <thead>
          <tr class="text-body">
            <th>Version</th>
            <th>User Name </th>
            <th>Gateway</th>
            <th>Acquiring Bank</th>
            <th>American Express (Y/N)</th>
            <th>Device ID</th>
            <th>Device Name</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in mock_data" :key="data.device_id" class="text-dark">
            <td>{{data.version}}</td>
            <td>{{data.user_name}}</td>
            <td>{{data.gateway}}</td>
            <td>{{data.acquiring_bank}}</td>
            <td>{{data.american_express ? 'YES' : 'NO'}}</td>
            <td>{{data.device_id}}</td>
            <td>{{data.device_name}}</td>
            <td>{{data.status ? 'YES' : 'NO'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  export default {
    name: "DevicesComponent",
    components: {
      VueCtkDateTimePicker
    },
    data () {
      return {
        mock_data: [{"version":"1.0","user_name":"Edan Summit","gateway":"PayPal","acquiring_bank":"Barclays","american_express":false,"device_id":"676-04-5196","device_name":"iPhone 11 Pro","status":true},
          {"version":"1.0","user_name":"Bevon Dietrich","gateway":"2Checkout","acquiring_bank":"American Express","american_express":true,"device_id":"544-73-3926","device_name":"iPhone XS","status":false},
          {"version":"2.0","user_name":"Guilbert Bauch","gateway":"WePay","acquiring_bank":"NatWest","american_express":false,"device_id":"658-90-0617","device_name":"iPhone 11","status":true},
          {"version":"2.2","user_name":"Westbrook Skiles","gateway":"PayPal","acquiring_bank":"Barclays","american_express":false,"device_id":"681-32-5246","device_name":"iPhone XR","status":true},
          {"version":"1.0","user_name":"Gretchen Schulist","gateway":"PayPal","acquiring_bank":"American Express","american_express":true,"device_id":"834-06-5135","device_name":"iPhone 7 Plus","status":true},
          {"version":"2.1","user_name":"Gaye Howell","gateway":"WePay","acquiring_bank":"WorldPay","american_express":false,"device_id":"691-05-8207","device_name":"iPhone XR","status":true},
          {"version":"2.1","user_name":"Yovonnda Dietrich","gateway":"WePay","acquiring_bank":"WorldPay","american_express":false,"device_id":"582-89-8529","device_name":"iPhone 11","status":false},
          {"version":"1.0","user_name":"Terri Lockman","gateway":"PayPal","acquiring_bank":"Barclays","american_express":false,"device_id":"295-89-1205","device_name":"iPhone 8 Plus","status":false},
          {"version":"2.0","user_name":"Scottie Lebsack","gateway":"2Checkout","acquiring_bank":"American Express","american_express":true,"device_id":"882-06-8723","device_name":"iPhone 11","status":true},
          {"version":"2.0","user_name":"Shoshana Corkery","gateway":"WePay","acquiring_bank":"American Express","american_express":true,"device_id":"447-16-2974","device_name":"iPhone 7 Plus","status":false}],
        inputValueLeft: null,
      }
    },
    methods: {
    }
}
</script>

<style lang="css" scoped>
.date-range-holder{
  width: 39%;
  float: left;
}

button.report {
  padding: 10px 40px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-bottom: 15px;
}

button.setup:hover {
  color: white;
}
table {
  border-collapse:separate;
  border-spacing: 0 1em;
}

table thead tr th {
  border: none;
  background-color: #DEDEDE;
}

table tbody tr td {
  padding: 30px 13px;
  font-size: 16px;
  background-color: #DEDEDE;
}

table tbody tr td:nth-child(5) {
  text-align: center;
  padding-right: 70px;
}

table tbody tr:hover {
  cursor: pointer;
}

.devices-table{
  margin-bottom: 30px;
}
</style>
