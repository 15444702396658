<template>
  <div>
    <div class="date-range-holder">
      <VueCtkDateTimePicker
          v-model="dateRange"
          color="#483D8B"
          range
          overlay
          hint="Selected:"
          label="Filter by date"
          @input="onDateChange"
      >
      </VueCtkDateTimePicker>
    </div>

    <label class="ml-4">
      <input class="mb-3 pl-5 form-control" id="input" type="text" placeholder="Search by name" v-model="teamMemberName" @input="filterByName">
      <i class="fa fa-search" aria-hidden="true"></i>
    </label>
    <div v-if="message" :class="error ? 'alert alert-warning' : 'alert alert-info'">
      {{message}}
    </div>
    <div v-if="!errored" id="bills">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Name</th>
          <th>NPS Score</th>
          <th>Number of Bills</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="result in results" class="text-center">
          <td>{{result.name}}</td>
          <td>{{result.nps_score}}</td>
          <td>{{result.total_bills}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </div>
  </div>

</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: "TeamMembersNPS",
  components: {
    VueCtkDateTimePicker
  },
  created() {
    this.loadData();
  },
  data() {
    return {results: [],
      dateRange: null,
      errored: false,
      busy: false,
      limit: 30,
      keyword: '',
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      teamMemberName: null,
      message: null,
      error: false,
      pageSize: null,
      maxPage: 10,
      totalItems: 0,
    }
  },
  methods: {
    loadData() {
      this.busy = true;
      let params = new URLSearchParams();

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      if (this.teamMemberName !== null) {
        params.append('name', this.teamMemberName);
      }

      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      axios.get("backoffice/nps-team-members",  {params: params})
          .then(res => {
            this.errored = false;
            this.results = res.data.data;
            this.maxPage = 1;
            this.totalItems = 10;
          }).
      catch( (err) => {
        this.message = err.response.data.message;
        this.error = true;
        this.errored = true;
        this.clearMessage();
      });
      this.busy = false;
    },

    filterByName() {
      this.page = 1;

      this.loadData();
    },

    onDateChange() {
      this.page = 1;
      this.loadData();
    },

    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },

    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },

    clearMessage(errorValue = false) {
      setTimeout(() => {
        this.message = null;
        this.error = errorValue;
      }, 3000);
    },

    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  },
}
</script>

<style scoped>

input{
  height: 44px;
}

table thead tr,
.fa-search
{
  color: #B4B4B4;
}

table thead tr th{
  border: none;
  text-align: center;
}

table tbody tr td.nps{
  color: #89C58E;
}

table tbody tr td.tblnum{
  font-weight: bold;
}

table tbody tr td{
  padding: 25px 15px!important;
}

table tbody tr:hover{
  cursor: pointer;
}

.filter{
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.filter::-webkit-scrollbar {
  display: none;
}

label{
  position: relative;
}

.fa-search{
  position: absolute;
  left: 8%;
  top: 21%;
}

.date-range-holder{
  width: 40%;
  display: inline-block;
  vertical-align: middle;
}

.text-center{
  text-align: center;
}

</style>