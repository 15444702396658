import axios from 'axios'

const state = {
  fileType: 'Select Type (Video/Image)',
  loadFiles: [],
  loadFilesErrors: '',
  imageValidationErrors: '',
  videoValidationErrors: '',
  backendErrors: null,
  success: null,
  loading: false,
};

const mutations = {
  SET_WELCOME_IMAGE: (state, data) => {
    let newLoadFiles = {}
    newLoadFiles = {image: data, video: null}
    state.loadFiles = [];
    state.loadFiles.push(newLoadFiles)
  },
  SET_WELCOME_VIDEO: (state, data) => {
    let newLoadFiles = {}
    state.loadFiles = [];
    newLoadFiles = {image: null, video: data}
    state.loadFiles.push(newLoadFiles)
  },
  LOAD_WELCOME_FILES: (state) => {
    axios.get('backoffice/get-welcome-screen-files')
      .then(res => {
        if (res !== null) {
          if (res.data.data !== null && res.data.data.length > 0 && (res.data.data[0].image !== null || res.data.data[0].video !== null)) {
            state.loadFiles = res.data.data
          } else {
            state.loadFiles = []
          }
      } else {
        state.loadFiles = []
      };
    })
    .catch(error => {
      console.log(error);
      state.loadFilesErrors = 'Something went wrong with displaying the screen. Please try again later.'
      setTimeout(() => {
        state.loadFilesErrors = ''
      }, 3000);
    })
  },
  LOADING: (state, data) => {
    if (data) {
      return state.loading = true
    }
  },
  SET_FILE_TYPE: (state, value) => {
    state.fileType = value
  },
  CHANGE_FILE_TYPE: (state) => {
    state.fileType = 'Select Type (Video/Image)'
  },
  CLEAR_LOADING: (state) => {
    return state.loading = false
  },
  PREVIEW_IMAGE_ERROR_MESSAGE: (state, error) => {
    return state.imageValidationErrors = error
  },
  CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE: (state) => {
    return state.imageValidationErrors = '';
  },
  PREVIEW_VIDEO_ERROR_MESSAGE: (state, error) => {
    return state.videoValidationErrors = error
  },
  CLEAR_PREVIEW_VIDEO_ERROR_MESSAGE: (state) => {
    return state.videoValidationErrors = '';
  },
  IMAGE_ERROR_MESSAGE: (state, error) => {
    let errors = []
    if (error) {
      errors.push(error.image[0])
      return state.backendErrors = errors
    }
  },
  CLEAR_IMAGE_ERROR_MESSAGE: (state) => {
    return state.backendErrors = null;
  },
  VIDEO_ERROR_MESSAGE: (state, error) => {
    let errors = []
    if (error) {
      errors.push(error.video[0])
      return state.backendErrors = errors
    }
  },
  CLEAR_VIDEO_ERROR_MESSAGE: (state) => {
    return state.backendErrors = null;
  },
  CLEAR_LOAD_ERROR_MESSAGE: (state) => {
    return state.loadFilesErrors = '';
  },
  SUCCESS_MESSAGE: (state, data) => {
    return state.success = 'Saved'
  },
  CLEAR_SUCCESS_MESSAGE: (state, data) => {
    return state.success = null
  }
};

const actions = {
  addWelcomeImage: ({ commit }, payload) => {
    const imageData = new FormData();
    imageData.append('image', payload);

    const config = {
      headers: {'Content-Type': `multipart/form-data; boundary=${imageData._boundary}`}
    }

    commit('LOADING', payload)

    axios.post('backoffice/welcome-screen-image', imageData, config)
      .then(res => {
        if (res) {
          commit('CLEAR_LOADING')
          commit('SUCCESS_MESSAGE', res.data.message)
          if (res.data.message) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
              commit('SET_WELCOME_IMAGE', res.data.data)
              commit('CHANGE_FILE_TYPE')
            }, 2000);
          }
        };
      })
      .catch(error => {
        commit('CLEAR_LOADING')
        commit('IMAGE_ERROR_MESSAGE', error.response.data)
      })
  },
  addWelcomeVideo: ({ commit }, payload) => {
    const videoData = new FormData();
    videoData.append('video', payload);

    const config = {
      headers: {'Content-Type': `multipart/form-data; boundary=${videoData._boundary}`}
    }

    commit('LOADING', payload)

    axios.post('backoffice/welcome-screen-video', videoData, config)
      .then(res => {
        if (res) {
          commit('CLEAR_LOADING')
          commit('SUCCESS_MESSAGE', res.data.message)
          if (res.data.message) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
              commit('SET_WELCOME_VIDEO', res.data.data)
              commit('CHANGE_FILE_TYPE')
            }, 2000);
          }
        };
      })
      .catch(error => {
        commit('CLEAR_LOADING')
        commit('VIDEO_ERROR_MESSAGE', error.response.data)
      })
  },
  handleWelcomeImage: ({ commit }, payload ) => {
    let size = payload.size/1024/1024;
    let message = ''
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const MAX_SIZE = 5;
    const tooLarge = size > MAX_SIZE;

    if(!allowedTypes.includes(payload.type)) {
      message = 'Allowed only image/jpeg, image/jpg, image/png'
      commit('PREVIEW_IMAGE_ERROR_MESSAGE', message)
    } else if (tooLarge) {
      message = `Too large. Max size is ${MAX_SIZE}MB`
      commit('PREVIEW_IMAGE_ERROR_MESSAGE', message)
    } else {
      commit('CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE')
    }
  },
  handleWelcomeVideo: ({ commit }, payload ) => {
    let size = payload.size/1024/1024;
    let message = ''
    const allowedTypes = ["video/mp4", "video/mov", "video/quicktime"];
    const MAX_SIZE = 15;
    const tooLarge = size > MAX_SIZE;

    if(!allowedTypes.includes(payload.type)) {
      message = 'Allowed only video/mp4, video/mov, video/quicktime'
      commit('PREVIEW_VIDEO_ERROR_MESSAGE', message)
    } else if (tooLarge) {
      message = `Too large. Max size is ${MAX_SIZE}MB`
      commit('PREVIEW_VIDEO_ERROR_MESSAGE', message)
    } else {
      commit('CLEAR_PREVIEW_VIDEO_ERROR_MESSAGE')
    }
  }
}

const getters = {
  fileType: state => state.fileType,
  loadFiles: state => state.loadFiles,
  loadFilesErrors: state => state.loadFilesErrors,
  imageValidationErrors: state => state.imageValidationErrors,
  videoValidationErrors: state => state.videoValidationErrors,
  backendErrors: state => state.backendErrors,
  success: state => state.success,
  loading: state => state.loading
};

export default {
  state,
  mutations,
  actions,
  getters
}
