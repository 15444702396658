<template>
    <div class="header">
      <img alt="ty logo" src="../../assets/tableYeti-logo.png">
      <nav class="">
      <ul class="">
          <!-- <li><router-link :to="{ name: 'Sales' }" exact>Sales</router-link></li> -->
          <!-- <li><router-link :to="{ name: 'Feedback' }" exact>Feedback</router-link></li> -->
          <!-- <li><router-link :to="{ name: 'Marketing' }" exact>Marketing</router-link></li> -->
          <!-- <li><router-link :to="{ name: 'Account' }" exact>Account</router-link></li> -->
          <!-- <li><router-link :to="{ name: 'Service' }" exact>Service Charge</router-link></li> -->
          <li><router-link :to="{ name: 'Overview' }" exact>Overview</router-link></li>
          <li><router-link :to="{ name: 'BillsPage' }" exact>Sales</router-link></li>
          <li><router-link :to="{ name: 'NetPromoterPage' }" exact>Feedback</router-link></li>
          <li><router-link :to="{ name: 'WelcomePage' }" exact>Marketing</router-link></li>
          <li><router-link :to="{ name: 'TipManagementReportsPage' }" exact>Service Charge</router-link></li>
          <li><router-link :to="{ name: 'PaymentsPage' }" exact>Account</router-link></li>
      </ul>
    </nav>
</div>
</template>

<script>
// import Sales from '../../views/Sales';
// import Feedback from '../../views/Feedback';
// import Marketing from '../../views/Marketing';
// import Service from '../../views/Service';
// import Account from '../../views/Account';
import Overview from '../../views/Overview';
import BillsPage from '../../views/salesPages/BillsPage';
import NetPromoterPage from '../../views/feedbackPages/NetPromoterPage';
import WelcomePage from '../../views/marketingPages/WelcomePage';
import TeamMembersPage from '../../views/servicePages/TeamMembersPage';
import PaymentsPage from '../../views/accountPages/PaymentsPage';
import AccountManagerPage from '../../views/accountPages/AccountManagerPage';
import TipManagementReportsPage from '../../views/servicePages/TipManagementReportsPage';

export default {
  name: 'Header',
  components:{
    // Sales,
    // Feedback,
    // Marketing,
    // Service,
    // Account
    Overview,
    BillsPage,
    NetPromoterPage,
    WelcomePage,
    TeamMembersPage,
    PaymentsPage,
    TipManagementReportsPage,
    AccountManagerPage,
  },
}
</script>

<style scoped>

div.header{
  display: flex;
  justify-content: center;
  position: relative;
}

div.header img{
  position: absolute;
  left: 0;
  top: 15%;
  height: 3.5em;
}

nav{
  margin-top: 15px;
  margin-bottom: 30px;
}
nav ul{
  list-style: none;
  text-align: center;
}

nav ul li {
  display: inline-block;
  margin-right: 20px;
  border-radius: 3px;
  background: rgb(60, 3, 140);
}

nav ul li a{
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 154px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.router-link-active{
  color: rgb(227, 70, 150);
}

</style>
