import axios from 'axios'

const state = {
  widgetType: 'Select-Type',
  widgetsList: [],
  selectedWidget: {},
  vouchersList: [],
  onWidgetsSuccess: null,
  onWidgetsErrors: null,
  onLoadWidgetsErrors: '',
};

const mutations = {
  CHANGE_WIDGET_TYPE: (state) => {
    state.widgetType = 'Select-Type'
  },
  SET_WIDGET_TYPE: (state, value) => {
    state.widgetType = value
  },
  LOAD_ALL_WIDGETS_LIST: (state) => {
    axios.get('backoffice/widgets/list')
      .then(res => {
        if(res !== null && res !== undefined) {
          if (res.data.data !== null
              && res.data.data !== undefined
              && res.data.data.length > 0) {
              // On loading modification on widgetsList for loading non cached version of logo
              let wgList = res.data.data;
              wgList.forEach(e => {
                e.logo = e.logo + '?' + Date.now();
              });
            state.widgetsList = wgList;
          } else if (res.data.data !== null
              && res.data.data !== undefined
              && res.data.data.length === 0) {
            state.onLoadWidgetsErrors = 'No widgets yet'
            state.widgetsList = []
          } else {
            state.widgetsList = []
          }
        } else {
          state.widgetsList = []
        };
      })
      .catch(error => {
        console.log(error.message)
        if(error.message === 'Request failed with status code 401') {
          state.onLoadWidgetsErrors = 'Session expired, please login again.'
        } else {
          state.onLoadWidgetsErrors = 'Something went wrong with displaying the widgets. Please try again later.'
        }
      })
  },
  WIDGETS_ERROR_MESSAGE: (state, error) => {
    console.log(error.message)
    if(error.message === '') {
      return state.onWidgetsErrors = 'Something went wrong. Please try again later.'
    }
    // else {
    //   return state.onWidgetsErrors = error.message
    // }
  },
  CLEAR_WIDGETS_ERROR_MESSAGE: (state) => {
    return state.onWidgetsErrors = null;
  },
  CLEAR_LOAD_ERROR_MESSAGE: (state) => {
    return state.onLoadWidgetsErrors = '';
  },
  SUCCESS_MESSAGE: (state, data) => {
    return state.onWidgetsSuccess = data;
  },
  CLEAR_SUCCESS_MESSAGE: (state, data) => {
    return state.onWidgetsSuccess = '';
  },
  LOAD_SELECTED_WIDGET: (state, data) => {
    let widget = {};
    widget = data.list.find(item => {
      return item.id === data.id;
    })
    state.selectedWidget = widget;
    console.log(state.selectedWidget);
    state.vouchersList = widget.details;
  },
  EMPTY_SELECTED_WIDGET: (state) => {
    state.selectedWidget = {};
    state.vouchersList = [];
  }
};

const actions = {
  getWidgetType: ({ commit }, payload) => {
    let type = '';
    if(payload === 'Website') {
      type = 'Website'
      commit('SET_WIDGET_TYPE', type)
    } else if(payload === 'Instagram') {
      type = 'Instagram'
      commit('SET_WIDGET_TYPE', type)
    } else if(payload === 'Donations') {
      type = 'Donations'
      commit('SET_WIDGET_TYPE', type)
    } else {
      type = 'Select-Type'
      commit('SET_WIDGET_TYPE', type)
    }
  },
  getSelectedWidget: ({ commit }, payload) => {
    axios.get('backoffice/widgets/list')
      .then(res => {
        if (res) {
          commit('LOAD_SELECTED_WIDGET', {list: res.data.data, id: payload})
        }
      })
      .catch(error => {
        commit('WIDGETS_ERROR_MESSAGE', error.response.data);
        if (error.response.data) {
          setTimeout(() => {
            commit('CLEAR_WIDGETS_ERROR_MESSAGE')
          }, 1500);
        }
      })
  },
  updateOrder: ({ commit }, payload) => {
    axios.post('backoffice/widgets/update-order', payload)
      .then(res => {
        if (res) {
          console.log(res.data.data)
        }
      })
      .catch(error => {
        alert('Reordering failed. Please try again.');
        commit('WIDGETS_ERROR_MESSAGE', 'Reordering failed. Please try again.');
        setTimeout(() => {
          commit('CLEAR_WIDGETS_ERROR_MESSAGE')
        }, 1500);
      })
  },
  addWidget: ({ commit }, payload) => {
    const config = {
      headers: {'Content-Type': 'application/json'}
    }
    axios.post('backoffice/widgets/add', JSON.stringify(payload), config)
      .then(res => {
        if (res) {
          setTimeout(() => {
            commit('CHANGE_WIDGET_TYPE')
          }, 500);
          commit('SUCCESS_MESSAGE', res.data.message)
          if (res.data.message) {
              setTimeout(() => {
                  commit('CLEAR_SUCCESS_MESSAGE')
                }, 2000);
              }
          commit('LOAD_ALL_WIDGETS_LIST', res.data.data)
          commit('CLEAR_LOAD_ERROR_MESSAGE')
          commit('EMPTY_SELECTED_WIDGET')
        };
      })
      .catch(error => {
        console.log(error.response)
        commit('WIDGETS_ERROR_MESSAGE', error.response.data);
        if(error.response.data) {
          setTimeout(() => {
            commit('CLEAR_WIDGETS_ERROR_MESSAGE')
          }, 1500);
        }
      })
  },
  updateWidget: ({ commit }, payload) => {
    axios.post(`backoffice/widgets/${payload.data.id}/update`, payload.data)
    .then(res => {
      axios.get('backoffice/widgets/list')
        .then(res => {
          let widget = {};
          widget = res.data.data.find(item => {
            return item.id === payload.data.id;
          })

          let dbVouchers = widget.details;
          let orderedVouchers = payload.vouchers;
          for (let i = 0; i < orderedVouchers.length; i++) {
            for (let j = 0; j < dbVouchers.length; j++) {
              if (dbVouchers[j] !== undefined
                && dbVouchers[j].id === orderedVouchers[i].id) {
                dbVouchers[j] = undefined;
              }
            }
          }
          for (let i = 0; i < orderedVouchers.length; i++) {
            if (orderedVouchers[i].id === undefined || orderedVouchers[i].id === null) {
              for (let j = 0; j < dbVouchers.length; j++) {
                if (dbVouchers[j] === undefined) {
                  continue;
                } else if (dbVouchers[j].details.amount === orderedVouchers[i].details.amount
                  || dbVouchers[j].details.voucher_offer === orderedVouchers[i].details.voucher_offer
                  || dbVouchers[j].details.voucher_text === orderedVouchers[i].details.voucher_text) {
                    orderedVouchers[i].id = dbVouchers[j].id;
                    dbVouchers[j] = undefined;
                    break;
                  }
              }
            }
          }

          const toPostVouchersOrder = orderedVouchers.map(voucher => {
            return voucher.id;
          });

          const newOrder = {
            widget_details_order: toPostVouchersOrder
          };

          axios.post('backoffice/widgets/update-order', newOrder)
            .then(res => console.log(res))
            .catch(error => console.log(error))
        })
      .catch(error => console.log(error))

      if(res) {
        commit('SUCCESS_MESSAGE', 'Widget successfully updated!')
        if (res.data.message) {
          setTimeout(() => {
            commit('CLEAR_SUCCESS_MESSAGE')
          }, 1000);
        }
        window.location.reload()
        setTimeout(() => {
          commit('CHANGE_WIDGET_TYPE')
          commit('EMPTY_SELECTED_WIDGET')
        }, 500);
      };
    })
    .catch(error => {
      console.log(error.response)
      commit('CHANGE_WIDGET_TYPE')
      commit('EMPTY_SELECTED_WIDGET')
      commit('WIDGETS_ERROR_MESSAGE', error.response.data);
      if(error.response.data) {
        setTimeout(() => {
          commit('CLEAR_WIDGETS_ERROR_MESSAGE')
        }, 1500);
      }
    })
  },
  updateVoucher: ({ commit }, payload) => {
    axios.post(`backoffice/widgets/${payload.id}/update-details`, payload.voucher)
    .then(res => {
      if (res) {
        commit('SUCCESS_MESSAGE',  res.data.message)
        if (res.data.message) {
          setTimeout(() => {
            commit('CLEAR_SUCCESS_MESSAGE')
          }, 2000);
        }
      };
    })
    .catch(error => {
      console.log(error.response)
      commit('WIDGETS_ERROR_MESSAGE', error.response.data);
      if (error.response.data) {
        setTimeout(() => {
          commit('CLEAR_WIDGETS_ERROR_MESSAGE')
        }, 1500);
      }
    })
  },
  deleteWidget: ({ commit }, payload) => {
    axios.delete(`backoffice/widgets/${payload}/delete`)
      .then(res => {
        if(res.status === 200) {
          commit('SUCCESS_MESSAGE', 'Widget successfully deleted!')
          if(res.data) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
            }, 2500);
          }
          commit('LOAD_ALL_WIDGETS_LIST', res.data.data)
        };
      })
      .catch(error => {
        console.log(error.response)
        commit('WIDGETS_ERROR_MESSAGE', error.response.data);
        if(error.response.data) {
          setTimeout(() => {
            commit('CLEAR_WIDGETS_ERROR_MESSAGE')
          }, 1500);
        }
      })
  }
};

const getters = {
  widgetType: state => state.widgetType,
  widgetsList: state => state.widgetsList,
  selectedWidget: state => state.selectedWidget,
  vouchersList: state => state.vouchersList,
  onWidgetsSuccess: state => state.onWidgetsSuccess,
  onWidgetsErrors: state => state.onWidgetsErrors,
  onLoadWidgetsErrors: state => state.onLoadWidgetsErrors,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
