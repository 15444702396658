import { render, staticRenderFns } from "./EditDonationsComponent.vue?vue&type=template&id=e26b64ac&scoped=true&v-if=widgetType%20%3D%3D%3D%20'Donations'&"
import script from "./EditDonationsComponent.vue?vue&type=script&lang=js&"
export * from "./EditDonationsComponent.vue?vue&type=script&lang=js&"
import style0 from "./EditDonationsComponent.vue?vue&type=style&index=0&id=e26b64ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e26b64ac",
  null
  
)

export default component.exports