<template lang="html">
  <div class="">
    <SalesSubheader />
    <p>Settlements</p>
    <SettlementsComponent />
  </div>
</template>

<script>

import SalesSubheader from '../../components/SalesComponents/SalesSubheader';
import SettlementsComponent from '../../components/SalesComponents/SettlementsComponent';

export default {
  name: "SettlementPage",
  components: {
    SalesSubheader,
    SettlementsComponent
  }
}
</script>

<style scoped>

div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>