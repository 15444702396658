<template v-if="serviceAmount === 'Additional Tip pop-up'">
  <div>
    <form @submit.prevent="onSubmit" action="" method="">
      <div class="form-row">
          <div class="form-group col-9">
            <input type="text" name="" placeholder="Message" v-model="message" @click="onClick()">
            <p class="alert alert-danger" v-bind:class="{'d-none': isActiveMsg }" role="alert">*please enter your message</p>
          </div>
      </div>
      <div class="form-row">
        <div class="input-group col-5">
          <input class="form-control mr-3 group" type="text" placeholder="15%" v-model="great" @keypress="onlyForCurrency">
          <input class="form-control mr-3 group" type="text" placeholder="17.5%" v-model="exellent" @keypress="onlyForCurrency">
          <input class="form-control mr-3 group" type="text" placeholder="22.5%" v-model="exceptional" @keypress="onlyForCurrency">
          <p class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*please enter numbers in percentage fields</p>
        </div>
      </div>
      <div class="btnWrapper">
        <button class="btn update">Update</button>
      </div>
    </form>
  <!-- Iphone Holder -->
    <div class="iphone-holder float-right">
      <div class="inner">
        <div class="content text-center">
          <img src="../../../assets/icon-back-arrow-iphone.png" class="float-left arrow mt-3" alt="">
          <h6 class="mb-0 pt-3 mr-5">Your Bill</h6>
          <p class=" mb-0 pb-2 secondary table-text">Table 51</p>
          <img alt="ham logo" src="../../../assets/hamburger_menu_icon.png" class="mr-2 ham-img">
        </div>
        <div class="container-fluid ">
          <div class="row">
            <div class="col-md-12 parent-holder p-0">
              <ul class="">
                <li class="first">Tap <img src="../../../assets/like-icon-yellow.svg" alt="" class="first"> below to leave feedback  <img alt="info logo" src="../../../assets/info-icon.png" class="mr-2 info-img"> <span class="float-right secondary amount">Amount</span> </li>
                <li v-for="item in items" class="my-1 py-1 px-2">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   width="84.000000pt" height="84.000000pt" viewBox="0 0 84.000000 84.000000"
                   preserveAspectRatio="xMidYMid meet" class="mr-2">
                  <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
                  fill="#E79D49" stroke="none" class="primary-svg">
                  <path d="M494 816 c-9 -39 3 -78 24 -74 13 3 17 14 17 48 0 34 -4 45 -17 48
                  -12 2 -19 -5 -24 -22z"/>
                  <path d="M340 752 c0 -25 45 -66 64 -59 23 9 20 30 -7 55 -28 27 -57 29 -57 4z"/>
                  <path d="M641 746 c-23 -24 -23 -56 -1 -56 17 0 62 49 57 63 -7 21 -32 18 -56
                  -7z"/>
                  <path d="M431 623 c-22 -74 -66 -141 -104 -162 -22 -12 -28 -11 -54 8 -26 19
                  -40 21 -123 19 l-95 -3 0 -240 0 -240 95 -3 c81 -2 98 0 121 17 26 20 27 20
                  89 0 48 -15 91 -19 196 -19 130 0 136 1 159 25 14 13 25 35 25 49 0 14 11 36
                  25 50 26 26 33 64 15 82 -8 8 -7 19 1 37 9 20 9 33 1 51 -8 18 -8 33 1 55 10
                  26 8 34 -9 58 -20 26 -27 28 -110 33 -73 4 -88 8 -85 20 2 8 6 50 9 92 4 75 4
                  78 -26 108 -24 23 -39 30 -70 30 l-40 0 -21 -67z m97 5 c15 -15 16 -100 1
                  -150 -9 -35 -13 -38 -45 -38 -31 0 -35 -3 -32 -22 3 -22 7 -23 138 -26 74 -1
                  139 -7 144 -12 16 -16 -13 -39 -52 -42 -25 -2 -37 -8 -37 -18 0 -10 12 -16 37
                  -18 65 -6 78 -52 14 -52 -35 0 -62 -21 -51 -40 3 -5 21 -10 38 -10 41 0 62
                  -14 54 -34 -4 -10 -18 -16 -40 -16 -55 0 -76 -33 -31 -50 16 -7 24 -16 22 -28
                  -3 -15 -19 -17 -132 -20 -110 -2 -139 1 -193 19 l-63 21 0 154 c0 120 3 154
                  13 154 40 0 136 109 157 179 7 20 13 42 15 49 5 15 27 16 43 0z m-283 -383 l0
                  -190 -72 -3 -73 -3 0 196 0 196 73 -3 72 -3 0 -190z"/>
                  <path d="M154 135 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36 0z"/>
                  </g>
                  </svg>
                 {{item.name}} <span class="float-right">&#163; {{item.price}}</span> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bottom" >
          <div class="mb-1">
            <p class="p-2 mb-0">Service Charge <span class="float-right">&#163; 48.75</span></p>
          </div>
          <!-- Iphone Real Preview -->
          <div v-if="cardInIphoneRealPreview">
            <p class="p-message px-3 m-0">{{message}}</p>
            <div class="col-md-4 float-left percent">{{great}}&#37;</div>
            <div class="col-md-4 ml-1 float-left percent">{{exellent}}&#37;</div>
            <div class="col-md-4 ml-1 float-left percent">{{exceptional}}&#37;</div>
            <div>
              <p class="tips m-0"><span class="mr-2">Great</span><span>Exellent</span><span class="ml-3 pl-3">Exceptional</span></p>
            </div>
          </div>
          <!-- Iphone onSave Preview -->
          <div v-else="cardInIphone">
            <p class="p-message px-3 m-0">{{formData.message}}</p>
            <div class="col-md-4 float-left percent">{{formData.great | to-fixed-one}}&#37;</div>
            <div class="col-md-4 ml-1 float-left percent">{{formData.exellent | to-fixed-one}}&#37;</div>
            <div class="col-md-4 ml-1 float-left percent">{{formData.exceptional | to-fixed-one}}&#37;</div>
            <div>
              <p class="tips m-0"><span class="mr-2">Great</span><span>Exellent</span><span class="ml-3 pl-3">Exceptional</span></p>
            </div>
          </div>
          <div class="btn-iphone-wrapper">
            <button type="button" name="button" disabled class="no-thanks">No Thanks</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "AdditionalTipComponent",
  props: ['serviceAmount'],
  data() {
    return {
      serviceAmount: this.$store.getters.serviceAmount,
      items: [
        {name: "*BOOZLESS SET", price: "60.00"},
        {name: "*BOOZLESS SET", price: "60.00"},
        {name: "*2 St Mary's garden Dona.. ", price: "1.00"},
        {name: "*2 St Mary's garden Dona..  ", price: "1.00"},
      ],
      message: "",
      great: "",
      exellent: "",
      exceptional: "",
      formData: {},
      isActiveMsg: true,
      isActive: true,
      cardInIphoneRealPreview: true,
      cardInIphone: false,
    }
  },
  computed: {
    messageIsValid () {
      return !!this.message
    },
    greatIsValid () {
      return !!this.great
    },
    exellentIsValid () {
      return !!this.exellent
    },
    exceptionalIsValid () {
      return !!this.exceptional
    },
    percentInputsAreValid () {
      return this.greatIsValid && this.exellentIsValid && this.exceptionalIsValid;
    },
  },
  methods: {
    onClick () {
      this.isActiveMsg = true;
      this.isActive = true;
      this.cardInIphoneRealPreview = true;
      this.cardInIphone = false;
    },
    onSubmit () {
      if (!this.messageIsValid) {
        this.isActiveMsg = false;
      } else if (!this.percentInputsAreValid) {
        this.isActive = false;
      } else {
        this.formData = {
          message: this.message,
          great: this.great,
          exellent: this.exellent,
          exceptional: this.exceptional
        }
        this.resetFields();
      }
    },
    onlyForCurrency ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) {
        $event.preventDefault();
       }
       if(this.price!=null && this.price.indexOf(".")>-1 && (this.price.split('.')[1].length > 1)){
        $event.preventDefault();
       }
    },
    resetFields () {
      this.message = "";
      this.great = "";
      this.exellent = "";
      this.exceptional = "";
      this.serviceAmount = "Additional Tip pop-up";
      this.isActiveMsg = true;
      this.isActive = true;
      this.cardInIphone = true;
      this.cardInIphoneRealPreview = false;
    }
  }
}
</script>

<style lang="css" scoped>
.iphone-holder{
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -140px;
}

.inner{
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.parent-holder{
  background: #E8E8E8;
  padding: 0 6px!important;
}

.content p.table-text{
  color: #463C8B;
  font-weight: bold;
  font-size: 14px;
  margin-right: 45px;
}

img.arrow{
  height: 1.3em;
  margin-left: 10px;
}

ul li.first{
  background: #F0F0F0;
  padding: 8px!important;
  margin-top: 6px;
  font-size: 11px;
  color: #B3B3B3;
}

.content{
  position: relative;
}

.content h6,
.total{
  color: #1F1F1F;
  font-weight: bold;
}

.content img.ham-img{
  height: 13px;
  position: absolute;
  top: 19px;
  right: 3px;
}

svg.arrow-img{
  height: 5em;
  width: 100px;
  position: absolute;
  bottom: 33%;
  left: 33%;
}

ul{
  list-style: none;
  padding: 0;
  background: #E8E8E8;
}

ul li{
  display: block;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
}

ul li span.amount{
  color: #463C8B;
  font-weight: bold;
  font-size: 15px;
}

ul li svg{
  height: 2em;
  width: 31px;
}

ul li img.first{
  vertical-align: sub;
  height: 2em;
}

ul li img{
  height: 1.3em;
}

.bottom{
  background: #E8E8E8;
}

.bottom p,
.bottom span{
  background: #FFFFFF;
  color: #C9C9C9;
  border-radius: 2px;
  font-size: 14px;
}

p.p-message{
  min-height: 100px;
  max-width: 298px;
  color:#000000;
  font-size: 16px;
  line-height: 16px;
  padding-top: 15px;
  word-wrap: break-word;
}

.no-thanks,
.percent{
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #E79D49;
  width: 90%;
  margin-left: 5%;
  padding: 4px;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.percent{
  height: 30px;
  width: 29%;
  background: #E79D49;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 5px;
}

.tips span{
  color: #E79D49;
  font-size: 14px;
  font-weight: bold;
  margin-left: 40px;
}

.btn-iphone-wrapper{
  background-color: #FFFFFF;
  padding-top: 5px;
}

label,
input{
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 65px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input{
  width: 52%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
  letter-spacing: 0.3px;
}

.group{
  border-radius: 7px !important;
}

label.pl-3{
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0.3px;
}

form{
  display: inline-block;
  width: 69%;
}

input:focus,
input.visited{
  color: #202020;
}

button.update{
  padding: 13px 55px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-top: 20px;
}

.btnWrapper{
  width: 51%;
  margin-left: 70px;
}

.alert-danger {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px;
}
</style>
