<template lang="html">
<div>
  <div class="date-range-holder">
    <VueCtkDateTimePicker
      v-model="inputValueLeft"
      color="#483D8B"
      range
      overlay
      hint="Selected:"
      label="Select Date"
      id="left"
      >
    </VueCtkDateTimePicker>
  </div>

  <select class="ml-4" v-on:change="changeItem">
    <option disabled selected value>Select Feedback Type</option>
    <option value="Disliked">Disliked</option>
    <option value="Liked">Liked</option>
    <option value="Served Cold">Served Cold</option>
    <option value="Low Value">Low Value</option>
    <option value="Favourite">Favourite</option>
  </select>
  <div class="right-side-compare">
    <div class="all-time border border-secondary mx-4">
      <div class="">
        <p class="m-0 mb-3" style="text-indent: -5px;">All Time</p>
        <p class="m-0 score">100.00</p>
      </div>
    </div>

   <VueCtkDateTimePicker
      v-model="inputValueTopRight"
      color="#483D8B"
      range
      overlay
      hint="Selected:"
      label="Click to compare"
      class="mb-1"
      id="middle"
      >
    </VueCtkDateTimePicker>

    <VueCtkDateTimePicker
      v-model="inputValueBottomRight"
      color="#483D8B"
      range
      overlay
      hint="Selected:"
      label="Click to compare"
      id="right"
      >
    </VueCtkDateTimePicker>
  </div>
  <h2>{{this.emojiStatus}} <img v-bind:src="this.emojiImage" alt="" class="emoji"> </h2>
  <div id="bills">
    <table v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit" class="table table-hover">
      <thead>
        <tr>
          <th>Menu Item</th>
          <th>Total Times on Bill</th>
          <th>Total Feedback Count</th>
          <th>Total Disliked</th>
          <th>% of feedback</th>
        </tr>
      </thead>
      <tbody>
        <template  v-if="showSlicedData">
          <tr v-for="data in filteredIds" :key="data.id">
            <td>{{data.id}}</td>
            <td>{{data.name.slice(0,16)}}</td>
            <td>{{data.email.slice(0,6)}}</td>
            <td>{{data.postId}}</td>
            <td>{{data.id}}</td>
          </tr>
        </template>

        <template v-else>
          <!-- <div class="pl-2 py-4">{{filterDate}}</div> -->
          <tr v-for="data in slicedTableData" :key="data.id">
            <td>{{data.id}}</td>
            <td>{{data.name.slice(0,16)}}</td>
            <td>{{data.email.slice(0,6)}}</td>
            <td>{{data.postId}}</td>
            <td>{{data.id}}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: "MenuComponent",
   components: {
     VueCtkDateTimePicker
   },
  data(){
    return {
      slicedTableData: [],
      allTableData: [],
      busy: false,
      limit: 30,
      keyword: '',
      showSlicedData: false,
      filterDate: "This year",
      inputValueLeft: null,
      inputValueTopRight: null,
      inputValueBottomRight: null,
      emojiStatus: "Disliked",
      emojiImage:  require('@/assets/disliked.png'),
      emojiImageDisliked:  require('@/assets/disliked.png'),
      emojiImageLiked:  require('@/assets/liked.png'),
      emojiImageServed:  require('@/assets/served_cold.png'),
      emojiImageLow:  require('@/assets/low_value.png'),
      emojiImageFav:  require('@/assets/favourite.png'),
    }
  },
  methods: {
    loadMore() {
      this.busy = true;
      axios.get("https://jsonplaceholder.typicode.com/comments").then(res => {
        const append = res.data.slice(this.slicedTableData.length,this.slicedTableData.length + this.limit )
        this.slicedTableData = this.slicedTableData.concat(append);
        this.allTableData = res.data;
      this.busy = false;
      }).catch( (err) => {
        this.busy = false;
      })
    },
    toggleDivClass(addRemoveClass, className) {
      const el = document.getElementById('bills');
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    filter(elem) {
      if (elem.target.value == "") {
        this.showSlicedData = false;
        this.toggleDivClass('removeClass', 'filter')
      }
      else{
        this.showSlicedData = true;
        this.toggleDivClass('addClass', 'filter')
      }
    },
    changeItem(){
      this.emojiStatus = event.target.value;
      if (event.target.value === "Disliked") {
        this.emojiImage = this.emojiImageDisliked;
      }
      else if (event.target.value === "Liked") {
        this.emojiImage = this.emojiImageLiked;
      }
      else if (event.target.value === "Served Cold") {
        this.emojiImage = this.emojiImageServed;
      }
      else if (event.target.value === "Low Value") {
        this.emojiImage = this.emojiImageLow;
      }
      else if (event.target.value === "Favourite") {
        this.emojiImage = this.emojiImageFav;
      }
    }
  },

  created() {
   this.loadMore();
 },

  computed: {
    filteredIds: function(){
      return this.allTableData.filter((item) => {
        return item.email.match(this.keyword);
      })
    }
  },

}
</script>

<style lang="css" scoped>
select{
  height: 44px;
  width: 200px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: rgba(0,0,0,.54);
}

table thead tr,
.fa-search{
  color: #B4B4B4;
}

table thead tr th{
  border: none;
}

table tbody tr td{
  padding: 25px 15px!important;
}

table tbody tr:hover{
  cursor: pointer;
}

.filter{
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.filter::-webkit-scrollbar {
  display: none;
}

.date-range-holder{
  width: 45%;
  display: inline-block;
  vertical-align: middle;
}

div.right-side-compare{
  width: 35%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  top: -44px;
}


div.all-time{
  padding: 20px 25px;
  line-height: 15px;
  font-weight: 700;
  float: left;
}

div.all-time div p.score{
  font-size: 27px;
  color: rgb(60, 3, 140);
  font-weight: 900;
}

#middle-wrapper{
  float: left;
  width: 55%;
}

#right-wrapper{
  width: 55%;
  float: left;
}

input#middle-input::placeholder{
  color: red!important;
}


img.emoji{
  height: 1em;
  vertical-align: top;
}

h2{
  font-weight: 700;
  color: rgb(60, 3, 140);
}

</style>
