<template lang="html">
  <div class="">
    <button type="button" name="button" class="btn add">Add Card</button>
  </div>
</template>

<script>
export default {
  name: "PaymentsComponent"
}
</script>

<style lang="css" scoped>
  button.add{
    padding: 15px 70px;
    background: #E85F9A;
    color: white;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
    font-weight: 700;
  }
</style>
