<template lang="html">
  <div>
    <div v-if="allCompaniesTotals" class="totals-holder">
      <table>
        <thead>
        <tr>Card total: £{{allCompaniesTotals.totalCard}}</tr>
        <tr>Tip total: £{{allCompaniesTotals.totalTip}}</tr>
        <tr>AVG tip %: {{allCompaniesTotals.totalPercentage}}</tr>
        </thead>
      </table>
    </div>
    <div class="row">
      <div class="date-range-holder mt-0 col-md-3">
        <VueCtkDateTimePicker
            maxDate="maxDate"
            v-model="dateRange"
            color="#483D8B"
            buttonColor="#e34696"
            overlay
            format=YYYY-MM-DD
            formatted=ll
            hint="Selected:"
            label="Select Date"
            id="left"
            @validate="loadData">
        </VueCtkDateTimePicker>
      </div>
      <div class="col-md-1 ">
        <button
            v-if="this.isTodayIncluded"
            class="mb-1 btn btn-sm btn-success"
            :disabled="loading"
            @click.prevent="loadData"
        >
          <i class="fa fa-refresh">Refresh</i>
        </button>
      </div>
    </div>

<!--    <label class="ml-4" v-if="companies.length > 0">-->
    <div class="mt-2 row" style="width: 40%" v-if="companies.length > 0">
      <div class="col-md-8">
        <Multiselect
            v-model="selectedCompanies"
            :multiple="true"
            :options="allCompanies"
            @change="loadData"
        >
        </Multiselect>
      </div>
      <div class="col-md-2">
        <button
            class="btn btn-sm btn-primary"
            :disabled="selectedCompanies.length === 0 || loading"
            @click.prevent="searchCompanyByName"
        >
          Search
        </button>
      </div>
      <div class="col-md-2">
        <button
            class="btn btn-sm btn-outline-warning"
            :disabled="allCompanies.length === companies.length || loading"
            @click.prevent="resetCompaniesFilter"
        >
          Reset
        </button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="date-range-holder mt-0 col-md-3">
        <VueCtkDateTimePicker
            maxDate="maxDate"
            v-model="reportDate"
            color="#483D8B"
            buttonColor="#e34696"
            overlay
            format=YYYY-MM-DD
            formatted=ll
            hint="Selected:"
            label="Select DS Report Date"
            id="left1"
        >
        </VueCtkDateTimePicker>
      </div>
      <div class="col-md-3">
        <button
            type="button"
            class="btn download"
            @click="downloadDirectSettlementReport"><span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Direct Settlement Report
        </button>
      </div>
    </div>

    <div v-if="message" :class="error ? 'alert alert-warning' : 'alert alert-info'">
      {{message}}
    </div>
    <div v-if="!errored" id="score" class="score-table mt-4">
      <table class="table table-hover">
        <thead v-if="!loading" class="sticky-header">
        <tr class="text-body" v-if="dates.length === 7">
          <th style="width: 15%!important;">Company</th>
          <th style="width: 12%!important;" v-for="date in dates">{{date}}</th>
          <th>Totals</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">Loading...</tr>
        <tr v-else v-for="company in companies" class="">
          <td class="align-left">{{company}}</td>
          <td class="" v-for="companyData in results[company]">
            Card:
            <br/>
            <b>£{{companyData['cardTotal']}}</b>
            <hr/>
            Tip:
            <br/>
            <b>£{{companyData['tipTotal']}}</b>
            <hr/>
            Tips as %:
            <br/>
            <b>{{companyData['percentage']}}</b>
          </td>
          <td>
            Card:
            <br/>
            <b>£{{weeklyTotals[company]['totalCard']}}</b>
            <hr/>
            Tip:
            <br/>
            <b>£{{weeklyTotals[company]['totalTip']}}</b>
            <hr/>
            AVG tip %:
            <br/>
            <b>{{weeklyTotals[company]['totalPercentage']}}</b>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </div>

    <Pagination
        v-if="selectedCompanies.length === 0 "
        @handlePageChange="handlePageChange"
        :maxPage="maxPage"
        :totalItems="totalItems"
    >
      <li slot="body"></li>
    </Pagination>
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import moment from 'moment';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import axios from "axios";
import Multiselect from 'vue-multiselect';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "AccountManagerComponent",
  components: {
    VueCtkDateTimePicker,
    Multiselect,
    Pagination
  },
  data () {
    return {
      dateRange: null,
      reportDate: null,
      results: [],
      loading: true,
      errored: false,
      companies: [],
      allCompanies: [],
      dates: [],
      maxDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      selectedCompanies: [],
      isTodayIncluded: false,
      weeklyTotals: [],
      interval: null,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      maxPage: null,
      totalItems: null,
      reportDatePicked: false,
      message: null,
      error: false,
      csvLoad: false,
      allCompaniesTotals: null
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      this.allCompaniesTotals = null;
      let params = new URLSearchParams();

      if (this.dateRange) {
        params.append('date', this.dateRange);
      }

      if (this.selectedCompanies.length) {
        params.append('companies', JSON.stringify(this.selectedCompanies));
      }

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      axios.get('backoffice/admin/companies-daily-totals', {params: params})
          .then(response => {
            let data = response.data.data;
            this.companies = data.companies;
            this.allCompanies = data.all_companies;
            this.dates = data.all_dates;
            this.results = data.totals;
            this.loading = false;
            this.isTodayIncluded = data.is_today_included;
            this.weeklyTotals = data.weekly_totals;
            this.totalItems = data.total_items;
            this.maxPage = data.max_page;

            if (this.selectedCompanies.length) {
              this.allCompaniesTotals = data.all_companies_totals
            }
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 300)
          })
    },
    searchCompanyByName() {
      this.page = 1;

      this.loadData();
    },
    resetCompaniesFilter() {
      this.selectedCompanies = [];
      this.loadData();
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    },
    downloadDirectSettlementReport() {
      this.loading = true;
      let params = new URLSearchParams();

      if (!this.reportDate) {
        this.loading = false;
        this.error = true;
        this.message = 'You have to select a date.'
        this.clearMessage();
        return;
      }

      params.append('date', this.reportDate);

      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/admin/companies-direct-settlement-report', {params: params}, config)
          .then(response => {
            const csvData = response.data;
            if (response.status === 200 && response.data !== null) {
              this.csvLoad = true;
              let blob = new Blob([csvData], {type: 'text/csv'});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `direct_settlement_report(${this.reportDate}).csv`;
              link.click();
              setTimeout(() => {
                this.csvLoad = false;
              }, 1000);
            }
          })
          .catch(error =>
              console.log(error)
          );
    },
    clearMessage(errorValue = false) {
      setTimeout(() => {
        this.message = null;
        this.error = errorValue;
      }, 3000);
    },
  }
}
</script>


<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover {
  color: white;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select {
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.align-left{
  text-align: left!important;
}

.sticky-header{
  position: sticky;
  top: 0;
  background: white;
}

.totals-holder{
  position: absolute;
  left: 60%;
  background: #E85F9A;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>