<template lang="html">
  <nav>
    <ul>
      <li><router-link :to="{ name: '', params: {} }">Restaurant name</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "OverviewSubheader"
}
</script>

<style lang="css" scoped>
nav ul{
  list-style: none;
  padding: 0;
  border-bottom: 3px solid rgb(227, 70, 150);
}

nav ul li{
  display: inline-block;
  margin-right: 30px;
  border: 1px solid rgb(227, 70, 150);
  font-size: 14px;
  border-radius: 3px;
}

nav ul li a{
  background-color: transparent;
  width: 173px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(227, 70, 150);
  font-weight: bold;
}

</style>
