<template lang="html">
<div>
  <div class="date-range-holder">
    <VueCtkDateTimePicker
      v-model="dateRange"
      color="#483D8B"
      range
      overlay
      hint="Selected:"
      label="Filter by date"
      @input="onDateChange"
      >
    </VueCtkDateTimePicker>
  </div>

  <label class="ml-4">
    <input class="mb-3 pl-5 form-control" id="input" type="text" placeholder="Search by ID" v-model="id" @input="filterById">
    <i class="fa fa-search" aria-hidden="true"></i>
  </label>
  <div v-if="message" :class="error ? 'alert alert-warning' : 'alert alert-info'">
    {{message}}
  </div>
  <div v-if="!errored" id="bills">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Table Number</th>
          <th>Number Of Transactions</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="result in results" class="text-center">
            <td>{{result.id}}</td>
            <td>{{result.created_at}}</td>
            <td>{{result.amount}}</td>
            <td>{{result.table_number}}</td>
            <td>{{result.transactions.length}}</td>
            <td>
              <button v-if="result.transactions.length > 0" class="btn btn-outline-info btn-sm mb-1" @click="showTransactionsModal(result.transactions)">
                View Transactions
              </button>
              <br v-if="result.transactions.length > 0">
              <button v-if="result.products.length >  0" class="btn btn-outline-success btn-sm mb-1" @click="showProductsModal(result.products)">
                View Products
              </button>
              <br v-if="result.products.length > 0">
              <button v-if="result.reviews.length > 0" class="btn btn-outline-primary btn-sm" @click="showReviewsModal">
                View Reviews
              </button>
            </td>
          </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
  </div>

  <Pagination
      @handlePageChange="handlePageChange"
      :maxPage="maxPage"
      :totalItems="totalItems"
  >
    <li slot="body"></li>
  </Pagination>

  <Modal v-if="showModal"
         @close="closeModal"
         :transactions="transactions"
         :products="products"
         :reviews="reviews"
  >
    <ul>
      <li slot="body"></li>
    </ul>
  </Modal>
</div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Modal from './Modals/BillsInfoModal';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "BillsTable",
  components: {
    VueCtkDateTimePicker,
    Modal,
    Pagination
  },

  created() {
    this.loadData();
  },

  data() {
    return {
      results: [],
      dateRange: null,
      errored: false,
      busy: false,
      limit: 30,
      keyword: '',
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      id: null,
      message: null,
      error: false,
      pageSize: null,
      showModal: false,
      transactions: null,
      products: null,
      reviews: null,
      maxPage: 10,
      totalItems: 100,
    }
  },

  methods: {
    loadData() {
      this.busy = true;
      let params = new URLSearchParams();

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      if (this.id !== null) {
        params.append('id', this.id);
      }

      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            params.append('date_to', this.dateRange.end);
          }
        }
      }

      axios.get("backoffice/bills/list",  {params: params})
        .then(res => {
          let data = res.data.data;
          this.results = data.items;
          this.maxPage = data.max_page;
          this.totalItems = data.total_items;
        }).
        catch( (err) => {
          this.message = err.response.data.message;
          this.error = true;
          this.clearMessage();
        });
      this.busy = false;
    },

    filterById() {
      if (isNaN(this.id)) {
        this.message = 'Bill ID must be a number';
        this.error = true;
        this.clearMessage();

        return;
      }
      this.page = 1;

      this.loadData();
    },

    onDateChange() {
      this.page = 1;
      this.loadData();
    },

    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },

    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },

    clearMessage(errorValue = false) {
      setTimeout(() => {
        this.message = null;
        this.error = errorValue;
      }, 3000);
    },

    showTransactionsModal(transactions) {
      this.showModal = true;
      this.transactions = transactions;
      this.products = null;
      this.reviews = null;
    },

    showProductsModal(products) {
      this.showModal = true;
      this.products = products;
      this.transactions = null;
      this.reviews = null;
    },

    showReviewsModal(reviews) {
      this.showModal = true;
      this.reviews = reviews;
      this.transactions = null;
      this.products = null;
    },

    closeModal () {
      this.showModal = false;
      this.reviews = null;
      this.transactions = null;
      this.products = null;
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  },

}
</script>

<style lang="css" scoped>

input{
  height: 44px;
}

table thead tr,
.fa-search
 {
  color: #B4B4B4;
}

table thead tr th{
  border: none;
  text-align: center;
}

table tbody tr td.nps{
  color: #89C58E;
}

table tbody tr td.tblnum{
  font-weight: bold;
}

table tbody tr td{
  padding: 25px 15px!important;
}

table tbody tr:hover{
  cursor: pointer;
}

.filter{
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.filter::-webkit-scrollbar {
  display: none;
}

label{
  position: relative;
}

.fa-search{
  position: absolute;
  left: 8%;
  top: 21%;
}

.date-range-holder{
  width: 40%;
  display: inline-block;
  vertical-align: middle;
}

.text-center{
  text-align: center;
}
</style>
