<template lang="html">
  <div class="">
    <OverviewSubheader />
    <!-- <p>Restaraunt Name</p> -->
  </div>
</template>

<script>
import OverviewSubheader from '../../components/DefaultMainComponents/OverviewSubheader';
export default {
  name: "RestarauntPage",
  components: {
    OverviewSubheader
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
