<template lang="html">
  <nav>
    <ul>
      <li><router-link :to="{ name: 'PaymentsPage', params: {} }">Payments</router-link></li>
      <li><router-link :to="{ name: 'InvoicesPage', params: {} }">Invoices</router-link></li>
      <li><router-link :to="{ name: 'DevicesPage', params: {} }">Devices</router-link></li>
      <li><router-link :to="{ name: 'IntegrationsPage', params: {} }">Integrations</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'AccountManagerPage', params: {} }">Account Manager</router-link></li>
      <!-- <li><router-link :to="{ name: 'AdvancedPage', params: {} }">Advanced</router-link></li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "AccountSubheader",
  created() {
    this.isAdmin = localStorage.getItem('userEmail') == 'developer@tableyeti.com';
  },
  data() {
    return {
      isAdmin: false
    }
  }
}
</script>

<style lang="css" scoped>
nav ul{
  list-style: none;
  padding: 0;
  border-bottom: 3px solid rgb(227, 70, 150);
}

nav ul li{
  display: inline-block;
  margin-right: 30px;
  border: 1px solid rgb(227, 70, 150);
  font-size: 14px;
  border-radius: 3px;
}

nav ul li a{
  background-color: transparent;
  width: 173px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(227, 70, 150);
  font-weight: bold;
}

.router-link-active{
  background-color: #D1CAE4;
}
</style>
