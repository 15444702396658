<template lang="html">
  <div class="inner-colour-container">
    <form @submit.prevent="onSubmit" class="" action="" method="">
      <div class="form-row">
        <div class="form-group col-4">
          <input type="text" name="" placeholder="Subject" v-model="subject" @click="onClick()">
          <p class="alert alert-danger" v-bind:class="{'d-none': isActiveSub }" role="alert">*please enter subject</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-4">
          <label for="selectImagePicker" class="pl-3">
          Image
          <input type="file" value="" id="selectImagePicker" style="display:none" v-on:change="onFileChange"/>
          </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-4">
          <input type="text" name="" placeholder="Message" v-model="message">
          <p class="alert alert-danger" v-bind:class="{'d-none': isActiveMsg }" role="alert">*please enter your message</p>
        </div>
      </div>
      <div class="btnWrapper">
        <button class="btn save">Save</button>
      </div>
    </form>
    <!-- Iphone Holder -->
    <div class="iphone-holder float-right">
      <div class="inner">
        <div class="battery-row">
          <img src="../../../assets/icon-signal.png" alt="" class="left-battery-row">
          <img src="../../../assets/icon-wireless.png" alt="" class="left-battery-row">
          <img src="../../../assets/icon-loading.png" alt="" class="left-battery-row">
          <p class="centar"><span>11:34</span></p>
          <p class="centar"><span>77%</span></p>
          <img src="../../../assets/icon-battery.png" alt="" class="right-battery-row">
        </div>
        <div class="arrow-row">
          <img src="../../../assets/icon-left-arrow-iphone.png" alt="" class="left-arrow">
          <img src="../../../assets/icon-up-arrow-iphone.png" alt="" class="arrow-image">
          <img src="../../../assets/icon-down-arrow-iphone.png" alt="" class="arrow-image">
        </div>
        <div class="text-center">
          <img src="../../../assets/icon-profile-iphone.png" alt="" class="float-right profile-image">
          <p class="p-noreplay">noreplay@tableyeti.com</p>
          <p class="p-details float-right">Details</p>
          <p class="p-mail">To:<span>oliver25687@gmail.com</span></p>
        </div>
        <hr class="my-1 ">
        <!-- Iphone Real Preview -->
        <div v-if="cardInIphoneRealPreview">
          <div class="p-2">
            <img src="../../../assets/icon-point-iphone.png" alt="" class="float-right point-image">
            <p class="p-subject">{{subject}}</p>
            <p class="p-date">13 November 2019 at 20:27</p>
            <hr class="my-2">
            <img :src="image" alt="" class="logo-image">
            <p class="p-message">{{message}}</p>
          </div>
        </div>
        <!-- Iphone onSave Preview -->
        <div v-else="cardInIphone">
          <div class="p-2">
            <img src="../../../assets/icon-point-iphone.png" alt="" class="float-right point-image">
            <p class="p-subject">{{formData.subject}}</p>
            <p class="p-date">13 November 2019 at 20:27</p>
            <hr class="my-2">
            <img :src="formData.image" alt="" class="logo-image">
            <p class="p-message">{{formData.message}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReceiptEmailComponent",
  data () {
    return {
      subject: "",
      image: "",
      message: "",
      formData: {},
      isActiveSub: true,
      isActiveMsg: true,
      cardInIphoneRealPreview: true,
      cardInIphone: false,
    }
  },
  computed: {
    subjectIsValid () {
      return !!this.subject
    },
    messageIsValid () {
      return !!this.message
    }
  },
  methods: {
    onClick () {
      this.isActiveSub = true;
      this.isActiveMsg = true;
      this.cardInIphoneRealPreview = true;
      this.cardInIphone = false;
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
      this.fileName = e.target.files[0].name;
    },
    createImage (file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onSubmit () {
      if (!this.subjectIsValid) {
        this.isActiveSub = false;
      } else if (!this.messageIsValid) {
        this.isActiveMsg = false;
      } else {
        this.formData = {
          subject: this.subject,
          image: this.image,
          message: this.message,
        }
        this.resetFields();
      }
    },
    resetFields () {
      this.subject = "";
      this.image = "";
      this.message = "";
      this.isActiveSub = true;
      this.isActiveMsg = true;
      this.cardInIphone = true;
      this.cardInIphoneRealPreview = false;
    }
  }
}
</script>

<style lang="css" scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -60px;
}

.inner {
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overflow-y: scroll;
}

.inner::-webkit-scrollbar {
  display: none;
}

label,
input {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 65px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
  letter-spacing: 0.3px;
}

label.pl-3 {
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0.3px;
}

form {
  display: inline-block;
  width: 69%;
  margin-top: 10px;
  margin-bottom: 15px;
}

input:focus,
input.visited {
  color: #202020;
}

button.save {
  padding: 13px 55px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
}

.btnWrapper {
  width: 51%;
}

div.battery-row {
  background-color: #f7f7f7;
  height: 30px;
}

div.arrow-row {
  background-color: #f7f7f7;
  float: left;
  height: auto;
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
}

img.left-arrow {
  height: 1.4em;
  width: 1.4em;
  display:inline-block;
  margin-left: 5px;
}

img.arrow-image {
  filter: opacity(30%);
  height: 1.6em;
  width: 1.6em;
  display: block;
  margin-right: 18px;
  float: right;
}

div.battery-row {
  background-color: #f7f7f7;
  float: left;
  height: 25px;
  width: 100%;
  padding:  0 5px;
}

img.left-battery-row {
  height: .9em;
  width: .9em;
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}

img.right-battery-row {
  height: 1.3em;
  width: 1.5em;
  display: block;
  margin-top: 2px;
  float: right;
}

p.centar {
  display: inline-block;
  width: 35%;
  text-align: end;
  font-size: 12px;
}

img.profile-image {
  height: 2em;
  width: 2em;
  margin-right: 10px;
}

img.point-image {
  height: .7em;
  width: .7em;
  margin-right: 10px;
  margin-top: 5px;
}

img.logo-image {
  height: 10em;
  width: 18em;
  padding: 10px 10px 10px 6px;
}

p.p-subject,
p.p-noreplay,
p.p-mail {
  padding: 0px 0px 0px 6px;
  word-break: break-word;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-bottom: 0px;
}

p.p-noreplay {
  padding-right: 92px;
}

p.p-mail {
  padding-right: 88px;
  margin-bottom: 10px;
}

p.p-details {
  color: #0645AD;
  margin-right: 5px;
  font-size: 14px;
}

p.p-date,
p.p-mail span {
  color: rgb(145, 143, 143);
  padding: 0px 10px 0px 6px;
  word-break: break-word;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
}

p.p-message {
  padding: 5px 10px 10px 6px;
  word-break: break-word;
  font-size: 15px;
  line-height: 18px;
}

.alert-danger {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px;
}
</style>
