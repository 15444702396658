<template>
  <div id="app">
    <!-- <Header v-if="authenticated"/> -->
    <!-- <router-view @authenticated="setAuthenticated" /> -->
    <template v-if="authenticated">
      <p class="greet-user">
        Hello, {{ user.data.user.name }} - {{ user.data.user.email }}
      </p>
      <li v-on:click.prevent="signOut" class="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          fill="#3C038C"
          width="50px"
          height="50px"
          class="icon"
        >
          <g>
            <path
              d="M510.371,226.513c-1.088-2.603-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.32-21.824-8.32-30.165,0     c-8.341,8.341-8.341,21.845,0,30.165l27.584,27.584H320.013c-11.797,0-21.333,9.557-21.333,21.333s9.536,21.333,21.333,21.333     h119.168l-27.584,27.584c-8.341,8.341-8.341,21.845,0,30.165c4.16,4.181,9.621,6.251,15.083,6.251s10.923-2.069,15.083-6.251     l63.979-63.979c1.984-1.963,3.541-4.331,4.629-6.955C512.525,237.606,512.525,231.718,510.371,226.513z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
            />
            <path
              d="M362.68,298.667c-11.797,0-21.333,9.557-21.333,21.333v106.667h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437     l-74.091-22.229h174.635v106.667c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333v-128     C384.013,9.557,374.477,0,362.68,0H21.347c-0.768,0-1.451,0.32-2.197,0.405c-1.003,0.107-1.92,0.277-2.88,0.512     c-2.24,0.576-4.267,1.451-6.165,2.645c-0.469,0.299-1.045,0.32-1.493,0.661C8.44,4.352,8.376,4.587,8.205,4.715     C5.88,6.549,3.939,8.789,2.531,11.456c-0.299,0.576-0.363,1.195-0.597,1.792c-0.683,1.621-1.429,3.2-1.685,4.992     c-0.107,0.64,0.085,1.237,0.064,1.856c-0.021,0.427-0.299,0.811-0.299,1.237V448c0,10.176,7.189,18.923,17.152,20.907     l213.333,42.667c1.387,0.299,2.795,0.427,4.181,0.427c4.885,0,9.685-1.685,13.525-4.843c4.928-4.053,7.808-10.091,7.808-16.491     v-21.333H362.68c11.797,0,21.333-9.557,21.333-21.333V320C384.013,308.224,374.477,298.667,362.68,298.667z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
            />
          </g>
        </svg>
      </li>
      <Header />
    </template>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./components/DefaultMainComponents/Header";
import Login from "./views/Login";

export default {
  name: "App",
  components: {
    Header,
    Login
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    })
  },
  beforeDestroy() {
    Vue.prototype.ldClient.close();
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut"
    }),

    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "Login"
        });
      });
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
#app {
  font-family: "Lato", sans-serif;
  position: relative;
  width: 1200px;
  margin: auto;
}

.greet-user {
  color: #e34696;
  text-align: right;
  margin-top: 5px;
}

.logout {
  float: right;
  margin-top: 18px;
  margin-left: -45px;
  list-style: none;
  cursor: pointer;
  padding: 0 20px;
}

.icon:hover {
  fill: #e34696;
}
</style>
