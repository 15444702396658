import { render, staticRenderFns } from "./NoServiceComponent.vue?vue&type=template&id=1b6d463d&scoped=true&v-if=serviceAmount%20%3D%3D%3D%20'No%20Service%20pop-up'&"
import script from "./NoServiceComponent.vue?vue&type=script&lang=js&"
export * from "./NoServiceComponent.vue?vue&type=script&lang=js&"
import style0 from "./NoServiceComponent.vue?vue&type=style&index=0&id=1b6d463d&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b6d463d",
  null
  
)

export default component.exports