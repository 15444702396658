<template lang="html">
  <div class="">
    <ServiceSubheader />
    <p>Reports</p>
    <DateRangeReportsComponent />
  </div>
</template>

<script>
import ServiceSubheader from '../../components/ServiceComponents/ServiceSubheader';
import DateRangeReportsComponent from '../../components/ServiceComponents/DateRangeReportsComponent';
export default {
  name: "DateRangeReportsPage",
  components: {
    ServiceSubheader,
    DateRangeReportsComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
