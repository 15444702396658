<template lang="html">
  <nav>
    <ul>
<!--      <li><router-link :to="{ name: 'FeedbackManagerPage', params: {} }">Feedback Manager</router-link></li>-->
      <li><router-link :to="{ name: 'NetPromoterPage', params: {} }">Net Promoter Score</router-link></li>
      <li><router-link :to="{ name: 'TeamMembersNPSPage', params: {} }">Team Members</router-link></li>
<!--      <li><router-link :to="{ name: 'MenuPage', params: {} }">Menu</router-link></li>-->
<!--      <li><router-link :to="{ name: 'SetExperimentPage', params: {} }">Set Experiment</router-link></li>-->
      <!-- <li><router-link :to="{ name: 'EmailReportsPage', params: {} }">Email Reports</router-link></li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "FeedbackSubheader"
}
</script>

<style lang="css" scoped>
nav ul{
  list-style: none;
  padding: 0;
  border-bottom: 3px solid rgb(227, 70, 150);
}

nav ul li{
  display: inline-block;
  margin-right: 30px;
  border: 1px solid rgb(227, 70, 150);
  font-size: 14px;
  border-radius: 3px;
}

nav ul li a{
  background-color: transparent;
  width: 173px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(227, 70, 150);
  font-weight: bold;
}

.router-link-active{
  background-color: #D1CAE4;
}
</style>
