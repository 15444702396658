<template lang="html">
<div>
  <!-- Date picker -->
  <div class="date-range-holder">
    <VueCtkDateTimePicker
      v-model="dateRange"
      color="#483D8B"
      buttonColor="#e34696"
      range
      overlay
      format=YYYY-MM-DD
      formatted=ll
      hint="Selected:"
      label="Filter by date"
      @validate="loadData">
    </VueCtkDateTimePicker>
  </div>
  <!-- Download Z-Reports button -->
  <button
    type="button"
    class="btn ml-4 download"
    @click="onCSVExport"><span v-if="csvLoad" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Download Report</button>
  <!-- Z Reports table -->
  <div v-if="!errored" id="zreports" class="zreports-table mt-4">
    <table class="table table-hover">
      <thead>
        <tr class="text-body">
          <th>Date</th>
          <th>
            Visa / Mastercard / Other
            <div class="tooltipa">
              i
              <span class="tooltiptext cards">
                Total spent within Z Report on Visa, Mastercard and other cards like
                Maestro and Union Pay. This includes service charge spent
                on card, additional service charge spent on card, and money spent on widgets and banners on card.
              </span>
            </div>
          </th>
          <th>
            American Express
            <div class="tooltipa">
              i
              <span class="tooltiptext exp">
                Total spent within Z Report on American Expresses. This includes service charge spent
                on card, additional service charge spent on card, and money spent on widgets and banners on card.
              </span>
            </div>
          </th>
          <th>
            Card Service
            <div class="tooltipa">
              i
              <span class="tooltiptext">
               This is the amount of service charge paid by clients. This is read from your ePOS to our devices.
              </span>
            </div>
          </th>
          <th>
            Cash Service
            <div class="tooltipa">
              i
              <span class="tooltiptext">
                We no longer support reporting of this metric
              </span>
            </div>
          </th>
          <th>
            Additional Gratuity
            <div class="tooltipa yeti">
              i
              <span class="tooltiptext">
                This is the amount of monies collected on card as tips added by guests during payment.
              </span>
            </div>
          </th>
          <th>
            Widget Spend
            <div class="tooltipa">
              i
              <span class="tooltiptext">
                This is the amount of money spend on card on your widget screen (the last screen of the payment process).
              </span>
            </div>
          </th>
          <th class="text-center">Card Total <div class="tooltipa ">  i<span class="tooltiptext">Total spent today on Visa, Mastercard and other cards like Maestro and Union Pay. This includes service charge spent on card, and money spent on widgets and banners on card</span></div> </th>
        </tr>
      </thead>
      <tbody v-if="listLoad">
        <tr>Loading...</tr>
      </tbody>
      <tbody v-else-if="!listLoad && results.length > 0">
        <tr v-for="result in results" :key="result.id" class="text-dark">
          <td>{{ result.modifedDate }}</td>
          <td>{{ result.report_data.cardTotal | currency }}</td>
          <td>{{ result.report_data.amexTotal | currency }}</td>
          <td>{{ result.report_data.serviceChargeCardTotal | service }}</td>
          <td>{{ result.report_data.serviceChargeCashTotal | service }}</td>
          <td>{{ result.report_data.totalAdditionalServiceCharge | service }}</td>
          <td>{{ result.report_data.voucherTotal }}</td>
          <td><strong>&#163; {{ result.card_total | currency }}</strong></td>
        </tr>
      </tbody>
      <tbody v-else >
        <tr>
          <td colspan="12" class="text-center alert-info empty-data">
            Nothing to show...
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Info if error -->
  <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
  </div>

  <Pagination
      @handlePageChange="handlePageChange"
      :maxPage="maxPage"
      :totalItems="totalItems"
  >
    <li slot="body"></li>
  </Pagination>
  </div>
</template>

<script>
import axios from 'axios';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "ZreportsTable",
   components: {
     VueCtkDateTimePicker,
     Pagination
   },
  data(){
    return {
      results: [],
      dateRange: null,
      date_from: '',
      date_to: '',
      csvLoad: false,
      listLoad: true,
      errored: false,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      maxPage: 10,
      totalItems: 100,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    created() {
      this.loadData();
    },
    loadData () {
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.date_from = this.dateRange.start;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            this.date_to = this.dateRange.end;
            params.append('date_to', this.dateRange.end);
          }
        }
      }
      // Append page param
      if (this.page !== null) {
        params.append('page', this.page);
      }
      // Append perPage param
      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }
      // Api request for zreports list
      axios.get('backoffice/daily-reports/list', {params: params})
        .then(response => {
          let NO_DATA = '/';
          let data = response.data.data;
          this.maxPage = data.max_page;
          this.totalItems = data.total_items;
          let tmpData = data.items;
          let flatMappedReports = [];

          if (tmpData !== null) {
            tmpData.forEach(report => {
              let tmpReport = JSON.parse(JSON.stringify(report));
              // Modified date output
              const fullDate = report.created_at.date;
              const modifedDate = fullDate.split(' ')[0];
              tmpReport.modifedDate = modifedDate;

              if (tmpReport.report_data !== null) {
                flatMappedReports.push(tmpReport)
              } else {
                // Modified data output when report_data is null
                let empty_data = {
                  cardTotal: NO_DATA ,
                  amexTotal: NO_DATA ,
                  serviceChargeCardTotal: NO_DATA ,
                  serviceChargeCashTotal: NO_DATA ,
                  totalAdditionalServiceCharge: NO_DATA ,
                  voucherTotal: NO_DATA ,
                  yetiTipTotal: NO_DATA,
                  modifedDate: modifedDate,
                  card_total: tmpReport.card_total
                }
                tmpReport.report_data = empty_data;
                flatMappedReports.push(tmpReport);
              }
            });
          this.results = flatMappedReports;
        }
        this.results = flatMappedReports;
      })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          setTimeout(() => {
            this.listLoad = false
          }, 300)
        })
    },
    nextPage () {
      if (this.results.length === this.perPage) {
        this.page = this.page + 1;
      }
      this.loadData();
    },
    previousPage () {
      if (this.page !== 1) {
        this.page = this.page - 1;
      }
      this.loadData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.loadData();
    },
    // Api request for CSV data
    onCSVExport () {
      let params = new URLSearchParams();
      // Append date params
      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          this.date_from = this.dateRange.start;
          params.append('date_from', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            this.date_to = this.dateRange.end;
            params.append('date_to', this.dateRange.end);
          }
        }
      }
      const config = {headers: {'Content-Type': "text/csv; charset=UTF-8"}};
      axios.get('backoffice/daily-reports/csv', {params: params}, config)
        .then(response => {
          const csvData = response.data;
          if (response.status === 200 && response.data !== null) {
            this.csvLoad = true;
            let blob = new Blob([csvData], {type: 'text/csv'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if ((this.date_from !== null && this.date_from !== '' && this.date_from !== undefined)
               || (this.date_to !== null && this.date_to !== '' && this.date_to !== undefined)) {
              link.download = `zreports(${this.date_from}-${this.date_to}).csv`;
            } else {
              link.download = `zreports-all.csv`;
            }
            link.click();
            setTimeout(() => {
              this.csvLoad = false;
            }, 1000);
          }
        })
        .catch(error => console.log(error));
    },
    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  },
}
</script>

<style lang="css" scoped>
.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

button.download {
  padding: 8px 20px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

button.download:hover {
  color: white;
}

.tooltipa{
  height: 19px;
  width: 19px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #D25793;
  color: #D25793;
  position: absolute;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 15px;
  z-index: 2;
}

.yeti{
  right: -2px;
}

.tooltiptext{
  visibility: hidden;
  width: 400px;
  padding: 10px;
  padding-top: 15px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D25793;
  border-radius: 2px;
  bottom: 100%;
  right: 100%;
  font-size: 14px;
}

.cards,
.exp{
  left: 100%;
}

.tooltipa:hover .tooltiptext {
  visibility: visible;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  position: relative;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

table tbody tr td.empty-data {
  height: 110px;
  padding-top: 40px;
}

select{
  height: 38px;
  width: 50px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: #483D8B;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #483D8B;
  transition: background-color .3s;
}

.pagination a.current {
  background-color: white;
  color: #483D8B;
}

.pagination a:hover:not(.active) {
  color: #483D8B;
  background-color: #ddd;
}

.pagination a:active,
.pagination a:focus,
select:active,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
</style>
