import axios from "axios";
import Vue from "vue";

import { context, propagation } from "@opentelemetry/api";
export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    isAdmin: null
  },

  getters: {
    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {

      var userAtttributes = {
        key: data.data.user.email,
        name: data.data.user.name,
        email: data.data.user.email,
        id: data.data.user.id
      };

      const baggage =
        propagation.getActiveBaggage() || propagation.createBaggage();

      baggage.setEntry("test_myservice", true);
      const newContext = propagation.setBaggage(context.active(), baggage);

      // confirm that the new context contains the created baggage
      console.log("saving baggage", propagation.getBaggage(newContext));

      context.with(newContext, () => {
        // with the new context activated, the attached baggage is active and defined
        console.log(propagation.getActiveBaggage());
      });

      Vue.prototype.ldClient.identify(userAtttributes);

      Vue.prototype.$posthog.identify(userAtttributes.key, userAtttributes);

      state.user = data;
    }
  },

  actions: {
    async signIn({ dispatch }, credentials) {
      let response = await axios.post("backoffice/login", credentials);
      return dispatch("attempt", response.data.data.token);
      // console.log(response.data.data.token);
    },

    async attempt({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }

      if (!state.token) {
        return;
      }
      // console.log(token);

      try {
        let response = await axios.get("backoffice/authuser");
        // , {
        //   headers: {
        //     'Authorization' : 'Bearer ' + token
        //   }
        // }
        commit("SET_USER", response.data);
        localStorage.setItem("userEmail", response.data.data.user.email);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        Vue.prototype.$posthog.reset();
      }
    },

    signOut({ commit }) {
      return axios.get("backoffice/logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        Vue.prototype.$posthog.reset();
      });
    }
  }
};
