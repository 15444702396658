<template>
  <div>
    <FeedbackSubheader />
    <p>Feedback</p>
</div>
</template>

<script>
import FeedbackSubheader from '../components/FeedbackComponents/FeedbackSubheader';


export default {
  name: 'Feedback',
  components: {
    FeedbackSubheader
  }
}
</script>

<style scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
