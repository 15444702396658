<template lang="html">
  <div class="">
    <FeedbackSubheader />
    <p>Experiments</p>
    <ExperimentComponent />
  </div>
</template>

<script>
import FeedbackSubheader from '../../components/FeedbackComponents/FeedbackSubheader';
import ExperimentComponent from '../../components/FeedbackComponents/ExperimentComponent';
export default {
  name: "SetExperimentPage",
  components: {
    FeedbackSubheader,
    ExperimentComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
