<template>
  <div>
    <select name="" id=""
      class="mr-1 mb-3"
      v-model="serviceAmount"
      @change="onChangeAmount"
      @click="optionColorChanged = false"
      :class="{optColor: optionColorChanged}"
      id="select">
      <option
        v-for="amountOption in amountOptions"
        :value="amountOption">
        {{ amountOption }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectInputComponent",
  props: ['serviceAmount'],
  data() {
    return {
      amountOptions: ['Select Service amount', 'Additional Tip pop-up', 'No Service pop-up'],
      optionColorChanged: false,
    }
  },
  methods: {
    onChangeAmount (event) {
      this.serviceAmount = event.target.value;
      this.$emit('clickChange', this.serviceAmount);
    }
  }
}
</script>

<style lang="css" scoped>
select{
  padding: 20px 150px 20px 15px;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #BCBBBC;
  outline: none;
  border-radius: 7px;
  color:#BCBBBC;
}

select:focus{
  color: #202020;
}

.optColor {
  color:#202020
}
</style>
