import posthog from "posthog-js";

export default {
  install(Vue) {
    Vue.prototype.$posthog = posthog.init(
      process.env.VUE_APP_POSTHOG_KEY,
      {
        api_host: "https://eu.posthog.com",
        capture_pageview: false
      }
    );
  }
};