<template lang="html">
  <div class="">
    <MarketingSubheader />
    <p>Welcome Screen</p>
    <WelcomeScreenComponent />
  </div>
</template>

<script>
import MarketingSubheader from '../../components/MarketingComponents/MarketingSubheader';
import WelcomeScreenComponent from '../../components/MarketingComponents/WelcomeHomeComponents/WelcomeScreenComponent';
export default {
  name: "WelcomePage",
  components: {
    MarketingSubheader,
    WelcomeScreenComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
