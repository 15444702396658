<template>
  <div class="text-center pt-5 pb-4 error-holder">
    <p class="error-header">Oops, this is embarrassing</p>
    <p>Something went wrong while loading your data. Please try again later.</p>
    <img class="img img-responsive" src="../../assets/error-yeti.png">
  </div>
</template>

<script>
export default {
  name: "ErrorComponent"
}
</script>

<style scoped>
.error-holder{
  color: #3c038c;
}

.error-header{
  font-size: 36px;
  font-weight: 700;
}

.img {
  max-width: 150px;
}
</style>