<template v-if="fileType === 'Image'">
  <div class="inner-colour-container">
    <form @submit.prevent="onSubmit($event)" action="" method="">
      <label for="imagePicker" class="pl-3" id="image">
        Select File
        <input type="file" name="image" value="" id="imagePicker" style="display:none" v-on:change="onHandleImage" />
      </label>
      <button type="submit" name="button" class="btn ml-4 save-color">Save</button>

      <img v-if="loading" src="../../../assets/spinner.svg" class="img-svg" alt="">
      <div v-if="success" class="success-wrapper">
        <p class="success-text">{{success}}</p>
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      </div>

      <template v-if="remove">
        <button type="button" name="button" v-on:click="removeFile" class="btn btn-sm ml-2 remove text-white">Remove</button>
      </template>
    </form>

    <!-- Iphone Holder -->
    <div class="iphone-holder float-right">
      <div v-if="cardInIphoneRealPreview">
        <template v-if="this.image">
          <div class="inner-upload" :style="{backgroundImage:`url(${this.image})`}"></div>
        </template>
        <template v-if="this.image === null || imageValidationErrors">
          <div class="inner"></div>
        </template>
      </div>
      <div v-if="cardInIphone">
        <template v-for="loadFile in loadFiles">
          <template v-if="loadFile.image === '' || loadFile.image === null">
            <div class="inner"></div>
          </template>
          <template v-else>
            <div class="inner-upload" :style="{backgroundImage:'url(' + loadFile.image + ')'}"></div>
          </template>
        </template>
      </div>

      <div class="bottom">
        <button type="button" name="button" class="mb-2 primary" disabled>Get Started - Pay Bill </button>
        <p>Powered by Table Yeti <img src="../../../assets/tableYeti-logo.png" alt=""> </p>
      </div>
    </div>

    <p v-if="imageValidationErrors" class="alert-danger">{{ imageValidationErrors }}</p>
    <p v-if="backendErrors" class="alert-danger" v-for="(error, index) in backendErrors"><span :key="index">{{error}}</span></p>
    <p class="file-name">{{this.fileName}}</p>
  </div>
</template>

<script>
import axios from 'axios'
import welcome from '../../../store/modules/welcome'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: "ImageComponent",
  props: ['fileType', 'loadFiles'],
  data() {
    return {
      image: '',
      fileName: '',
      remove: false,
      cardInIphone: true,
      cardInIphoneRealPreview: false,
    }
  },
  computed: {
    imageValidationErrors() {
      return welcome.state.imageValidationErrors;
    },
    backendErrors() {
      return welcome.state.backendErrors;
    },
    success() {
      return welcome.state.success;
    },
    loading() {
      return welcome.state.loading;
    },
  },
  methods: {
    ...mapActions([
      'addWelcomeImage',
      'handleWelcomeImage',
    ]),
    ...mapMutations([
      'SET_WELCOME_IMAGE',
      'IMAGE_ERROR_MESSAGE',
      'CLEAR_IMAGE_ERROR_MESSAGE',
      'CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE',
    ]),
    onHandleImage(e) {
      this.imageValidationErrors = ''
      this.$store.commit('CLEAR_IMAGE_ERROR_MESSAGE')
      this.$store.commit('CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE')
      let files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0]
      this.createImage(files[0]);
      this.file = file;
      this.$store.dispatch('handleWelcomeImage', this.file)

      this.cardInIphone = false;
      this.cardInIphoneRealPreview = true;

      this.fileName = file.name;
      this.remove = true;
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onSubmit (e) {
      if ((this.imageValidationErrors === null || this.imageValidationErrors === '')
        && (this.file !== null && this.file !== undefined)
        ) {
        this.$store.dispatch('addWelcomeImage', this.file)
        this.remove = false;
      } else {
        welcome.state.imageValidationErrors = 'Please first select an image'
        this.imageValidationErrors = welcome.state.imageValidationErrors
        this.fileName = '';
      }
    },
    removeFile: function () {
      this.$store.commit('CLEAR_IMAGE_ERROR_MESSAGE')
      this.$store.commit('CLEAR_PREVIEW_IMAGE_ERROR_MESSAGE')

      this.image = null;
      this.file = null;
      this.fileName = '';
      this.remove = false;
    },
  }
}
</script>

<style lang="css" scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -210px;
}

.inner,
.inner-upload {
  background: white;
  background-image: url("../../../assets/tableYeti-logo.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 62px;
  background-size: 27em;
  z-index: 2;
  position: absolute;
  width: 81%;
  left: 35px;
  top: 92px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inner-upload {
  background-size: cover;
  background-position: center;
}

.bottom {
  height: 100px;
  background: rgba(80, 55, 55, 0.5);
  position: absolute;
  bottom: 87px;
  left: 34px;
  width: 81.6%;
  z-index: 3;
}

.bottom p {
  color: #BFC0C0;
  font-size: 13px;
  float: right;
  margin-right: 2%;
  font-weight: bold;
}

.bottom img {
  height: 1.5em;
}

.bottom button {
  background: #D15895;
  border-radius: 20px;
  border: none;
  width: 90%;
  margin-left: 5%;
  margin-top: 8%;
  padding: 9px;
  color: #FFFFFF;
  font-weight: bold;
}

button.save-color {
  padding: 15px 60px;
  background: #E85F9A;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
}

.optColor {
  color:#202020
}
.img-svg {
  width: 10%;
  margin-left: 10px;
}

form {
  display: inline-block;
  width: 50%;
}

label,
input {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 60px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

label.pl-3 {
  margin-bottom: 0px;
  font-size: 15px;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
}

input:focus,
input.visited,
select:focus {
  color: #202020;
}

.remove {
  background: #E85F9A;
}

.success-wrapper {
  display: inline-block;
  margin-left: 20px;
  animation: end .5s ease-in-out;
}

.success-wrapper {
  opacity: 1;
  transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
}

.success-text {
  display: inline;
  color: #7ac142;
  margin-right: 5px;
}

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 10px;
  stroke-width: 2;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.file-name {
  padding: 0px;
  margin: 5px;
  color: #3C038C;
  font-weight: 600;
}

.alert-danger {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 5px;
}
</style>
