<template lang="html">
  <div id="main" >
    <!-- <template v-if="authenticated"> -->
      <!-- <Overview /> -->
    <!-- </template> -->
    <!-- <template v-else> -->
      <div id="login">
        <div id="description">
          <h1>Login</h1>
          <p>By logging in you agree to the ridiculously long terms and conditions that you didn't bother to read.</p>
        </div>
        <div id="form">
          <form @submit.prevent="doLogin">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="form.email" placeholder="me@example.com" autocomplete="off">

            <label for="password" class="mr-2">Password</label>
            <i class="fas" :class="[passwordIcon]" @click="hidePassword = !hidePassword"></i>
            <input :type="passwordType" id="password" v-model="form.password" placeholder="**********">

            <button type="submit">Log in</button>
            <div class="alert">
              {{alertText}}
            </div>
          </form>
        </div>
      </div>
    <!-- </template> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import Overview from './Overview';

export default {
  name: "Login",
  components: {
    // Overview
  },
  data(){
    return {
    form:{
      email: '',
      password: '',
    },
    hidePassword: true,
    alertText: ""
  }
  },
  computed: {
    passwordType() {
      return this.hidePassword ? 'password' : 'text'
    },
    passwordIcon() {
      return this.hidePassword ? 'fa-eye' : 'fa-eye-slash'
    },
    // ...mapGetters({
    //   authenticated: 'auth/authenticated',
    //   user: 'auth/user',
    // })
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),

   doLogin() {
      this.signIn(this.form).then(() => {
        this.$router.replace({
          name: 'Overview'
        })
      }).catch(() => {
        // console.log("snema internet ili zgresi pasvordot/userot");
      })

      if (this.form.email == "") {
        this.alertText = " Enter your email"
      }
      else if (this.form.password == "") {
        this.alertText = " Enter your password"
      }

    },


  }

}
</script>

<style lang="css" scoped>

div#main {
  width: 100%;
  height: 100vh;
}

div#main div#login {
  align-items: center;
  background-color: #e2e2e5;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

div#main div#login div#description {
  background-color: #ffffff;
  width: 280px;
  padding: 35px;
}

div#main div#login div#description h1,
div#main div#login div#description p {
  margin: 0;
}

div#main div#login div#description p {
  font-size: 0.8em;
  color: #95a5a6;
  margin-top: 10px;
}

div#main div#login div#form {
  background-color: rgb(60, 3, 140);
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px #666;
  color: #ecf0f1;
  width: 260px;
  padding: 35px;
}

div#main div#login div#form label,
div#main div#login div#form input {
  outline: none;
  width: 100%;
  display: inline;
}

div#main div#login div#form label {
  color: #95a5a6;
  font-size: 0.8em;
}

div#main div#login div#form input {
  background-color: transparent;
  border: none;
  color: #ecf0f1;
  font-size: 1em;
  margin-bottom: 20px;
}

div#main div#login div#form ::placeholder {
  color: #ecf0f1;
  opacity: 1;
}

div#main div#login div#form button {
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  padding: 10px;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}

div#main div#login div#form button:hover {
  background-color: #eeeeee;
}

@media screen and (max-width: 600px) {
  div#main div#login {
    align-items: unset;
    background-color: unset;
    display: unset;
    justify-content: unset;
  }

  div#main div#login div#description {
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
  }

  div#main div#login div#form {
    border-radius: unset;
    box-shadow: unset;
    width: 100%;
  }

  div#main div#login div#form form {
    margin: 0 auto;
    max-width: 280px;
    width: 100%;
  }
}
</style>
