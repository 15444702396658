import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import auth from './auth'
import welcome from './modules/welcome'
import npsEmails from './modules/npsEmails'
import widgets from './modules/widgets'
import colours from './modules/colours'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },

  getters: {

  },

  actions: {

  },

  mutations: {

  },

  modules: {
    auth,
    welcome,
    npsEmails,
    widgets,
    colours
  }
})
