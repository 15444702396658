<template>
  <div>
    <ServiceSubheader />
    <p>Service Charge</p>
</div>
</template>

<script>
import ServiceSubheader from '../components/ServiceComponents/ServiceSubheader';


export default {
  name: 'Service',
  components: {
    ServiceSubheader
  }
}
</script>

<style scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
