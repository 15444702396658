<template lang="html">
  <div class="">
    <AccountSubheader />
    <p>Invoices</p>
    <InvoicesComponent />
  </div>
</template>

<script>
import AccountSubheader from '../../components/AccountComponents/AccountSubheader';
import InvoicesComponent from '../../components/AccountComponents/InvoicesComponent';
export default {
  name: "InvoicesPage",
  components: {
    AccountSubheader,
    InvoicesComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
