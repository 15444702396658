import axios from 'axios'

const state = {
  loadEmails: [],
  onLoadErrors: '',
  emailValidationErrors: '',
  errors: null,
  successEmailMessage: '',
};

const mutations = {
  LOAD_ALL_NPS_EMAILS: (state) => {
    axios.get('backoffice/get-emails')
      .then(res => {
        if (res !== null && res !== undefined) {
          if (res.data.data !== null && res.data.data.length > 0) {
            res.data.data.map(email => email.edit = false)
            state.loadEmails = res.data.data
          } else if (res.data.data !== null && res.data.data.length === 0) {
            state.onLoadErrors = 'No emails yet'
            state.loadEmails = []
          } else {
            state.loadEmails = []
          }
      } else {
        state.loadEmails = []
      };
    })
    .catch(error => {
      // state.errors = error
      if (error) {
        state.onLoadErrors = 'Something went wrong with displaying the email addresses. Please try again later.'
      }
    })
  },
  PREVIEW_EMAIL_ERROR_MESSAGE: (state, error) => {
    return state.emailValidationErrors = error
  },
  CLEAR_PREVIEW_EMAIL_ERROR_MESSAGE: (state) => {
    return state.emailValidationErrors = '';
  },
  EMAIL_ERROR_MESSAGE: (state, error) => {
    let errors = []
    if (error) {
      errors.push(error.email)
      return state.errors = errors[0]
    }
  },
  CLEAR_EMAIL_ERROR_MESSAGE: (state) => {
    return state.errors = null;
  },
  CLEAR_LOAD_ERROR_MESSAGE: (state) => {
    return state.onLoadErrors = '';
  },
  SUCCESS_MESSAGE: (state, data) => {
    return state.successEmailMessage = data;
  },
  CLEAR_SUCCESS_MESSAGE: (state, data) => {
    return state.successEmailMessage = '';
  }
};

const actions = {
  addNpsEmails: ({ commit }, payload) => {
    const config = {
      headers: {'Content-Type': 'aplication/json'}
    }
    axios.post('backoffice/save-email-for-report', {email: payload}, config)
      .then(res => {
        if (res) {
          commit('SUCCESS_MESSAGE', res.data.message)
          if (res.data.message) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
            }, 2500);
          }
          commit('LOAD_ALL_NPS_EMAILS', res.data.data)
        };
      })
      .catch(error => {
        commit('EMAIL_ERROR_MESSAGE', error.response.data);
        if (error.response.data.email) {
          setTimeout(() => {
            commit('CLEAR_EMAIL_ERROR_MESSAGE')
          }, 2500);
        }
        commit('LOAD_ALL_NPS_EMAILS', state.loadEmails);
      })
  },
  deleteEmail: ({ commit }, payload) => {
    axios.post(`backoffice/delete-email/${payload.id}`)
      .then(res => {
        if (res) {
          commit('SUCCESS_MESSAGE', 'Email successfully deleted!')
          if (res.data.message) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
            }, 2500);
          }
          commit('LOAD_ALL_NPS_EMAILS', res.data.data)
        };
      })
      .catch(error => {
        commit('EMAIL_ERROR_MESSAGE', error.response.data);
        if (error.response.data.email) {
          setTimeout(() => {
            commit('CLEAR_EMAIL_ERROR_MESSAGE')
          }, 2500);
        }
        commit('LOAD_ALL_NPS_EMAILS', state.loadEmails);
      })
  },
  updateEmail: ({ commit }, payload) => {
    const email = payload.email;
    axios.post(`backoffice/update-email-for-report/${payload.id}`, {
      email: payload.email,
      repetition: payload.repetition
    })
      .then(res => {
        if(res) {
          commit('SUCCESS_MESSAGE', res.data.message)
          if (res.data.message) {
            setTimeout(() => {
              commit('CLEAR_SUCCESS_MESSAGE')
            }, 2500);
          }
          commit('LOAD_ALL_NPS_EMAILS', res.data.data);
        };
      })
      .catch(error => {
        commit('EMAIL_ERROR_MESSAGE', error.response.data);
        if (error.response.data.email) {
          setTimeout(() => {
            commit('CLEAR_EMAIL_ERROR_MESSAGE')
          }, 2500);
        }
        commit('LOAD_ALL_NPS_EMAILS', state.loadEmails);
      })
  },
};

const getters = {
  loadEmails: state => state.loadEmails,
  onLoadErrors: state => state.onLoadErrors,
  emailValidationErrors: state => state.emailValidationErrors,
  errors: state => state.errors,
  successEmailMessage: state => state.successEmailMessage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
