<template lang="html">
  <div class="">
    <AccountSubheader />
    <p>Payments</p>
    <PaymentsComponent />
  </div>
</template>

<script>
import AccountSubheader from '../../components/AccountComponents/AccountSubheader';
import PaymentsComponent from '../../components/AccountComponents/PaymentsComponent';
export default {
  name: "PaymentsPage",
  components: {
    AccountSubheader,
    PaymentsComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
