<template lang="html">
  <div class="">
    <FeedbackSubheader />
    <p class="mb-2">Net Promoter Score</p>
    <NetPromoterScoreComponent />
  </div>
</template>

<script>
import FeedbackSubheader from '../../components/FeedbackComponents/FeedbackSubheader';
import NetPromoterScoreComponent from '../../components/FeedbackComponents/NetPromoterScoreComponent';
export default {
  name: "NetPromoterPage",
  components: {
    FeedbackSubheader,
    NetPromoterScoreComponent
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
