<template lang="html">
  <div class="">
    <SalesSubheader />
    <b-breadcrumb :items="items"></b-breadcrumb>
    <p class="page-header">
      Yeti Activity Feed
    </p>
    <p class="page-info">
      This page contains a live feed of activity from across your Yeti fleet.
      For a detailed breakdown of processed transactions, refer to <router-link :to="{ name: 'SettlementPage', params: {} }">settlements</router-link>
    .
    </p>
    <TransactionsTable />
  </div>
</template>

<script>
import SalesSubheader from '../../components/SalesComponents/SalesSubheader';
import TransactionsTable from '../../components/SalesComponents/TransactionsTable';
import {BootstrapVue, BBreadcrumb} from 'bootstrap-vue'

export default {
  name: "TransactionsPage",
  components: {
    SalesSubheader,
    TransactionsTable,
    BootstrapVue,
    BBreadcrumb
  },
  data() {
    return {
      items: [
        {
          text: "Sales",
          href: "/sales/bills"
        },
        {
          text: "Activity Feed",
          href: ""
        }
      ]
    };
  }
}
</script>

<style lang="css" scoped>
div p.page-header{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
.breadcrumb{
  background: white;
  margin-left: -15px;
}
.page-info{
  margin-bottom: 30px;
}
</style>
