import { render, staticRenderFns } from "./EditInstagramComponent.vue?vue&type=template&id=1cf1238b&scoped=true&v-if=widgetType%20%3D%3D%3D%20'Instagram'&"
import script from "./EditInstagramComponent.vue?vue&type=script&lang=js&"
export * from "./EditInstagramComponent.vue?vue&type=script&lang=js&"
import style0 from "./EditInstagramComponent.vue?vue&type=style&index=0&id=1cf1238b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cf1238b",
  null
  
)

export default component.exports