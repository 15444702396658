<template>
  <div>
    <MarketingSubheader />
    <p>Marketing</p>
</div>
</template>

<script>
import MarketingSubheader from '../components/MarketingComponents/MarketingSubheader';


export default {
  name: 'Marketing',
  components: {
    MarketingSubheader
  }
}
</script>

<style scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>
