<template lang="html">
  <div>
    <div class="date-range-holder">
      <VueCtkDateTimePicker
        v-model="inputValueLeft"

        color="#483D8B"
        range
        overlay
        hint="Selected:"
        label="Select Month"
        id="left"
      />
    </div>
    <div id="invoices" class="invoices-table">
      <!-- <table v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit" class="table table-hover"> -->
      <table class="table">
        <thead>
          <tr class="text-body">
            <th>Date</th>
            <th>Month</th>
            <th>Amount (inc VAT)</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in mock_data" :key="data.invoice_id" class="text-dark">
            <td>{{data.date}}</td>
            <td>{{data.month}}</td>
            <td>{{data.amount}}  &#163;</td>
            <td><img src="../../assets/icon-download.png" style="width: 25px"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  export default {
    name: "InvoicesComponent",
    components: {
      VueCtkDateTimePicker
    },
    data () {
      return {
        mock_data: [
          {"date":"15","month":"April","amount":"30"},
          {"date":"16","month":"April","amount":"23.7"},
          {"date":"18","month":"April","amount":"33.64"},
          {"date":"22","month":"April","amount":"53.99"}
        ],
        inputValueLeft: null,
      }
    },
    methods: {
    }
}
</script>

<style lang="css" scoped>
.date-range-holder{
  width: 39%;
  float: left;
}

table {
  border-collapse:separate;
  border-spacing: 0 1em;
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  background-color: #DEDEDE;
  text-align: center;
}

table tbody tr td {
  padding: 30px 40px;
  text-align: center;
  font-size: 16px;
  background-color: #DEDEDE;
}

table thead tr th:nth-child(1) {
  text-align: left;
}

table tbody tr td:nth-child(1) {
  text-align: left;
}

table thead tr th:nth-child(4) {
  text-align: right;
}

table tbody tr td:nth-child(4) {
  text-align: right;
}

img {
  cursor: pointer;
}

.invoices-table{
  margin-bottom: 30px;
}
</style>
