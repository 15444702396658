<template lang="html">
  <KanbanBoard />

</template>

<script>
import KanbanBoard from './Feedback/KanbanBoard';

export default {
  name: "FeedbackManagerComponent",
  components: {
    KanbanBoard
  }
}
</script>

<style lang="css" scoped>
</style>
