<template lang="html">
  <div class="container-fluid p-0">
      <div class="row">
        <div v-for="column in columns" :key="column.title" class="col-4" >
        <div class="column-width p-3">
          <p class="float-left">{{column.title}}</p>
          <span class="float-right text-danger clear" v-on:click="clearArr">{{column.button}}</span>
          <div class="clearfix"></div>
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable :list="column.tasks" :animation="400" group="tasks" ghost-class="ghost-card">
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
            <TaskCard v-for="(task) in column.tasks" :key="task.id" :task="task" class="mb-4 p-2 cursor-move"/>
            <!-- </transition-group> -->
          </draggable>
        </div>
        </div>
      </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./TaskCard";
export default {
  name: "KanbanBoard",
  components: {
    TaskCard,
    draggable
  },
  data() {
    return {
      columns: [
        {
          title: "Negative Feedback",
          tasks: [
            {
              id: 1,
              title: "Add discount code to checkout page",
              date: "September 14",
              type: "Feature Request",
              email: "bladovic@gmail.com"
            },
            {
              id: 2,
              title: "Provide documentation on integrations",
              date: "September 12",
              type: "Table breakfast",
              email: "bladovic@gmail.com"
            },
            {
              id: 3,
              title: "Design shopping cart dropdown",
              date: "September 9",
              type: "Design",
              email: "bladovic@gmail.com"
            },
            {
              id: 4,
              title: "Add discount code to checkout page",
              date: "September 14",
              type: "Feature Request",
              email: "bladovic@gmail.com"
            }
          ]
        },
        {
          title: "Solving the issue",
          tasks: [
            {
              id: 6,
              title: "Design shopping cart dropdown",
              date: "May 9",
              type: "Design",
              email: "saso@addme.com"
            },
            {
              id: 7,
              title: "Add discount code to checkout page",
              date: "May 14",
              type: "Feature Request",
              email: "saso@addme.com"
            },
            {
              id: 8,
              title: "Provide documentation on integrations",
              date: "May 12",
              type: "Backend",
              email: "saso@addme.com"
            }
          ]
        },
        {
          title: "Completed",
          button: "Clear List",
          tasks: [
            {
              id: 14,
              title: "Add discount code to checkout page",
              date: "January 14",
              type: "Feature Request",
              email: "findme@gmail.com"
            },
            {
              id: 15,
              title: "Design shopping cart dropdown",
              date: "January 9",
              type: "Design",
              email: "findme@gmail.com"
            }
          ]
        }
      ]
    };
  },
  methods: {
    clearArr: function(){
      this.columns[2].tasks = [];
    }
  }
}

</script>

<style lang="css" scoped>

.column-width{
  background-color: #EBECF0;
  border-radius: 7px;
  min-height: 50vh;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.column-width p{
  font-weight: bold;
  font-size: 18px;
}

.ghost-card {
  opacity: 0.6;
  background: #F7FAFC;
  border: 2px solid #4299e1;
}


span.clear{
  cursor: pointer;
}

.cursor-move{
  background: white;
  cursor: grab;
  border-radius: 5px;
  box-shadow: 2px 3px 3px 2px rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.sortable-chosen{
  transform: rotate(5deg);
}


.column-width::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.column-width::-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
}

.column-width::-webkit-scrollbar-thumb{
	background-color: #AAA;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(90deg,rgba(0, 0, 0, .2) 25%,transparent 25%,transparent 50%,rgba(0, 0, 0, .2) 50%,rgba(0, 0, 0, .2) 75%,transparent 75%,transparent)
}

</style>
