import axios from 'axios'

const state = {
  primary_colour: '',
  secondary_colour: '',
  onLoadColoursErrors: '',
  onColoursSuccess: null,
  onColoursErrors: null,
  onColoursLoading: false,
};

const mutations = {
  SET_THEME_COLORS: (state, data) => {
    state.loadThemeColours = data
  },
  LOAD_THEME_COLORS: (state) => {
    axios.get('backoffice/colours')
      .then(res => {
        if (res !== null) {
          if (res.data.data !== null
              && res.data.data !== undefined
              && (res.data.data.primary_colour !== null
              || res.data.data.secondary_colour !== null)) {
            state.primary_colour = res.data.data.primary_colour
            state.secondary_colour = res.data.data.secondary_colour
          } else {
            state.primary_colour = ''
            state.secondary_colour = ''
          }
      } else {
        state.primary_colour = ''
        state.secondary_colour = ''
      };
    })
    .catch(error => {
      console.log(error.message)
      if(error.message === 'Request failed with status code 401') {
        state.onLoadColoursErrors = 'Session expired, please login again.'
      } else {
        state.onLoadColoursErrors = 'Something went wrong with displaying the colours. Please try again later.'
      }
    })
  },
  CLEAR_LOAD_ERROR_MESSAGE: (state) => {
    return state.onLoadColoursErrors = null;
  },
  LOADING: (state, data) => {
    if (data) {
      return state.onColoursLoading = true
    }
  },
  CLEAR_LOADING: (state) => {
    return state.onColoursLoading = false
  },
  COLORS_SUCCESS_MESSAGE: (state, data) => {
    return state.onColoursSuccess = data
  },
  CLEAR_COLORS_SUCCESS_MESSAGE: (state, data) => {
    return state.onColoursSuccess = null
  },
  COLORS_ERROR_MESSAGE: (state, error) => {
    if (error) {
      state.onColoursErrors = 'Please select colours or try again later!'
    }
  },
  CLEAR_COLORS_ERROR_MESSAGE: (state) => {
    return state.onColoursErrors = null;
  },
};

const actions = {
  addThemeColors: ({ commit }, payload) => {
    const config = {
      headers: {'Content-Type': 'application/json'}
    }
    commit('LOADING', payload)
    axios.post('backoffice/colours/update ', payload, config)
      .then(res => {
        if(res) {
          commit('CLEAR_LOADING')
          commit('SET_THEME_COLORS', res.data.data)
          commit('LOAD_THEME_COLORS')
          commit('COLORS_SUCCESS_MESSAGE', res.data.message)
          if(res.data.message) {
            setTimeout(() => {
              commit('CLEAR_COLORS_SUCCESS_MESSAGE')
            }, 2000);
          }
        };
      })
      .catch(error => {
        console.log(error.response)
        commit('CLEAR_LOADING')
        commit('COLORS_ERROR_MESSAGE', error.response.data)
        if(error.response.data) {
          setTimeout(() => {
            commit('CLEAR_COLORS_ERROR_MESSAGE')
          }, 2000);
        }
      })
  },
}

const getters = {
  primary_colour: state => state.primary_colour,
  secondary_colour: state => state.secondary_colour,
  onLoadColoursErrors: state => state.onLoadColoursErrors,
  onColoursSuccess: state => state.onColoursSuccess,
  onColoursErrors: state => state.onColoursErrors,
  onColoursLoading: state => state.onColoursLoading,
};

export default {
  state,
  mutations,
  actions,
  getters
}
