<template lang="html">
<div>
  <div class="mt-4">
    <button class="add-email" @click="showAddEmailModal">Add daily email report recipients</button>
  </div>
  <div class="date-range-holder mt-0">
    <VueCtkDateTimePicker
      v-model="dateRange"
      color="#483D8B"
      buttonColor="#e34696"
      range
      overlay
      format=YYYY-MM-DD
      formatted=ll
      hint="Selected:"
      label="Select Date"
      id="left"
      @validate="loadData">
    </VueCtkDateTimePicker>
  </div>
  <select class="ml-4 pl-2" @change="onTypeChanged">
    <option selected value="0">Filter</option>
    <option value="1">Promoters</option>
    <option value="2">Passives</option>
    <option value="3">Detractors</option>
  </select>
  <div class="right-side-compare">
    <div class="all-time border border-secondary mx-4">
      <div class="">
        <p class="m-0 mb-3" style="text-indent: -5px;">All Time</p>
        <p v-if="erroredNpsAll" class="m-0 score text-center">Error</p>
        <p v-else class="m-0 score text-center">{{allTimeDeduction}}</p>
      </div>
    </div>
    <div class="compare-date float-right">
      <div class="first-date border border-secondary mx-4 mb-1">
        <div class="">
          <p class="m-0 mb-1">First Date</p>
          <p v-if="erroredNpsFirst" class="m-0 score text-center">Error</p>
          <p v-else class="m-0 first-score text-center">{{this.firstDateDeduction}}</p>
        </div>
      </div>
      <div class="second-date border border-secondary mx-4">
        <div class="">
          <p class="m-0 mb-1">Second Date</p>
          <p v-if="erroredNpsSecond" class="m-0 score text-center">Error</p>
          <p v-else class="m-0 second-score text-center">{{this.secondDateDeduction}}</p>
        </div>
      </div>
    </div>
    <VueCtkDateTimePicker
      v-model="compareDateFirst"
      color="#483D8B"
      buttonColor="#e34696"
      range
      overlay
      format=YYYY-MM-DD
      formatted=ll
      hint="Selected:"
      label="Click to compare"
      class="mb-1"
      id="middle"
      @validate="onCompareDateFirst">
    </VueCtkDateTimePicker>
    <VueCtkDateTimePicker
      v-model="compareDateSecond"
      color="#483D8B"
      buttonColor="#E34696"
      range
      overlay
      format=YYYY-MM-DD
      formatted=ll
      hint="Selected:"
      label="Click to compare"
      id="right"
      @validate="onCompareDateSecond">
    </VueCtkDateTimePicker>
  </div>
  <div v-if="!errored" id="score" class="score-table mt-4">
    <table class="table table-hover">
      <thead>
        <tr class="text-body">
          <th>Date</th>
          <th>Time</th>
          <th>Bill ID</th>
          <th>Table Number</th>
          <th>NPS Score</th>
          <th>NPS Type</th>
          <th>Has Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">Loading...</tr>

        <tr v-else v-for="result in results" :key="result.rating.id" class="text-dark">
          <td>{{result.date}}</td>
          <td>{{result.time}}</td>
          <td>{{result.id}}</td>
          <td>{{result.table_number}}</td>
          <td>{{result.rating.rating}}</td>
          <td>{{result.type}}</td>
          <td @click="showEmailModal" :class="{'pinkLinks': result.transaction_emails.customer_email_short === 'YES'}">
            {{result.transaction_emails.customer_email_short}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="text-center mt-5 pt-5 pb-4 alert-danger" role="alert">
    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
  </div>

  <Pagination
      @handlePageChange="handlePageChange"
      :maxPage="maxPage"
      :totalItems="totalItems"
      :message="paginationMessage"
  >
    <li slot="body"></li>
  </Pagination>

  <Modal v-if="isModalVisible"
    @close="closeModal"
    :emails="emails"
    :comments="comments"
    :voiceNotes="voiceNotes"
    :loadModel="loadModel"
    :eventDate="eventDate"
  >
    <ul>
      <li slot="body"></li>
    </ul>
  </Modal>
</div>
</template>

<script>
import axios from 'axios'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Modal from './ModalComponent';
import Pagination from "../Pagination/PaginationComponent";

export default {
  name: "NetPromoterScoreComponent",
   components: {
     VueCtkDateTimePicker,
     Modal,
     Pagination
   },
  data(){
    return {
      results: [],
      dateRange: {
        end: null,
        shortcut: undefined,
        start: new Date().toISOString().substring(0, 10)
      },
      allTimeDeduction: null,
      compareDateFirst: null,
      compareDateSecond: null,
      firstDateDeduction: null,
      secondDateDeduction: null,
      start: null,
      loading: true,
      errored: false,
      erroredNpsAll: false,
      erroredNpsFirst: false,
      erroredNpsSecond: false,
      isModalVisible: false,
      busy: false,
      limit: 30,
      loadModel: '',
      emails: [],
      comments: [],
      voiceNotes: [],
      eventDate: '',
      maxPage: 10,
      totalItems: 100,
      page: 1,
      perPage: 10,
      perPages: [10, 15, 20],
      paginationMessage: "Please note that the items per page number refers to the number of bills. Each bill might have multiple reviews."
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    onTypeChanged (event) {
      if (event.target.value === "1") {
        this.rating = 'promoter';
      } else if (event.target.value === "2") {
        this.rating = 'passive';
      } else if (event.target.value === "3") {
        this.rating = 'detractor';
      } else {
        this.rating = null;
      }
      this.loadData();
    },
    loadData () {
      let params = new URLSearchParams();

      if (this.page !== null) {
        params.append('page', this.page);
      }

      if (this.perPage !== null) {
        params.append('per_page', this.perPage);
      }

      if (this.dateRange !== null) {
        if (this.dateRange.start !== null && this.dateRange.start !== undefined) {
          params.append('start', this.dateRange.start);
          if (this.dateRange.end !== null && this.dateRange.end !== undefined) {
            let endDate = new Date(this.dateRange.end);
            endDate.setDate(endDate.getDate() + 1);
            params.append('end', endDate.toISOString().substring(0, 10));
          }
        }
      }
      if (this.rating !== null && this.rating !== undefined && this.rating !== '') {
        params.append('rating', this.rating);
      }
      axios.get('backoffice/nps', {params: params})
        .then(response => {
          let tmpData = response.data.data.items;
          let flatMappedBills = [];
          this.totalItems = response.data.data.total_items;
          this.maxPage = response.data.data.max_page;

          if (tmpData !== null) {
            tmpData.forEach(bill => {
              if (bill.ratings !== null && bill.ratings.length > 0) {
                bill.ratings.forEach(ratingObj => {
                  if (([0, 1, 2, 3, 4, 5, 6].includes(ratingObj.rating) && this.rating === 'detractor') ||
                    ([7, 8].includes(ratingObj.rating) && this.rating === 'passive') ||
                    ([9, 10].includes(ratingObj.rating) && this.rating === 'promoter') ||
                    (this.rating === null || this.rating === undefined))
                  {
                    const fullDate = ratingObj.created_at;
                    const dateArray = fullDate.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
                    let tmpBill = JSON.parse(JSON.stringify(bill));
                    tmpBill.transaction_emails.customer_email_short = 'NO'
                    if (tmpBill.transaction_emails.length > 0) {
                      tmpBill.transaction_emails
                        .forEach(transEmail => {
                          if (transEmail.customer_email !== null && transEmail.customer_email.length > 0) {
                            tmpBill.transaction_emails.customer_email_short = 'YES'
                          }
                        })
                    } else {
                      tmpBill.transaction_emails.customer_email_short = 'NO'
                    }

                    tmpBill.fullDate = fullDate;
                    tmpBill.date = dateArray[0];
                    tmpBill.time = dateArray[1];

                    tmpBill.rating = ratingObj;
                    tmpBill.ratingId = ratingObj.id

                    if ([9, 10].includes(ratingObj.rating)) {
                      tmpBill.type = "Promoter";
                    } else if ([7, 8].includes(ratingObj.rating)) {
                      tmpBill.type = "Passive";
                    } else {
                      tmpBill.type = "Detractor";
                    }

                    if (tmpBill.rating.rating_feedback !== null) {
                      tmpBill.rating.rating_feedback_short = 'YES'
                    } else {
                      tmpBill.rating.rating_feedback_short = 'NO'
                    }

                    if (
                        tmpBill.rating.full_audio_path !== null
                        && tmpBill.rating.rating <=6
                        && tmpBill.rating.full_audio_path !== undefined
                      ) {
                      tmpBill.rating.audio_feedback_filename_short = 'YES'
                    } else {
                      tmpBill.rating.audio_feedback_filename_short = 'NO'
                    }
                    flatMappedBills.push(tmpBill);
                    }
                  })
              } else {
                let tmpBill = bill;
                const fullDate = tmpBill.created_at;
                const dateArray = fullDate.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
                tmpBill.fullDate = fullDate;
                tmpBill.date = dateArray[0];
                tmpBill.time = dateArray[1];

                tmpBill.rating = {id: tmpBill.id};
                if (this.rating === 'promoter') {
                  tmpBill.type = "Promoter";
                } else if (this.rating === 'passive') {
                  tmpBill.type = "Passive";
                } else if (this.rating === 'detractor') {
                  tmpBill.type = "Detractor";
                } else {
                  tmpBill.type = "/"
                }

                tmpBill.rating.rating = "/"
                tmpBill.rating.rating_feedback_short = 'NO'
                tmpBill.rating.audio_feedback_filename_short = 'NO'
                tmpBill.transaction_emails.customer_email_short = 'NO'

                if (tmpBill.transaction_emails.length > 0) {
                  tmpBill.transaction_emails
                    .forEach(transEmail => {
                      if (transEmail.customer_email !== null && transEmail.customer_email.length > 0) {
                        tmpBill.transaction_emails.customer_email_short = 'YES'
                      }
                    })
                } else {
                  tmpBill.transaction_emails.customer_email_short = 'NO'
                }

                flatMappedBills.push(tmpBill);
              }
            });
          }
          this.results = flatMappedBills;
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 300)
        })
      axios.get('backoffice/nps-calculation')
        .then(response => {
          let tempData = response.data.data
          this.allTimeDeduction = tempData.deduction
          if(this.allTimeDeduction === undefined) {
            this.allTimeDeduction = 'No score'
          }
        })
        .catch(error => {
          console.log(error)
          this.erroredNpsAll = true
        })
    },
    onCompareDateFirst () {
      let params = new URLSearchParams();
      if (this.compareDateFirst !== null) {
        if (this.compareDateFirst.start !== null && this.compareDateFirst.start !== undefined) {
          params.append('start', this.compareDateFirst.start);
          if (this.compareDateFirst.end !== null && this.compareDateFirst.end !== undefined ) {
            let endDate = new Date(this.compareDateFirst.end);
            endDate.setDate(endDate.getDate() + 1);
            params.append('end', endDate.toISOString().substring(0, 10));
          }
        }
      }
      axios.get('backoffice/nps-calculation', {params: params})
        .then(response => {
          let tempData = response.data.data
          this.firstDateDeduction = tempData.deduction
          if(this.firstDateDeduction === undefined) {
            this.firstDateDeduction = 'No score'
          }
        })
        .catch(error => {
          console.log(error)
          this.erroredNpsFirst = true
        })
    },
    onCompareDateSecond () {
      let params = new URLSearchParams();
      if (this.compareDateSecond !== null) {
        if (this.compareDateSecond.start !== null && this.compareDateSecond.start !== undefined) {
          params.append('start', this.compareDateSecond.start);
          if (this.compareDateSecond.end !== null && this.compareDateSecond.end !== undefined ) {
            let endDate = new Date(this.compareDateSecond.end);
            endDate.setDate(endDate.getDate() + 1);
            params.append('end', endDate.toISOString().substring(0, 10));
          }
        }
      }
      axios.get('backoffice/nps-calculation', {params: params})
        .then(response => {
          let tempData = response.data.data
          this.secondDateDeduction = tempData.deduction
          if(this.secondDateDeduction === undefined) {
            this.secondDateDeduction = 'No score'
          }
        })
        .catch(error => {
          console.log(error)
          this.erroredNpsSecond = true
        })
    },
    showAddEmailModal () {
      this.loadModel = 'add-emails'
      this.isModalVisible = true;

    },
    showEmailModal (event) {
      this.loadModel = 'emails'
      let emailsArr = [];
      let allBillEmails = [];
      let filtredEmails = [];
      const eventDate = event.composedPath()[1].innerText.substring(0,20);
      const billId = event.composedPath()[1].innerText.substring(20,25);

      emailsArr = this.results.filter(res => res.id == billId)
        .map(item => {
          if (item.ratings.length > 0) {
            item.transaction_emails.forEach(trans_email => {
              if (trans_email.customer_email !== null) {
                allBillEmails.push(trans_email.customer_email);
                filtredEmails = allBillEmails.filter((item, pos, self) => {
                  return self.indexOf(item) == pos;
                });
              }
            })
          } else {
            item.transaction_emails.forEach(trans_email => {
              if (trans_email.customer_email !== null) {
                filtredEmails.push(trans_email.customer_email);
              }
            })
          }
        });
      this.emails = filtredEmails
      this.eventDate = eventDate

      this.emails.filter(el => el !== null)

      if (event.target.innerText === 'NO') {
        this.isModalVisible = false;
      } else {
        this.isModalVisible = true;
      }
    },
    showCommentsModal (event) {
      this.loadModel = 'comments'
      let feedback;
      let commentsArr = [];
      let filteredComments = [];
      const eventDate = event.composedPath()[1].innerText.substring(0,20);
      const billId = event.composedPath()[1].innerText.substring(20,25);

      commentsArr = this.results.filter(res => res.id == billId)
        .filter(item => item.rating.created_at.replace(/\s/g, "") == eventDate.replace(/\s/g, ""));

      commentsArr.map(item => {
        if (item.rating.rating_feedback !== null) {
          feedback = item.rating.rating_feedback.slice(1, -1)
          filteredComments.push(feedback.split(","))
        }
      })
      this.comments = filteredComments[0];

      this.eventDate = eventDate
      if (event.target.innerText === 'NO') {
        this.isModalVisible = false;
      } else {
        this.isModalVisible = true;
      }
    },
    showAudioModal (event) {
      this.loadModel = 'voiceNotes'
      let audioArr = [];
      let splitAudio = [];
      const eventDate = event.composedPath()[1].innerText.substring(0,20);

      this.results.filter(res => res.fullDate.replace(/\s/g, "") === eventDate.replace(/\s/g, ""))
        .map(item => {
          if (item.rating.full_audio_path !== null && item.rating.full_audio_path !== undefined) {
            audioArr.push(item.rating.full_audio_path);
          }
        });


      audioArr.filter(el => el != null)
        .map(item => {
          splitAudio.push(item.split(','))
        })

      this.voiceNotes = splitAudio[0];
      this.eventDate = eventDate

      if (event.target.innerText === 'NO') {
        this.isModalVisible = false;
      } else {
        this.isModalVisible = true;
      }
    },
    closeModal () {
      this.isModalVisible = false;
    },

    handlePageChange(page, perPage) {
      this.page = page;
      this.perPage = perPage;

      this.loadData();
    }
  }
}
</script>

<style lang="css" scoped>
select {
  height: 44px;
  width: 150px;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  color: rgba(0,0,0,.54);
}

table thead tr th {
  padding: 12px 35px;
  border: none;
  text-align: center;
}

table tbody tr td {
  padding: 20px 12px;
  text-align: center;
  font-size: 16px;
}

table tbody tr td:nth-child(1) {
  padding-right: 5px;
}

.date-range-holder {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}

div.right-side-compare {
  width: 55%;
  height: 100px;
  display: inline-block;
  position: relative;
  top: 10px;
}

div.all-time {
  padding: 20px 25px;
  line-height: 15px;
  font-weight: 700;
  float: left;
  text-align: center;
}

div.all-time div p.score {
  font-size: 27px;
  color: #3C038C;
  font-weight: 900;
  padding-right: 10px;
}

.compare-date {
  width: 30%;
  height: 100px;
  position: relative;
  top: -8px;
  left: 10px;
}

div.first-date,
div.second-date {
  padding: 5px 10px;
  height: 50px;
  line-height: 15px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

p.first-score,
p.second-score,
p.score {
  font-size: 16px;
  color: #3C038C;
  font-weight: 700;
}

.pinkLinks {
  color: #E85F9A;
  cursor: pointer;
}
button.add-email {
  background: #ffffff;
  color: #E85F9A;
  border: none;
  font-weight: bold;
  outline: none;
}
</style>
