<template lang="html">
  <div>
    <select
      v-if="serviceAmount === 'Select Service amount'"
      class="d-block mb-3">
        <option value="">Additional service on/off</option>
        <option value="">On/Off</option>
    </select>
    <SelectInputComponent
      @clickChange="onChange"
      :serviceAmount="serviceAmount" />
    <AdditionalTipComponent
      v-if="serviceAmount === 'Additional Tip pop-up'"
      :serviceAmount="serviceAmount" />
    <NoServiceComponent
      v-if="serviceAmount === 'No Service pop-up'"
      :serviceAmount="serviceAmount" />
    <!-- Iphone Holder -->
    <div v-if="serviceAmount === 'Select Service amount'" class="iphone-holder float-right">
      <div class="inner">
        <div class="content text-center">
          <h6 class="mb-0 pt-3">Your Bill</h6>
          <p class=" mb-0 pb-1 secondary table-text">Table 7</p>
          <img alt="ham logo" src="../../../assets/hamburger_menu_icon.png" class="mr-2 ham-img">
        </div>
        <div class="container-fluid ">
          <div class="row">
            <div class="col-md-12 parent-holder p-0">
              <ul class="">
                <li class="first">Tap <img src="../../../assets/like-icon.svg" alt="" class="first"> below to leave feedback  <img alt="info logo" src="../../../assets/info-icon.png" class="mr-2 info-img"> <span class="float-right secondary amount">Amount</span> </li>
                <li v-for="item in items" class="my-1 py-1 px-2">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   width="84.000000pt" height="84.000000pt" viewBox="0 0 84.000000 84.000000"
                   preserveAspectRatio="xMidYMid meet" class="mr-2">
                  <g transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
                  fill="#E85F9A" stroke="none" class="primary-svg">
                  <path d="M494 816 c-9 -39 3 -78 24 -74 13 3 17 14 17 48 0 34 -4 45 -17 48
                  -12 2 -19 -5 -24 -22z"/>
                  <path d="M340 752 c0 -25 45 -66 64 -59 23 9 20 30 -7 55 -28 27 -57 29 -57 4z"/>
                  <path d="M641 746 c-23 -24 -23 -56 -1 -56 17 0 62 49 57 63 -7 21 -32 18 -56
                  -7z"/>
                  <path d="M431 623 c-22 -74 -66 -141 -104 -162 -22 -12 -28 -11 -54 8 -26 19
                  -40 21 -123 19 l-95 -3 0 -240 0 -240 95 -3 c81 -2 98 0 121 17 26 20 27 20
                  89 0 48 -15 91 -19 196 -19 130 0 136 1 159 25 14 13 25 35 25 49 0 14 11 36
                  25 50 26 26 33 64 15 82 -8 8 -7 19 1 37 9 20 9 33 1 51 -8 18 -8 33 1 55 10
                  26 8 34 -9 58 -20 26 -27 28 -110 33 -73 4 -88 8 -85 20 2 8 6 50 9 92 4 75 4
                  78 -26 108 -24 23 -39 30 -70 30 l-40 0 -21 -67z m97 5 c15 -15 16 -100 1
                  -150 -9 -35 -13 -38 -45 -38 -31 0 -35 -3 -32 -22 3 -22 7 -23 138 -26 74 -1
                  139 -7 144 -12 16 -16 -13 -39 -52 -42 -25 -2 -37 -8 -37 -18 0 -10 12 -16 37
                  -18 65 -6 78 -52 14 -52 -35 0 -62 -21 -51 -40 3 -5 21 -10 38 -10 41 0 62
                  -14 54 -34 -4 -10 -18 -16 -40 -16 -55 0 -76 -33 -31 -50 16 -7 24 -16 22 -28
                  -3 -15 -19 -17 -132 -20 -110 -2 -139 1 -193 19 l-63 21 0 154 c0 120 3 154
                  13 154 40 0 136 109 157 179 7 20 13 42 15 49 5 15 27 16 43 0z m-283 -383 l0
                  -190 -72 -3 -73 -3 0 196 0 196 73 -3 72 -3 0 -190z"/>
                  <path d="M154 135 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36 0z"/>
                  </g>
                  </svg>
                 {{item.name}} <span class="float-right">&#163; {{item.price}}</span> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bottom">
          <p class="px-3 pt-1">You can add service charge d...<span class="float-right total">Total &#163; 33.25</span> </p>
          <button type="button" name="button" class="mb-2 primary" disabled>Pay whole bill <img alt="people logo" src="../../../assets/icon-button-single.png" class="ml-2"> </button>
          <button type="button" name="button" disabled class="primary">Split bill <img alt="people logo" src="../../../assets/icon-button-multiple.png"></button>
        </div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="256.000000pt" height="212.000000pt" viewBox="0 0 256.000000 212.000000"
         preserveAspectRatio="xMidYMid meet" class="arrow-img">
        <g transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
        fill="#E85F9A" stroke="none" class="primary-svg">
        <path d="M231 2089 c-88 -29 -181 -119 -205 -199 -21 -70 -20 -129 2 -187 10
        -27 126 -214 259 -418 132 -203 314 -485 405 -625 392 -607 380 -590 488 -631
        43 -17 71 -20 130 -17 127 8 201 59 293 203 24 39 194 306 377 595 183 289
        380 599 438 689 118 186 132 219 132 306 0 116 -74 221 -196 277 -39 18 -67
        23 -139 23 -81 0 -95 -3 -143 -29 -29 -17 -66 -44 -81 -61 -15 -17 -178 -268
        -362 -558 -183 -290 -337 -527 -340 -527 -3 0 -162 240 -352 534 -192 295
        -363 548 -382 565 -89 77 -205 99 -324 60z m213 -190 c18 -19 170 -245 336
        -504 167 -258 351 -541 407 -628 l104 -159 88 139 c48 76 233 367 411 646 236
        372 331 514 354 528 65 40 176 12 216 -54 43 -70 36 -86 -158 -392 -98 -154
        -314 -494 -480 -755 -166 -262 -315 -489 -332 -505 -24 -23 -41 -29 -87 -33
        -94 -6 -98 -2 -361 403 -128 198 -290 448 -360 555 -401 619 -402 620 -402
        661 0 29 8 47 32 75 44 49 81 65 146 61 45 -3 58 -9 86 -38z"/>
        </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import SelectInputComponent from '../ServiceSettingsComponents/SelectInputComponent';
import AdditionalTipComponent from '../ServiceSettingsComponents/AdditionalTipComponent';
import NoServiceComponent from '../ServiceSettingsComponents/NoServiceComponent';

export default {
  name: "ServiceSettingsComponent",
  components: {
    SelectInputComponent,
    AdditionalTipComponent,
    NoServiceComponent,
  },
  data () {
    return {
      serviceAmount: this.$store.state.serviceAmount,
      optionColorChanged: false,
      items: [
        {name: "Mango", price: "3.95"},
        {name: "Mango", price: "3.95"},
        {name: "Mango", price: "3.95"},
        {name: "Plum", price: "4.95"},
        {name: "Green Appple", price: "6.95"},
        {name: "Honey Fresh Tea", price: "5.95"},
        {name: "Honeydew", price: "3.95"}
      ],
    }
  },
  methods: {
    onChange (value) {
      this.serviceAmount = value;
    }
  }
}
</script>

<style lang="css" scoped>
.iphone-holder{
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -220px;
}

.inner{
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.parent-holder{
  background: #E8E8E8;
  padding: 0 6px!important;
}

.content p.table-text{
  color: #463C8B;
  font-weight: bold;
  font-size: 14px;
}

ul li.first{
  background: #F0F0F0;
  padding: 8px!important;
  margin-top: 6px;
  font-size: 11px;
  color: #B3B3B3;
}

.content{
  position: relative;
}

.content h6,
.total{
  color: #1F1F1F;
  font-weight: bold;
}

.content img.ham-img{
  height: 13px;
  position: absolute;
  top: 19px;
  right: 3px;
}

svg.arrow-img{
  height: 5em;
  width: 100px;
  position: absolute;
  bottom: 33%;
  left: 33%;
}

ul{
  list-style: none;
  padding: 0;
  background: #E8E8E8;
}

ul li{
  display: block;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
}

ul li span.amount{
  color: #463C8B;
  font-weight: bold;
  font-size: 15px;
}

ul li svg{
  height: 2em;
  width: 31px;
}

ul li img.first{
  vertical-align: sub;
  height: 2em;
}

ul li img{
  height: 1.3em;
}

.bottom p{
  font-size: 10px;
  color: #C9C9C9;
  font-weight: bold;
}

.bottom p span{
  font-size: 14px;
}

.bottom button{
  background: #D15895;
  border-radius: 20px;
  border: none;
  width: 90%;
  margin-left: 5%;
  padding: 7px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
}

.bottom button img{
  height: 1.8em;
}

select{
  padding: 20px 130px 20px 15px;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #BCBBBC;
  outline: none;
  border-radius: 7px;
  color:#BCBBBC;
}

select:focus{
  color: #202020;
}
</style>
