import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


import Sales from '../views/Sales'
import BillsPage from '../views/salesPages/BillsPage'
import TransactionsPage from '../views/salesPages/TransactionsPage'
// import BankSubmissionsPage from '../views/salesPages/BankSubmissionsPage'
import ZreportsPage from '../views/salesPages/ZreportsPage'
import SettlementPage from '../views/salesPages/SettlementPage'
// import ReportsPage from '../views/salesPages/ReportsPage'


import Feedback from '../views/Feedback'
// import EmailReportsPage from '../views/feedbackPages/EmailReportsPage';
import FeedbackManagerPage from '../views/feedbackPages/FeedbackManagerPage';
import MenuPage from '../views/feedbackPages/MenuPage';
import NetPromoterPage from '../views/feedbackPages/NetPromoterPage';
import TeamMembersNPSPage from '../views/feedbackPages/TeamMembersNPSPage';
import SetExperimentPage from '../views/feedbackPages/SetExperimentPage';



import Marketing from '../views/Marketing'
import BannersPage from '../views/marketingPages/BannersPage';
import CollectedPage from '../views/marketingPages/CollectedPage';
// import ColoursPage from '../views/marketingPages/ColoursPage';
import ReceiptEmailPage from '../views/marketingPages/ReceiptEmailPage';
import WelcomePage from '../views/marketingPages/WelcomePage';
import WidgetsPage from '../views/marketingPages/WidgetsPage';



import Service from '../views/Service'
// import DailyReportsPage from '../views/servicePages/DailyReportsPage';
import SettingsPage from '../views/servicePages/SettingsPage';
import TeamMembersPage from '../views/servicePages/TeamMembersPage';
import TipManagementReportsPage from '../views/servicePages/TipManagementReportsPage';
import DateRangeReportsPage from '../views/servicePages/DateRangeReportsPage';



import Account from '../views/Account'
// import AdvancedPage from '../views/accountPages/AdvancedPage';
import DevicesPage from '../views/accountPages/DevicesPage';
import IntegrationsPage from '../views/accountPages/IntegrationsPage';
import InvoicesPage from '../views/accountPages/InvoicesPage';
import PaymentsPage from '../views/accountPages/PaymentsPage';
import AccountManagerPage from '../views/accountPages/AccountManagerPage';

import Overview from '../views/Overview';
import RestarauntPage from '../views/overviewPages/RestarauntPage';



import Login from '../views/Login';


import store from '../store/store';

const routes = [

  // {
  //  path: '/',
  //   redirect: {
  //     name: "login"
  //   }
  // },
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/overview/restaraunt',
    name: 'RestarauntPage',
    component: RestarauntPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // overview routes ending ///////////////////////////////
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/sales/bills',
    name: 'BillsPage',
    component: BillsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/sales/transactions',
    name: 'TransactionsPage',
    component: TransactionsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/sales/bank-submissions',
  //   name: 'BankSubmissionsPage',
  //   component: BankSubmissionsPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  {
    path: '/sales/z-reports',
    name: 'ZreportsPage',
    component: ZreportsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/sales/settlements',
    name: 'SettlementPage',
    component: SettlementPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/sales/reports',
  //   name: 'ReportsPage',
  //   component: ReportsPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  // sales routes ending////////////////////////////////////////////////////////////////
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/feedback/email-reports',
  //   name: 'EmailReportsPage',
  //   component: EmailReportsPage
  // },
  // {
  //   path: '/feedback/feedback-manager',
  //   name: 'FeedbackManagerPage',
  //   component: FeedbackManagerPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  // {
  //   path: '/feedback/menu',
  //   name: 'MenuPage',
  //   component: MenuPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  {
    path: '/feedback/net-promoter',
    name: 'NetPromoterPage',
    component: NetPromoterPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/feedback/team-members-nps',
    name: 'TeamMembersNPSPage',
    component: TeamMembersNPSPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/feedback/experiments',
  //   name: 'SetExperimentPage',
  //   component: SetExperimentPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  // feedback routes ending////////////////////////////////////////////
  {
    path: '/marketing',
    name: 'Marketing',
    component: Marketing,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/marketing/banners',
  //   name: 'BannersPage',
  //   component: BannersPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  {
    path: '/marketing/collected-emails',
    name: 'CollectedPage',
    component: CollectedPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/marketing/colours',
  //   name: 'ColoursPage',
  //   component: ColoursPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  // {
  //   path: '/marketing/receipt',
  //   name: 'ReceiptEmailPage',
  //   component: ReceiptEmailPage,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'Login'
  //       })
  //     }
  //
  //     next()
  //   }
  // },
  {
    path: '/marketing/welcome',
    name: 'WelcomePage',
    component: WelcomePage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/marketing/widgets',
    name: 'WidgetsPage',
    component: WidgetsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // marketing routes ending /////////////////////////
  {
    path: '/service',
    name: 'Service',
    component: Service,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/service/daily-reports',
  //   name: 'DailyReportsPage',
  //   component: DailyReportsPage
  // },
  {
    path: '/service/settings',
    name: 'SettingsPage',
    component: SettingsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/service/team-members',
    name: 'TeamMembersPage',
    component: TeamMembersPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/service/reports',
    name: 'TipManagementReportsPage',
    component: TipManagementReportsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/service/date-range-reports',
    name: 'DateRangeReportsPage',
    component: DateRangeReportsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // service routes ending /////////////////////////
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  // {
  //   path: '/account/advanced',
  //   name: 'AdvancedPage',
  //   component: AdvancedPage
  // },
  {
    path: '/account/devices',
    name: 'DevicesPage',
    component: DevicesPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/account/integrations',
    name: 'IntegrationsPage',
    component: IntegrationsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/account/invoices',
    name: 'InvoicesPage',
    component: InvoicesPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/account/payments',
    name: 'PaymentsPage',
    component: PaymentsPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/account/manager',
    name: 'AccountManagerPage',
    component: AccountManagerPage,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated'] || localStorage.getItem('userEmail') !== 'developer@tableyeti.com') {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  }
  // account routes ending//////////////////////////////////
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  Vue.nextTick(() => {
      Vue.prototype.$posthog.capture("$pageview", {
        $current_url: to.fullPath
      });
    });
});

export default router
