<template v-if="widgetType === 'Donations'">
  <div class="inner-colour-container">
    <form>
      <!-- step 1 start -->
      <div v-if="step === 1">
        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="primaryColorPicker" class="pl-3" v-on:input="onInputColor">
              Colour
            <input type="color" name="primary" id="primaryColorPicker" v-on:change="onChangeColorPicker($event)" v-model="widget.activeColor" v-bind:class="{pickedColor: attachedColor}" ref="color" />
            </label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-9">
            <input type="text" name="" placeholder="Headline" v-model="widget.headline" minlength="1" maxlength="9" title="Max allowed 9 characters.">
            <p v-if="!headlineIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field, max allowed 9 characters.</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-9">
            <input type="text" name="" placeholder="Sub Headline" v-model="widget.sub_headline" minlength="1" maxlength="100" title="Max allowed 100 characters.">
            <p v-if="!sub_headlineIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field, max allowed 100 characters.</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-4">
            <input type="text" name="" placeholder="Button Text" v-model="widget.button_text">
            <p v-if="!button_textIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="imagePicker" class="pl-3">
            Logo
            <input type="file" value="" name="logo" id="imagePicker" style="display:none" v-on:change="onHandleImage" />
            </label>
            <p v-if="!logoIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActive }" role="alert">*required field</p>
          </div>

          <div class="form-group col-md-10">
            <button class="btn next" v-on:click.prevent="next()">Next</button>
          </div>
        </div>
      </div>
      <!-- step 1 end -->

      <!-- step 2 start -->
      <div v-if="step === 2">
        <div class="form-row">
          <div class="form-group voucher">Voucher
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-4">
            <input type="number" name="" placeholder="Amount" v-model="widget.details[0].details.amount" @focus="isActiveTwo = true" @keypress="onlyForCurrency" min=0>
            <p v-if="!amountIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActiveTwo }" role="alert">*only numbers are allowed</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-4">
            <input type="text" name="" placeholder="Voucher Headline" v-model="widget.details[0].details.voucher_offer" @focus="isActiveTwo = true">
            <p v-if="!voucher_offerIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActiveTwo }" role="alert">*required field</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-4">
            <input type="text" name="" placeholder="Voucher Subheadline" v-model="widget.details[0].details.voucher_text" @focus="isActiveTwo = true">
            <p v-if="!voucher_textIsValid" class="alert alert-danger" v-bind:class="{'d-none': isActiveTwo }" role="alert">*required field</p>
          </div>
        </div>
        <template >
          <button v-if="onEditMode" type="submit" v-on:click.prevent="onUpdateVoucher(widget)" class="save btn ml-3">Update</button>
          <button v-if="!onEditMode" type="submit" v-on:click.prevent="onSubmit()" class="finish btn ml-3">Finished</button>
          <button v-if="!onEditMode" type="submit" v-on:click.prevent="onAddNewVoucher()" class="add-voucher btn ml-3">Add Voucher</button>
        </template>
        <button v-on:click.prevent="prev()" class="back btn">Back</button>
      </div>
      <!-- step 2 end -->

      <!-- On error message -->
      <p v-if="onWidgetsErrors" class="alert-danger text-left">{{ onWidgetsErrors }}</p>
    </form>
    <!-- Iphone Holder Live Preview start -->
    <div class="iphone-holder float-right">
      <div v-if="step === 1">
        <div class="inner">
          <div class="header text-center pt-2">
            <img src="../../../assets/icon-back-arrow-iphone.png" alt="" class="back float-left arrow">
            <h5 class="mb-0"> Thanks for paying with</h5>
            <img src="../../../assets/icon-logo.png" alt="" class="table-logo">
          </div>
          <hr class="my-2">
          <p class="pl-2">Here are some exclusive offers as a thank you for dining with us.</p>

          <div class="widgets-donation p-2 mb-3" :style="{backgroundColor: widget.activeColor}">
            <p class="d-inline-block">{{ widget.headline }}</p>
            <img :src="this.image" alt="" class="donation-image float-right">
            <p>{{ widget.sub_headline }}</p>
            <button disabled type="button" name="button" class="p-1 button-widget" :style="{color: widget.activeColor}">{{widget.button_text}}</button>
          </div>

        </div>
      </div>
      <div v-if="step === 2">
        <div class="inner">
          <div class="header text-center pt-2">
            <img src="../../../assets/icon-back-arrow-iphone.png" alt="" class="back float-left arrow">
            <h5 class="mb-0">Donate</h5>
          </div>
          <hr class="my-2">
          <img :src="this.image" alt="" class="donation-image float-right mr-2 mt-1">
          <p class="pl-2 donate">{{ widget.headline }}</p>
          <p class="pl-2 donate">{{ widget.sub_headline }}</p>

          <div class="widgets-donation donate p-2 mb-3" v-for="(voucher,index) in widget.details" :key="`voucher-${index}`" :style="{backgroundColor: widget.activeColor}">
            <p class="d-inline-block">
              <span>&#163; {{ voucher.details.amount | to-fixed }}</span>
              {{ voucher.details.voucher_offer }}</p>
            <img :src="image" alt="" class="donation-image float-right">
            <p>{{ voucher.details.voucher_text }}</p>
            <button disabled type="button" name="button" class="p-1 button-widget" :style="{color: widget.activeColor}">{{widget.button_text}}</button>
          </div>

          <div v-if="vouchersList.length > 0 && !onEditMode">
            <div class="widgets-donation donate p-2 mb-3" v-for="(voucher,index) in vouchersList" :key="`voucher-${index}`" :style="{backgroundColor: widget.activeColor}">
              <p class="d-inline-block">
                <span>&#163; {{ voucher.details.amount | to-fixed }}</span>
                {{ voucher.details.voucher_offer }}</p>
              <img :src="image" alt="" class="donation-image float-right">
              <p>{{ voucher.details.voucher_text }}</p>
              <button disabled type="button" name="button" class="p-1 button-widget" :style="{color: widget.activeColor}">{{widget.button_text}}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Draggable start -->
    <div class="mb-4"v-if="step === 2 && !onEditMode">
      <draggable :list="vouchersList" :forceFallback="true">
        <div class="result align-items-center py-2" v-for="(voucher,index) in vouchersList" :key="`voucher-${index}`" :style="{backgroundColor: widget.activeColor}">
          <div class="float-left ml-3 ">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <p class="ml-3 mt-3 d-inline-block">{{ voucher.details.voucher_offer }} | &#163; {{ voucher.details.amount | to-fixed }}</p>
          <div class="float-right ios-correct">
            <button type="button" class="btn view" data-toggle="modal" data-target="#myModal">View Results</button>
            <button type="button" name="button" class="mx-2 btn edit" v-on:click="onEditVoucher(voucher, index)">Edit</button>
          </div>
        </div>
      </draggable>
    </div>
    <!-- Draggable end -->
  </div>
</template>

<script>
import draggable from "vuedraggable";

import widgets from '../../../store/modules/widgets'

export default {
  name: "DonationsComponent",
  components: {
    draggable
  },
  data() {
    return {
      step: 1,
      image: "",
      widget: {
        type: 'Voucher',
        activeColor: "",
        headline: "",
        sub_headline: "",
        button_text: "",
        details: [
          {
            details: {
              amount: "",
              voucher_offer: "",
              voucher_text: "",
            }
          }
        ],
      },
      vouchersList: [],
      attachedColor: false,
      isActive: true,
      isActiveTwo: true,
      onEditMode: false,
    }
  },
  created () {
  },
  computed: {
    widgetType() {
      return widgets.state.widgetType
    },
    onWidgetsErrors() {
      return widgets.state.onWidgetsErrors
    },
    disabledInput() {
      return this.disabled
    },
    headlineIsValid() {
      return !!this.widget.headline && this.widget.headline.length <=9;
    },
    sub_headlineIsValid() {
      return !!this.widget.sub_headline && this.widget.sub_headline.length <=100;
    },
    button_textIsValid() {
      return !!this.widget.button_text
    },
    logoIsValid() {
      return !!this.image
    },
    amountIsValid() {
      return !!this.widget.details[0].details.amount
    },
    voucher_offerIsValid() {
      return !!this.widget.details[0].details.voucher_offer
    },
    voucher_textIsValid() {
      return !!this.widget.details[0].details.voucher_text
    },
    firstStepFormIsValid() {
      return this.headlineIsValid && this.sub_headlineIsValid && this.button_textIsValid && this.logoIsValid;
    },
    donationsFormIsValid() {
      return this.amountIsValid && this.voucher_offerIsValid && this.voucher_textIsValid;
    },
  },
  methods: {
    // For Safari issue with visibility of input type color
    onInputColor() {
      this.attachedColor = false;
    },
    onChangeColorPicker(e) {
      this.widget.activeColor = e.target.value;
      this.attachedColor = false;
    },
    onHandleImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0]
      this.createImage(files[0]);
      this.file = file;
      this.fileName = e.target.files[0].name;
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onlyForCurrency ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) {
        $event.preventDefault();
       }
       if(this.price!=null && this.price.indexOf(".")>-1 && (this.price.split('.')[1].length > 1)){
        $event.preventDefault();
       }
    },
    prev() {
      this.step--;
      this.attachedColor = false;
    },
    next() {
      if (!this.firstStepFormIsValid) {
        this.isActive = false
      } else {
        this.step++;
      }
    },
    emptyDetailsFields() {
      this.widget.details[0].details.amount = '',
      this.widget.details[0].details.voucher_offer = '',
      this.widget.details[0].details.voucher_text = ''
    },
    onAddNewVoucher() {
      if(!this.donationsFormIsValid) {
        this.isActiveTwo = false;
      } else {
        const newVoucher = {
          details: {
            amount: this.widget.details[0].details.amount,
            voucher_offer: this.widget.details[0].details.voucher_offer,
            voucher_text: this.widget.details[0].details.voucher_text
          }
        }
        this.vouchersList.unshift(newVoucher);
        this.emptyDetailsFields();
      }

    },
    onEditVoucher(voucher, index) {
      this.onEditMode = true;
      this.index = index;

      this.widget.details[0].details.amount = voucher.details.amount,
      this.widget.details[0].details.voucher_offer = voucher.details.voucher_offer,
      this.widget.details[0].details.voucher_text = voucher.details.voucher_text
    },
    replaceAt(array, index, value) {
      const ret = array.slice(0);
        ret[index] = value;
        return ret;
    },
    onUpdateVoucher(data) {
      const voucher = this.vouchersList[this.index];
      voucher.details.amount = data.details[0].details.amount;
      voucher.details.voucher_offer = data.details[0].details.voucher_offer;
      voucher.details.voucher_text = data.details[0].details.voucher_text;

      const newArray = this.replaceAt(this.vouchersList, this.index, voucher);

      this.widget.activeColor = data.activeColor;
      this.widget.headline = data.headline;
      this.widget.sub_headline = data.sub_headline;
      this.widget.button_text = data.button_text;

      this.onEditMode = false;
      this.emptyDetailsFields();
    },
    onSubmit() {
      let transVouchersList = [];
      this.vouchersList.forEach(detail => {
        let transDetailObj = {};
        transDetailObj = {
          amount: detail.details.amount,
          voucher_offer: detail.details.voucher_offer,
          voucher_text: detail.details.voucher_text,
        }
        transVouchersList.push(transDetailObj)
      });
      let formData = {
        type_id: 2,
        colour: this.widget.activeColor,
        logo: this.image,
        button_text: this.widget.button_text,
        headline: this.widget.headline,
        sub_headline: this.widget.sub_headline,
        title: null,
        details: transVouchersList
      }
      if(formData !== null && formData !== undefined) {
        this.$emit('clickedSubmit', formData)
      }
    },
  },
}
</script>

<style scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -140px;
}

.inner {
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overflow-y: scroll;
}

.inner::-webkit-scrollbar {
    display: none;
}

.parent-holder {
  background: #E8E8E8;
  padding: 0 6px!important;
}

.content p.table-text {
  color: #463C8B;
  font-weight: bold;
  font-size: 14px;
}

img.table-logo {
  height: 1.9em;
}

img.back {
  height: 1.3em;
  margin-left: 10px;
}

img.donation-image {
  height: 2em;
  width: 2em;
  margin-left: 20px;
}

img.donation-image {
  border-radius: 50%;
}

h5 {
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #202020;
  margin: 5px 20px 0 0;
}

label,
input {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 60px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #BCBBBC;
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  padding-left: 12px;
  outline: none;
  color: #202020;
}

input::placeholder {
  color: #BCBBBC;
  font-size: 15px;
}

input[type="color"] {
  width: 35%;
  position: relative;
  left: 50%;
  padding: 0px;
}

input[type="text"]:disabled,
input[type="number"]:disabled {
    background-color: #FEFFFF;
}

label.pl-3 {
  margin-bottom: 0px;
  font-size: 15px;
}

.voucher {
  border: 1px solid #BCBBBC;
  width: 270px;
  height: 65px;
  margin-left: 5px;
  border-radius: 7px;
  padding: 20px 13px;
  color: #BCBBBC;
}

p.pl-2 {
  color: #292929;
  line-height: 20px;
  font-size: 15px;
}

p.pl-2.donate {
  color: #292929;
  line-height: 30px;
  letter-spacing: .3px;
  font-size: 16px;
  font-weight: bold;
}

p.pl-2.donate:first-of-type {
  color: #463C8B;
  font-size: 18px;
}

.pickedColor {
  visibility: hidden;
}

form {
  display: inline-block;
  width: 69%;
  margin-top: 10px;
  margin-bottom: 15px;
}

button.next,
button.save,
button.back,
button.add-voucher,
button.finish {
  padding: 13px 50px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-left: 25px;
}

button.view,
button.edit,
button.back {
  background: white;
  border: 1px solid #E85F9A;
  color: #E85F9A;
  margin: 5px 0px;
  font-weight: bold;
}

button.edit {
  padding: 6px 40px;
  margin: 10px 0;
}

button.next {
  margin-right: 50px;
  margin-top: 5px;
}

button.save {
  margin-right: 35px;
  margin-top: 5px;
  border: 2px solid #E85F9A;
}

button.finish {
  margin-right: 35px;
  margin-top: 5px;
}

.add-voucher {
  margin-top: 5px;
}

.button-widget {
  font-weight: bold;
}

div.ios-correct {
  margin-top: 3px;
}

div.widgets-donation {
  color: white;
  border-radius: 10px;
  width: 96%;
  margin: auto;
}

div.widgets-donation button {
  color: white;
  border-radius: 20px;
  width: 100%;
  border: none;
  background: white;
  margin-bottom: 5px;
}

div.result {
  height: auto;
  width: 60%;
  padding-right: 15px;
  color: white;
  border-radius: 6px;
  margin-top: 18px;
  cursor: move;
}

div.result p {
  width: 59%;
  font-weight: bold;
}

p.deleteCard {
  color: red;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left:100%;
  top: -10%;
  margin-top: -35px;
  cursor: pointer;
}

div.widgets-donation p {
  width: 80%;
  line-height: 19px;
  word-break: break-word;
  display: inline-block;
}

div.widgets-donation p:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

div.donate p{
  font-size: 14px;
  line-height: 25px;
  word-break: break-word;
}

div.donate p:first-of-type {
  font-weight: bold;
  font-size: 16px;
}

div.donate p > span {
  font-weight: bold;
  font-size: 18px;
  margin-right: 90px;
  width: 20%;
}

div.result span {
  background: white;
  padding: 4px;
  border-radius: 5px;
  display: block;
  margin-top: 6px;
}

input:focus,
input.visited {
  color: #202020;
}

.optColor {
  color:#202020
}

.show {
  visibility: hidden;
}

div.header img {
  margin-right: 0px;
}

.alert-danger {
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px;
}

div.qr-parent {
  text-align: center;
  width: 65%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
}
</style>
