<template>
  <div>
    <select name="" id=""
      class="mr-1 mb-3"
      v-model="widgetType"
      @change="onChangeWidget"
      @click="optionColorChanged = false"
      :class="{optColor: optionColorChanged}"
      :disabled="disabled" id="select">
      <!-- <option
        value=""
        v-text="disabledOption"></option> -->
      <option
        v-for="typeOption in widgetTypeOptions"
        :value="typeOption"
        >{{ typeOption }}</option>
    </select>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "SelectInputComponent",
  props: ['widgetType', 'disabled'],
  data(){
    return{
      widgetTypeOptions: ['Select-Type', 'Instagram', 'Website', 'Donations'],
      optionColorChanged: false,
      widgetType: null
    }
  },
  methods: {
    ...mapMutations([
      'EMPTY_SELECTED_WIDGET',
    ]),
    onChangeWidget(event) {
      this.widgetType = event.target.value;
      this.$store.commit('EMPTY_SELECTED_WIDGET');
      this.$store.dispatch('getWidgetType', this.widgetType)
    },
  }
}
</script>

<style lang="css" scoped>
  select {
    padding: 20px 150px 20px 15px;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid #BCBBBC;
    outline: none;
    border-radius: 7px;
    color:#BCBBBC;
  }

  select:focus {
    color: #202020;
  }

  .optColor {
    color:#202020
  }
</style>

