<template lang="html">
  <div class="inner-colour-container">

    <SelectInputComponent
      @clickChange="onChange"
      :widgetType="widgetType" />
    <WebsiteComponent
      v-if="widgetType === 'Website' && editWidgetMode === false"
      :widgetType="widgetType"
      @clickedSubmit="onSubmitForm" />
    <EditWebsiteComponent
      v-if="widgetType === 'Website' && editWidgetMode === true"
      :widgetType="widgetType"

      @clickedUpdate="onUpdateForm" />
    <InstagramComponent
      v-if="widgetType === 'Instagram' && editWidgetMode === false"
      :widgetType="widgetType"
      @clickedSubmit="onSubmitForm" />
    <EditInstagramComponent
      v-if="widgetType === 'Instagram' && editWidgetMode === true"
      :widgetType="widgetType"

      @clickedUpdate="onUpdateForm" />
    <DonationsComponent
      v-if="widgetType === 'Donations' && editWidgetMode === false"
      :widgetType="widgetType"
      @clickedSubmit="onSubmitForm" />
    <EditDonationsComponent
      v-if="widgetType === 'Donations' && editWidgetMode === true"
      :widgetType="widgetType"

      @clickedUpdate="onUpdateForm" />


    <div v-if="widgetType === 'Select-Type'">
      <!-- Iphone Holder start -->
      <div class="iphone-holder float-right">
        <div class="inner">
          <div class="header text-center pt-2">
            <img src="../../../assets/icon-back-arrow-iphone.png" alt="" class="back float-left arrow">
            <h5 class="mb-0"> Thanks for paying with</h5>
            <img src="../../../assets/icon-logo.png" alt="" class="table-logo">
          </div>
          <hr class="my-2">
          <p class="pl-2">Here are some exclusive offers as a thank you for dining with us.</p>

          <div class="widgets-instagram p-2 mb-3" v-for="(card,index) in widgetsList" :key="card.index" :style="{backgroundColor: card.colour}">
            <p>{{ card.headline }}</p>
            <img :src="card.logo" alt="" class="insta-image float-right mr-2">
            <p>{{ card.sub_headline }}</p>
            <button disabled type="button" name="button" class="p-1 button-widget" :style="{color: card.colour}">{{ card.button_text }}</button>
          </div>
        </div>
      </div>
      <!-- Iphone Holder end -->

      <!-- Response messages -->
      <div class="response-msg mb-2">
        <!-- On success message -->
        <p v-if="onWidgetsSuccess" class="alert-success text-left">{{ onWidgetsSuccess }}</p>
        <!-- On error message -->
        <p v-if="onWidgetsErrors" class="alert-danger text-left">{{ onWidgetsErrors }}</p>
      </div>
      <!-- Onload widgets error -->
      <p v-if="onLoadWidgetsErrors" class="text-center alert-info">{{ onLoadWidgetsErrors }}</p>

      <!-- LeftSideCards start -->
      <draggable :list="widgetsList" :forceFallback="true" @change="onReorder($event)">
        <div class="result align-items-center py-2" v-for="(widget,index) in widgetsList" :key="widget.index" :style="{backgroundColor: widget.colour}">
          <div class="float-left ml-3 ">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <p class="ml-3 mt-3 d-inline-block">{{ widget.headline }}</p>
          <div class="float-right ios-correct">
            <button type="button" class="btn view" data-toggle="modal" data-target="#myModal">View Results</button>
            <button type="button" name="button" class="mx-2 btn edit" v-on:click="onEditWidget(widget)">Edit</button>
          </div>
          <p class="deleteCard" v-on:click="onDeleteWidget(widget.id)">Delete</p>
        </div>
      </draggable>
      <!-- LeftSideCards end -->

      <!-- Modal start -->
      <div class="modal fade" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Results</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
              Content coming soon
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
      <!-- Modal end -->
    </div>

  </div>
  </div>
  </div>
</template>

<script>
import widgets from '../../../store/modules/widgets'
import { mapMutations } from 'vuex';

import SelectInputComponent from '../WidgetComponent/SelectInput/SelectInputComponent';
import WebsiteComponent from '../WidgetComponent/WebsiteComponent';
import EditWebsiteComponent from '../WidgetComponent/EditWebsiteComponent';
import InstagramComponent from '../WidgetComponent/InstagramComponent';
import EditInstagramComponent from '../WidgetComponent/EditInstagramComponent';
import DonationsComponent from '../WidgetComponent/DonationsComponent';
import EditDonationsComponent from '../WidgetComponent/EditDonationsComponent';

import draggable from "vuedraggable";

export default {
  name: "WidgetsComponent",
  components: {
    SelectInputComponent,
    WebsiteComponent,
    EditWebsiteComponent,
    InstagramComponent,
    EditInstagramComponent,
    DonationsComponent,
    EditDonationsComponent,
    draggable,
  },
  data () {
    return {
      widget: {},
      image: '',
      cardInIphone: true,
      editWidgetMode: false,
      // disabled: false,

    }
  },
  created() {
    return this.$store.commit('LOAD_ALL_WIDGETS_LIST')
  },
  destroyed () {
    widgets.state.widgetType = 'Select-Type'
  },
  computed: {
    widgetType() {
      return widgets.state.widgetType
    },
    widgetsList() {
      return widgets.state.widgetsList
    },
    onWidgetsSuccess() {
      return widgets.state.onWidgetsSuccess
    },
    onWidgetsErrors() {
      return widgets.state.onWidgetsErrors
    },
    onLoadWidgetsErrors() {
      return widgets.state.onLoadWidgetsErrors
    }
    // isDisabled(value) {
      // console.log(value)
      // if(this.step === 2) {
        // return true;
      // }
    // },
    // disabledInput() {
    //   return this.disabled
    // },
  },
  methods: {
    ...mapMutations([
      'SET_WIDGET_TYPE',
      'LOAD_ALL_WIDGETS_LIST',
      'EMPTY_SELECTED_WIDGET',
    ]),
    onReorder(e) {
      const order = this.widgetsList.map((el,i) => {
        return el.id
      })
      const newOrder = {
        widgets_order: order
      }
      this.$store.dispatch('updateOrder', newOrder)
    },
    onChange(value) {
      this.$store.commit('EMPTY_SELECTED_WIDGET');
      this.$store.commit('SET_WIDGET_TYPE', value);
    },
    onSubmitForm(data) {
      this.$store.dispatch('addWidget', data);
    },
    onUpdateForm(data) {
      this.$store.dispatch('updateWidget', {data: data.formData, vouchers: data.vouchers})
      setTimeout(() => {
        this.editWidgetMode = data.editWidgetMode
      }, 1000);
    },
    onEditWidget(widget) {
      this.editWidgetMode = true;
      let type = '';
      if(widget.type === 'Table Booking') {
        type = 'Website'
        this.$store.commit('SET_WIDGET_TYPE', type)
      } else if (widget.type === 'Instagram') {
        type = 'Instagram'
        this.$store.commit('SET_WIDGET_TYPE', type)
      } else {
        type = 'Donations'
        this.$store.commit('SET_WIDGET_TYPE', type)
      }
      this.$store.dispatch('getSelectedWidget', widget.id)
    },
    onDeleteWidget(id) {
      this.$store.dispatch('deleteWidget', id);
    }
  }
}
</script>

<style lang="css" scoped>
.iphone-holder {
  background-image: url("../../../assets/iphone.png");
  height: 44.6em;
  width: 23em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -140px;
}

.inner {
  background: white;
  height: 200px;
  width: 200px;
  z-index: 2;
  position: absolute;
  width: 81.3%;
  left: 35px;
  top: 93px;
  height: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overflow-y: scroll;
}

.inner::-webkit-scrollbar {
    display: none;
}

img.table-logo {
  height: 1.9em;
}

img.back {
  height: 1.3em;
  margin-left: 10px;
}

img.insta-image {
  height: 2em;
  width: 2em;
}

img.insta-image {
  border-radius: 50%;
}

h5 {
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #202020;
  margin: 5px 20px 0 0;
}

p.pl-2 {
  color: #292929;
  line-height: 20px;
  font-size: 15px;
}

button.back {
  padding: 13px 50px;
  background: #E85F9A;
  color: white;
  border: 1px solid #E85F9A;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.75);
  float: right;
  outline: none;
  margin-left: 25px;
}

button.back,
button.view,
button.edit {
  background: white;
  border: 1px solid #E85F9A;
  color: #E85F9A;
  margin: 5px 0px;
  font-weight: bold;
}

button.edit {
  padding: 6px 40px;
  margin: 10px 0;
}

.button-widget {
  font-weight: bold;
}

div.ios-correct {
  margin-top: 3px;
}

div.widgets-instagram {
  color: white;
  border-radius: 10px;
  width: 96%;
  margin: auto;
}

div.widgets-instagram button {
  color: white;
  border-radius: 20px;
  width: 100%;
  border: none;
  background: white;
  margin-bottom: 5px;
}

div.result {
  height: auto;
  width: 60%;
  padding-right: 15px;
  color: white;
  border-radius: 6px;
  margin-top: 18px;
  cursor: move;
}

div.result p {
  font-weight: bold;
}

p.deleteCard {
  color: red;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left:100%;
  margin-top: -35px;
  cursor: pointer;
}

div.widgets-instagram p {
  width: 80%;
  line-height: 19px;
  word-break: break-word;
  display: inline-block;
}

div.widgets-instagram p:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

div.result span {
  background: white;
  padding: 4px;
  border-radius: 5px;
  display: block;
  margin-top: 6px;
}

div.header img {
  margin-right: 0px;
}

div.response-msg {
  height: 15px;
}

.alert-success,
.alert-danger,
.alert-info {
  background-color: white;
  border: none;
  margin-left: 5px;
}

.alert-danger {
  background-color: white;
  border: none;
  margin-left: 5px;
}
</style>

