<template>
  <div class="">
    <FeedbackSubheader />
    <p class="mb-2">Net Promoter Score</p>
    <TeamMembersNPS />
  </div>
</template>

<script>
import FeedbackSubheader from '../../components/FeedbackComponents/FeedbackSubheader';
import TeamMembersNPS from '../../components/FeedbackComponents/TeamMembersNPS';

export default {
  name: "TeamMembersNPSPage",
  components: {
    FeedbackSubheader,
    TeamMembersNPS
  }
}
</script>

<style lang="css" scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
}
</style>