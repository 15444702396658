<template>
  <div>
    <OverviewSubheader />
    <div>
      <img src="../assets/tableYeti-wide-logo.png" alt="">
    </div>
</div>
</template>


<script>
import OverviewSubheader from '../components/DefaultMainComponents/OverviewSubheader';


export default {
  name: 'Overview',
  components: {
    OverviewSubheader
  }
}
</script>

<style scoped>
div p{
  color: rgb(60, 3, 140);
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 50px;
}

img {
  width: 100%;
  max-width: 1070px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>
